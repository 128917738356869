import {faTrashAlt} from '@fortawesome/pro-regular-svg-icons';

import AskForConfirmation from '@/components/Modals/AskForConfirmation';
import {strings} from '@/localization/i18n';

export const OnDeleteShortcut = (item, deleteShortcut) => {
    return AskForConfirmation(
        `${strings('delete')} ${item.label}`,
        strings('delete_confirmation'),
        () => deleteShortcut(item),
        'error',
        strings('delete'),
        faTrashAlt,
        null,
        ()=> {},
        item.id,
        null,
        null,
        {color: 'error-600', className: '!bg-error-25 rounded-full p-3'},
    );
};
