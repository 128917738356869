import {MorphService} from '@aktek/morph-frontend-sdk';

export default async (sboId, sorting, filter, page, pageLength, search) => {
    // return {data: [], count: 0}
    // @TODO: fetch only supported fields
    const sort ={id: sorting?.column || 'designation', order: sorting?.order == 'up' ? 1 : -1};

    const res = await MorphService.retrieveRawMorphsBySBOId(
        sboId,
        null, // selectedFields
        search, // search
        sort, // sort
        pageLength * (page - 1), // skip
        pageLength, // limit
        filter, // userFilter
        true, // shouldReturnAllFields
    );

    if (!res.isSuccessful()) {
        return [];
    }

    // const tableData = dummyData;
    const tableData = res.getData();
    const data = tableData.data;

    const count = tableData ?.metadata?.[0]?.count || 0;

    return {data, count};
};
