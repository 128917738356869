import {TF4ThemeColors} from '@aktek/f3theme';
import {AvatarLabelGroup, Label, TContextMenuEntry, TStatusIconProps} from '@aktek/f4kit';
import {UserHistoryService, UserService, VerificationService} from '@aktek/helios-frontend-sdk';
import {faSuitcase} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope, faEye, faHistory, faLink, faMinus, faPen, faSnowflake, faTrash, faUser, faUserUnlock} from '@fortawesome/pro-regular-svg-icons';

import AskForConfirmation from '@/components/Modals/AskForConfirmation';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import AskForModal from '@/components/Modals/AskForModal';
import PasswordLinkModal, {TPasswordLinkModalProps} from '@/components/Modals/PasswordLinkModal';
import {TColumn, TGetRightClickRowActions} from '@/components/Table/Types/Table.t';
import Env from '@/config/env';
import {UserTypes} from '@/constants/UserTypes.c';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {useAppPermissions} from '@/context/UserContext/Hooks/useAppPermissions';
import {useCompanySettings} from '@/context/UserContext/Hooks/useCompanySettings';
import {useIsAccountManager, useIsCurrentUser, useIsRegularUser} from '@/context/UserContext/Hooks/useCurrentUser';
import {GetNameInitials} from '@/helpers/GetNameInitial.fn';
import {strings} from '@/localization/i18n';
import ls from '@/utils/storage/ls';

import ViewPermissionModal from '../RolesAndPermissions/components/ViewPermissionModal';
import CreateUserModal from './components/CreateUserModal';
import EditAccountManagerModal from './components/EditAccountManagerModal';
import HistoryModal, {THistoryModalProps} from './components/HistoryModal';
import {USER_HISTORY_COLUMNS} from './constants/userConstants.c';

const GetUsersColumns = (): Array<TColumn> => {
    const {users, permissions} = useAdminPermissions();
    const {resetOtherUserPassword} = useAppPermissions();

    const canFreeze = useCompanySettings('settings_freeze_account_admin_freeze');
    const canAdminGenerateLink = useCompanySettings('settings_admin_reset_user_pass');

    const isCurrentUserRegular = useIsRegularUser();
    const isCurrentUserAccountManger = useIsAccountManager();

    return [
        {key: 'name', header: strings('user'), type: 'string', isSortable: true,
            renderCell({row}) {
                const name = row?.original?.name;
                const email = row?.original?.email;
                const profilePicURL = row?.original?.profilePicURL;
                const img = profilePicURL ? {
                    src: Env.getFileURL(profilePicURL),
                    alt: 'Profile Picture',
                }: null;

                const avatarAltLabel = !profilePicURL ? GetNameInitials(name) : null;

                const isActive = !!row.original?.isActive;
                const isFrozen = !!row.original?.isFrozen;
                // const isSuperAdmin = row.original?.userType === 'SUPERADMIN';
                const isAccountManager = row.original?.isAccountManager;
                const icons = [
                    {
                        icon: faSnowflake,
                        baseClass: 'bg-[#B2DDFF] p-0.5',
                        color: 'white' as TF4ThemeColors,
                        isVisible: isFrozen,
                    },
                    {
                        icon: faMinus,
                        baseClass: 'bg-warning-500 p-0.5',
                        color: 'white' as TF4ThemeColors,
                        isVisible: !isActive,
                    },
                    // {
                    //     icon: faCrown,
                    //     baseClass: 'bg-white p-0.5',
                    //     color: 'warning-300',
                    //     isVisible: isSuperAdmin,
                    // },
                    {
                        icon: faSuitcase,
                        baseClass: 'bg-white !p-2',
                        color: 'primary-500' as TF4ThemeColors,
                        isVisible: isAccountManager,
                    },
                ];

                const positionedIcons : TStatusIconProps[] | TStatusIconProps = icons
                    .filter((icon) => icon.isVisible) // Filter out invisible icons first
                    .map((icon, index) => {
                        const positionClassesByIndex = {
                            0: '!bottom-0 !right-0',
                            1: '!bottom-4 !-right-2',
                            2: '!bottom-8 !right-0',
                        };

                        return {
                            ...icon,
                            className: `${icon.baseClass} ${positionClassesByIndex[index] || ''}`,
                        };
                    });

                return <AvatarLabelGroup
                    avatar={{
                        img: img,
                        avatarAltLabel: avatarAltLabel,
                        avatarClassName: avatarAltLabel ? 'border-none' : '',
                        statusIcon: positionedIcons,
                    }}
                    label={{
                        text: name,
                    }}
                    subLabel={{
                        text: email,
                    }}
                    size="sm"
                    isCheckable={false}
                />;
            },
        },
        // {
        //     key: 'phone', header: strings('phone_number'), type: 'string',
        //     renderCell({row}) {
        //         const phone = row?.original?.phone;
        //         const code = phone?.countryCode;
        //         const number = phone?.number;

        //         return phone ? `+${code} ${number}` : '';
        //     },
        // },
        {
            key: 'userType', header: strings('user_type'), type: 'string', isSortable: true,
            renderCell({cell}) {
            // TODO: render here the div with an icon for each field type
                return <Label text={strings(cell.getValue()?.toLowerCase())} />;
            },
        },
        {key: 'unixLastLoginDate', header: strings('last_login_date'), type: 'datetime', isSortable: true},
        {key: 'creationDate', header: strings('creation_date'), type: 'date', isSortable: true},
        {
            key: 'actions',
            header: '',
            type: 'actions',
            actions: {

                edit: {
                    tooltip: strings('edit'),
                    isVisible: ({row}) => {
                        const userType = row.original?.userType;

                        if ((isCurrentUserRegular || isCurrentUserAccountManger) && userType != UserTypes.Regular) return false;

                        return users?.can_edit;
                    },
                },

                delete: {
                    tooltip: strings('delete'),
                    isVisible: ({row}) => {
                        const isAccountManager = row.original?.isAccountManager;
                        const userType = row.original?.userType;

                        if ((isCurrentUserRegular || isCurrentUserAccountManger) && userType != UserTypes.Regular) return false;

                        return users?.can_delete && !isAccountManager;
                    },
                },

                ellipsis: {
                    tooltip: strings('options'),
                    isVisible: users?.can_edit,
                    get: ({row}) => {
                        const id = row.original?._id;
                        const isCurrentUser = useIsCurrentUser(id);
                        const isFrozen = row.original?.isFrozen;
                        const isActive = row.original?.isActive;
                        const isAccountManager = row.original?.isAccountManager;

                        // const currentUserType = currentUser?.userType;
                        // const userType = row.original?.userType;

                        const hidePasswordLinkGeneration = !canAdminGenerateLink
                        || !resetOtherUserPassword?.can_access || isAccountManager || !isActive;
                        //  || (currentUserType != UserTypes.SuperAdmin && userType == UserTypes.SuperAdmin);

                        return {
                            options: {
                                view_history: {
                                    hide: isAccountManager,
                                    label: strings('view_history'),
                                    type: 'item', option: 'Icon', icon: {color: 'neutral-700', icon: faHistory},
                                },

                                send_verification_email: {
                                    hide: isActive,
                                    label: strings('send_verification_email'),
                                    type: 'item', option: 'Icon',
                                    icon: {color: 'neutral-700', icon: faEnvelope},
                                },

                                generate_password_link: {
                                    hide: hidePasswordLinkGeneration,
                                    label: strings('generate_password_link'),
                                    type: 'item', option: 'Icon', icon: {color: 'neutral-700', icon: faLink},
                                },

                                freeze_account: {
                                    hide: !(canFreeze && !isFrozen) || isAccountManager || isCurrentUser,
                                    label: strings('freeze_account'),
                                    type: 'item', option: 'Icon', icon: {color: 'neutral-700', icon: faSnowflake},
                                },

                                unfreeze_account: {
                                    hide: !(canFreeze && isFrozen) || isCurrentUser,
                                    label: strings('unfreeze_account'),
                                    type: 'item', option: 'Icon', icon: {color: 'neutral-700', icon: faUserUnlock},
                                },

                                view_permissions: {
                                    hide: !permissions?.can_view,
                                    label: strings('view_permissions'),
                                    type: 'item', option: 'Icon', icon: {color: 'neutral-700', icon: faEye},
                                },
                            },
                        };
                    },
                },
            },
        },
    ];
};

export default GetUsersColumns;

export const GetRightClickRowUsersActions: TGetRightClickRowActions = ({
    users,
    permissions,
    resetOtherUserPassword,
    canFreeze,
    canAdminGenerateLink,
    isCurrentUserAccountManger,
    isCurrentUserRegular,
}) =>
    (refreshTable, deleteModalRef) => (row) => {
        const hidePasswordLinkGeneration = !canAdminGenerateLink
                         || !resetOtherUserPassword?.can_access || row.original?.isAccountManager || !row.original?.isActive;

        return [
            {
                label: strings('edit'),
                icon: faPen,
                onClick: () => {
                    const isAccountManager = row.original.isAccountManager;

                    return AskForCreationModal(
                        isAccountManager ? EditAccountManagerModal : CreateUserModal,
                        {
                            state: {mode: 'edit', _id: row.original._id},
                            refreshTable,
                        },
                    );
                },
                isVisible: () => {
                    const userType = row.original?.userType;

                    if ((isCurrentUserRegular || isCurrentUserAccountManger) && userType !== UserTypes.Regular) return true;

                    return !users?.can_edit;
                },

            },

            {
                label: strings('view_permissions'),
                isVisible: () => !permissions.can_view,
                icon: faEye,
                onClick: () => {
                    ls.permissionSection = 'admin';

                    return AskForModal(ViewPermissionModal, {id: row.original._id});
                },
            },

            {
                label: strings('freeze_account'),
                isVisible: () => !(canFreeze && !row.original?.isFrozen) || row.original?.isAccountManager,
                icon: faSnowflake,
                onClick: () =>AskForConfirmation(
                    `${strings('freeze')} ${row.original.name}`,
                    strings('freeze_confirmation_description'),
                    async (id)=> {
                        const res = await UserService.freezeUserAccount(id);
                        refreshTable();

                        return {isSuccessful: () => res.getData().valid};
                    },
                    'primary',
                    strings('freeze'),
                    null,
                    {
                        icon: faUser,
                        statusIcon: {
                            icon: faSnowflake,
                            className: 'bg-primary-100 rounded-full text-primary-600',
                        },
                    },
                    ()=> {},
                    row.original._id,
                    strings('freeze_success_message'),
                    strings('freeze_error_message'),
                ),
            },
            {
                label: strings('unfreeze_account'),
                isVisible: () => !(canFreeze && row.original?.isFrozen),
                icon: faUserUnlock,
                onClick: () => AskForConfirmation(
                    `${strings('unfreeze')} ${row.original.name}`,
                    strings('unfreeze_confirmation_description'),
                    async (id)=> {
                        const res = await UserService.unFreezeUserAccount(id);
                        refreshTable();

                        return {isSuccessful: () => res.isSuccessful()};
                    },
                    'primary',
                    strings('unfreeze'),
                    null,
                    {
                        icon: faUser,
                    },
                    ()=> {},
                    row.original._id,
                    strings('unfreeze_success_message'),
                    strings('unfreeze_error_message'),
                ),
            },

            {
                label: strings('send_verification_email'),
                isVisible: () => row.original?.isActive,
                icon: faEnvelope,
                onClick: () => AskForConfirmation(
                    strings('send_verification_email'),
                    strings('send_verification_email_confirmation_description'),
                    async (id)=> {
                        const res = await VerificationService.resendVerificationEmail(id);

                        return {isSuccessful: () => res.isSuccessful()};
                    },
                    'primary',
                    null,
                    faEnvelope,
                    null,
                    ()=> {},
                    row.original._id,
                    strings('send_verification_email_success_message'),
                    strings('send_verification_email_error_message'),
                ),
            },
            {
                label: strings('generate_password_link'),
                isVisible: () => hidePasswordLinkGeneration,
                icon: faLink,
                onClick: async () => {
                    const response = await UserService.obtainPasswordResetLink(row.original._id);

                    if (!response.isSuccessful()) return;

                    AskForModal<TPasswordLinkModalProps>(PasswordLinkModal, {
                        openOnMount: true,
                        URL: response.getData().link,
                    });
                },
            },

            {
                label: strings('view_history'),
                isVisible: () => row.original?.isAccountManager,
                icon: faHistory,
                onClick: () => AskForModal<THistoryModalProps>(HistoryModal, {
                    id: row.original._id,
                    getDataFunction: UserHistoryService.getUserHistory,
                    columns: USER_HISTORY_COLUMNS,
                }),
            },

            {
                label: strings('delete'),
                icon: faTrash,
                iconProps: {color: 'error-600'},
                onClick: () => deleteModalRef?.current.open({
                    id: row.original._id,
                    name: row.original.name,
                    message: strings('delete_user_message'),
                }),
                labelProps: {
                    color: 'error-600',
                },
                isVisible: () => {
                    const isAccountManager = row.original?.isAccountManager;
                    const userType = row.original?.userType;

                    if ((isCurrentUserRegular || isCurrentUserAccountManger) && userType !== UserTypes.Regular) return true;

                    return !(users?.can_delete && !isAccountManager);
                },

            },

        ].filter((action) => !(action?.isVisible && action.isVisible()));
    };
