import {Datetime} from '@aktek/f4kit';

import ReferenceButton from '@/components/ReferenceButton/ReferenceButton';

interface AutomaticCellProps {
    value: string | number;
    isTime?: boolean;
}

const Automatic: React.FC<AutomaticCellProps> = ({value, isTime = false}) => {
    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;

    if (isTime) {
        return <Datetime value={parsedValue} />;
    }

    return (
        <div className="flex items-center gap-1">
            <ReferenceButton value={value || ''} />
        </div>
    );
};

export default Automatic;
