import {Button, ButtonColorPicker, Modal, TabNav, TModalProps, useModalRef} from '@aktek/f4kit';
import cn from 'classnames';
import _compact from 'lodash/compact';
import {TabKey} from 'node_modules/@aktek/f4kit/dist/components/TierTwo/Tabs/Types/TabsProps';
import {useState} from 'react';

import {strings} from '@/localization/i18n';

import {ImageUploader} from '../F4Elements/F4ImageUploader';

export type TImageColorSelectorProps = {
    // Modal info
    modalProps?: Partial<TModalProps>;
    className?: string;

    // Form info
    colorFormName?: string;
    imageFormName?: string;
    canSetImage?: boolean;
    canSetColor?: boolean;
    setValue?: (name: string, value: unknown) => void;
}

export default function ImageOrColorSelectorModal(props: TImageColorSelectorProps) {
    const {className, modalProps={size: 'xs'}, imageFormName, colorFormName, setValue, canSetImage = true, canSetColor = true} = props;

    const IMAGE_OR_COLOR_TABS = _compact([
        canSetImage && {
            key: 'image',
            label: strings('image'),
        },
        canSetColor && {
            key: 'color',
            label: strings('color'),
        },
    ]);

    const [selectedTab, setSelectedTab] = useState<TabKey>('image');
    const [imageID, setImageID] = useState<string>('');
    const [color, setColor] = useState<string>('#fffffff');

    const modalRef = useModalRef();

    const onConfirm = () => {
        {imageID && setValue?.(imageFormName, imageID);}

        {color && setValue?.(colorFormName, color);}

        modalRef.current.close();
    };

    const onCancel = () => modalRef.current.close();

    return <Modal defaultIsOpened
        ref={modalRef}
        hasXIcon={false}
        headerClassName="hidden"
        className={cn('overflow-hidden !rounded-3xl p-6 max-w-[32rem]', className)}
        title={null}
        size={modalProps.size}
        onChange={modalProps.onChange}
    >
        <div className="flex flex-col">
            <div className="flex items-center justify-between w-full gap-4">
                <TabNav
                    value={selectedTab}
                    onChange={setSelectedTab}
                    tabs={IMAGE_OR_COLOR_TABS}
                    orientation={'horizontal'}
                />
                <Button isGhost
                    label={'Remove'}
                    variant="neutral"
                    onClick={()=>{
                        {selectedTab == 'image' && setImageID('');}

                        {selectedTab == 'color' && setColor('');}
                    }}
                />
            </div>
            <div className="h-40">

                {selectedTab == 'image' && <ImageUploader
                    value={imageID}
                    label={strings('click_to_upload')}
                    allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                    description={strings('click_to_upload_desc')}
                    maxSizeMB={1}
                    onChange={setImageID}
                />}

                {selectedTab == 'color'
                && <ButtonColorPicker
                    size="3xl"
                    defaultValue="#fff"
                    value={color}
                    onChange={setColor}
                />}
            </div>

            <div className="flex mt-6 w-full gap-4">
                <Button
                    label={strings('cancel')}
                    variant="white"
                    className="w-1/2"
                    onClick={onCancel}
                />
                <Button
                    label={strings('confirm')}
                    className="w-1/2"
                    onClick={onConfirm}
                />
            </div>
        </div>
    </Modal>;
}
