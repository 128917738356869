// Time limits
const MAX_HOURS = 23;
const MAX_MINUTES = 59;
const MAX_SECONDS = 59;

// TimeTransformer function
export default (inputTime) => {
    // fieldct the time part from the input string
    const timeMatch = inputTime.match(/(\d{1,2}):?(\d{2})?:?(\d{2})?/);
    if (!timeMatch) return null;

    // fieldct hours, minutes, and seconds
    const hours = parseInt(timeMatch[1], 10);
    const minutes = parseInt(timeMatch[2] || '0', 10);
    const seconds = parseInt(timeMatch[3] || '0', 10);

    // Check for valid time range
    if (hours < 0 || hours > MAX_HOURS || minutes < 0 || minutes > MAX_MINUTES || seconds < 0 || seconds > MAX_SECONDS) {
        return null;
    }

    // Create date object and set the time
    const date = new Date();
    date.setUTCHours(hours, minutes, seconds, 0);

    return date.getTime();
};
