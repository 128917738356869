
export const FindFolderById = (array, id) => {
    const found = array.find((item) => {
        if (item.id === id) return true;

        if (item?.children?.length) {
            return !!FindFolderById(item.children, id);
        }

        return false;
    });

    if (found?.id === id) return found;

    if (found?.children?.length) return FindFolderById(found.children, id);

    return found;
};
