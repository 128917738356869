import validateConnectedRef from './connectedRef';
import validateMeta from './meta';
import validateRef from './ref';
import validateRefList from './refList';

const validateCell = async (cell) => {
    console.log('....ValidateCell.tsx__7__: ', cell, '\n_________');
    const {type, value, _id, field, parentValue, affecterValue, affecterColumn} = cell;
    if (type == 'ref') return validateRef({value, _id, field});

    if (type == 'connectedRef') return validateConnectedRef({value, _id, field, parentValue, affecterValue, affecterColumn});

    if (type == 'refList') return validateRefList({value, _id, field});

    if (type == 'meta') return validateMeta({value, _id, field});

    return {isValid: true, finalValue: value};
};

export default validateCell;
