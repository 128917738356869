import RolesV4Service from '@aktek/helios-frontend-sdk/out/RolesV4Service';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import {TF4ViewableComponentPropsReferences} from '@/components/F4ViewableComponent/types/F4ViewableComponentProps.t';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import useTableRef from '@/components/Table/Hooks/useTable';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import {CreateRoleAndPermissionModal} from './components/CreateRoleAndPermissionModal';
import {GetRightClickRowRolesActions, ROLES_COLUMNS} from './RolesAndPermission.columns';

function RolesAndPermissions() {
    const tableRef = useTableRef();
    const refreshTable = ()=> tableRef.refresh?.();
    const {roles} = useAdminPermissions();

    const rolesOnRowAction = (references:TF4ViewableComponentPropsReferences) => (row, action, event, state) => {
        if (action.name == 'edit') {
            return AskForCreationModal(
                CreateRoleAndPermissionModal,
                {
                    state: {
                        mode: 'edit',
                        _id: row.original.id,
                    },
                    refreshTable,
                });
        }

        if (action.name == 'delete') {
            references.deleteModalRef.current.open({
                id: row.original.id,
                name: row.original.name,
                message: strings('role_message_delete'),
            });
        }
    };

    return (
        <F4ViewableComponent
            name="role"
            tableRef={tableRef}
            key="rolesandpermission"
            deleteSuccessMessage={strings('delete_role')}
            deleteIcon={false}
            defaultSortedColumn="name"
            emptyProps={{addLabel: strings('new_role')}}
            getRightClickRowActions={GetRightClickRowRolesActions(roles)}
            tableColumns={ROLES_COLUMNS()}
            canCreate={roles?.can_create}
            modal={CreateRoleAndPermissionModal}
            getAllData={RolesV4Service.getAllRoles}
            deleteItem={RolesV4Service.deleteRole}
            onRowAction={rolesOnRowAction}
        />
    );
}

export default RolesAndPermissions;
