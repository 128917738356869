export default async ({dependencies = [], connectedField, field}) => {
    const isMulti = connectedField.type === 'refList';
    dependencies.push({
        name: field.id,
        comparatorChanged: (value, dataValue, modified) => {
            if (isMulti) {
                if (modified === null) {
                    return !arrayIdsAreSubset(dataValue, value );
                }

                return !arrayIdsAreSubset(modified, value);
            }

            if (modified === null) {
                return value?._id != dataValue?._id;
            }

            return value?._id != modified?._id;
        },
    });

    return dependencies;
};

const arrayIdsAreSubset = (array1, bigArray) => {
    const idsSet = new Set();
    bigArray?.forEach((item) => {
        idsSet.add(item?._id);
    });

    return array1?.every?.((item, index) => idsSet.has(item?._id));
};
