
import _cloneDeep from 'lodash/cloneDeep';
import _keyBy from 'lodash/keyBy';

import {stringInclude} from '@/helpers/strings.fn';

const useSwitchCompaniesSearch = (searchTerm, userAuth) => {
    const userCompanies = _cloneDeep(userAuth?.userCompanies);

    if (!userCompanies?.length) return [];

    const companiesById = _keyBy(userCompanies, '_id');

    const rootCompany = userCompanies.find(isRootCompany);

    const organizedItems = addChildrenToCompanies(userCompanies, rootCompany?._id, companiesById);
    const filteredCompaniesData = filterCompanies(organizedItems, searchTerm)
        .sort((company1) => isRootCompany(company1) ? -1 : 1);

    return filteredCompaniesData;
};

const addChildrenToCompanies =(companies, topParentCompanyId, companiesById) => {
    companies.forEach((company) => {
        const parentId = company.ancestors?.find((ancestorsId) => ancestorsId !== topParentCompanyId);

        const parent = companiesById[parentId];
        company.topLevel = true;

        if (!parent) return;

        parent.children ||= [];
        parent.children.push(company);
        company.topLevel = false;
    });

    return companies.filter((company) => company.topLevel);
};

const filterCompanies = (companies, searchQuery) => {
    if (!searchQuery) return companies;

    const filteredCompanies = companies
        .reduce((result, company) => {
            const filteredCompany = getFilteredCompany(company, searchQuery);

            filteredCompany && result.push(filteredCompany);

            return result;
        }, []);

    return filteredCompanies;
};

const getFilteredCompany = (company, searchQuery) => {
    const matchesSearch = stringInclude(company.companyName, searchQuery);

    let filteredChildren = [];

    if (company.children) {
        if (matchesSearch) {
            filteredChildren = company.children;
        } else {
            filteredChildren = filterCompanies(company.children, searchQuery);
        }
    }

    if (matchesSearch || filteredChildren.length > 0) {
        return {
            ...company,
            children: filteredChildren,
        };
    }

    return null;
};

const getRootCompanyId = (company) => company.ancestors?.[0];

export const isRootCompany = (company) => getRootCompanyId(company) == company?._id;

export default useSwitchCompaniesSearch;
