import {isFunction} from 'lodash';

import {CanEditSettings} from '@/constants/Permissions.c';

import ButtonSection from './ButtonSection';

type TSettingsFooterProps = {
    onSave?: () => void;
    onFileUpload?: (T: unknown) => void;
    onFileDownload?: (T: unknown) => void;
    handleCancel?: () => void;
    handleReset?: () => void;
    appearanceFormRef?: unknown;
    isInitialValue?: boolean;
    isDefault?: boolean;
}

export default function SettingsFooter(
    {
        onSave,
        onFileUpload,
        onFileDownload,
        handleCancel,
        handleReset,
        appearanceFormRef,
        isDefault,
        isInitialValue,
    }: TSettingsFooterProps) {
    const showButtons = isFunction(onSave) && isFunction(handleReset) && isFunction(handleCancel);

    return (
        <div className="flex flex-wrap justify-end items-center w-full gap-6 sticky bottom-0 py-4 bg-neutral-50 elevation-3 !shadow-none">
            {(showButtons && CanEditSettings())
            && <ButtonSection
                onSave={onSave}
                onFileUpload={onFileUpload}
                onFileDownload={onFileDownload}
                onCancel={handleCancel}
                onReset={handleReset}
                appearanceFormRef={appearanceFormRef}
                isInitialValue={isInitialValue}
                isDefault={isDefault}
            />}
        </div>

    );
}
