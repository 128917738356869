// import DateTimePickerPopup from '@/components/DatePickerPopup/DateTimePickerPopup';
import {DateTimePickerPopup} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

const TimeCellEdit = ({value, onChange}) => {
    return <div className="flex items-center gap-1">

        <DateTimePickerPopup defaultOpen
            mode="time"
            onTimeChange={(time) => {
                onChange(time);
            }}
            timeProps={{
                defaultValue: value,
                timeFormat: 'hh:mm',
                placeholder: strings('select_time'),
            }}
            dateProps={{
                placeholder: strings('select_date'),
                value: 0,
                minDate: 0,
                maxDate: 0,
                dateFormat: 'DD/MM/YYYY',
            }}
        />

    </div>;
};

export default TimeCellEdit;
