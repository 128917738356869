
export default (inputDateTime) => {
    // Define regex patterns for validation
    const dateTimePattern = /^\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{2}(:\d{2})?$/;

    // Validate format: DD/MM/YYYY HH:mm or DD/MM/YYYY HH:mm:ss
    if (!dateTimePattern.test(inputDateTime)) {
        // Invalid format
        return null;
    }

    try {
        // Split date and time parts
        const [datePart, timePart] = inputDateTime.split(' ');

        // Split and destructure date components
        const [day, month, year] = datePart.split('/');

        // Split and destructure time components
        const [hours, minutes, seconds = '00'] = timePart.split(':');

        // Construct ISO 8601 date string
        const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

        // Create Date object and return timestamp in milliseconds
        const dateTime = new Date(isoDateString);

        return dateTime.getTime();
    } catch (error) {
        // Handle any unexpected errors
        console.error('Error parsing date-time:', error);

        return null;
    }
};
