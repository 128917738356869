import {faUnsorted} from '@fortawesome/free-solid-svg-icons';
import {faSortDown, faSortUp} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const RefAddButton = ({onDown, onUp, isTarget, isDown, sort, onReset}) => {
    let icon = faUnsorted;
    let color = '#003a6e33';

    if (sort && isTarget) {
        icon = isDown ? faSortDown : faSortUp;
        color = '#003a6e';
    }

    return <FontAwesomeIcon
        onClick={() => {
            if (!isTarget || !sort) {
                onDown();

                return;
            }

            if (isTarget && isDown) {
                onUp();

                return;
            }

            onReset();
        }}
        style={{margin: 5}}
        color={color}
        icon={icon}
    />;
};

export default RefAddButton;
