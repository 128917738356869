import {SpreadsheetService} from '@aktek/morph-frontend-sdk';

// import ParallelServiceRequestsManager from '@@Logical/TagSelector/ParallelServiceRequestsManager';

export default async ({value, _id, field, readMode}) => {
    if (Array.isArray(value) && value.every((ref) => Number.isInteger(ref?._id))) {
        return {isValid: true, finalValue: value};
    }

    if (typeof value != 'string' && !readMode) {
        return {isValid: false};
    }

    const request = {sboId: field.sboId, fieldId: field.fieldId, values: {}, canCreate: field.canCreateNew};
    const createdValue = value?.split(',').map((value) => value.trim());

    request.values[0] = createdValue;

    const key = field.sboId + '_' + field.fieldId + '-' + field.canCreateNew + '-' + createdValue + ' - ' + readMode;

    // const res = await ParallelServiceRequestsManager.dedup(SpreadsheetService.transformMultiRef, request, key);
    const res = await SpreadsheetService.transformMultiRef(request);

    if (!res.isSuccessful()) return {isValid: false};

    const data = res.getData();
    const _value = data[0];

    if (_value) {
        return {isValid: true, finalValue: _value};
    }

    return {isValid: true, finalValue: null};
};

