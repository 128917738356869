
import {Cell, Row} from '@tanstack/react-table';
import _isObject from 'lodash/isObject';

import MediaAlbumCellView from '@/components/Fields/MediaAlbum/MediaAlbum.Cell.View';
import Env from '@/config/env';

export const CellMediaAlbum = (columnDef) => ({
    cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
        const value = cell.getValue();

        if (!Array.isArray(value)) return null;

        const files = value.map((valueItem) => {
            const url = _getURLFromValue(valueItem, valueItem.isExternal);
            const mediaType = _getTypeFromValue(valueItem.type);

            return {url, name: valueItem.name, mediaType};
        });

        return <div className="w-64"> <MediaAlbumCellView value={files} /> </div>;
    },

});

const _getTypeFromValue = (value = '') => {
    const [fileType] = (value?.split?.('/') || []);

    return fileType;
};

const _getURLFromValue = (value, isExternal) => {
    if (!_isObject(value)) return Env.getFileURL(value);

    if (isExternal) return value.key;

    let url = Env.getFileURL(value.key);

    if (isExternal) url = value.key;

    return url;
};
