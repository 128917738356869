
import {Cell, Row} from '@tanstack/react-table';

import TimeCellView from '@/components/Fields/Time/Time.Cell.View';

export const CellTime = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = parseInt(row.getValue(cell.column.id));

            return <TimeCellView value={value} />;
        },
    };
};
