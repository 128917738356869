
import {Drawer} from '@aktek/f4kit';

import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';
import {openCloseDrawer} from '@/utils/openCloseDrawer';

import ExplorerSideBar from './components/ExplorerSideBar';
import FoldersRightPanel from './components/FoldersRightPanel';
import RecentlyUsed from './components/RecentlyUsed';
import {getClasses} from './styles/Explorer.styles';

export default function Explorer({}): React.ReactElement {
    const classes = getClasses();
    const {rightPanelView} = useGlobalFilesAndFolders();

    return <div className={classes.full}>
        <Drawer
            defaultOpen={ls.drawer?.explorer ?? true}
            onOpenChange={(e) => openCloseDrawer('explorer', !!e)}
            sideWidth={96}
            tooltipDelay={1000}
            buttonSize="sm"
            className="md:!w-1/3 sm:!w-2/5 !w-11/12"
        >
            <ExplorerSideBar />
            <>
                {rightPanelView == 'recent' && <RecentlyUsed />}
                {rightPanelView == 'folders' && <FoldersRightPanel title={strings('folders_and_data')}/>}
                {rightPanelView == 'shortcuts' && <FoldersRightPanel title={strings('shortcuts')}/>}
            </>
        </Drawer>
    </div>;
}
