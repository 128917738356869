import i18next from 'i18next';

import enFallback from './locales/en/fallback.json';
import enSpreadsheet from './locales/en/spreadsheet.json';

export const defaultNS = 'spreadsheet';
export const fallbackNS = 'fallback';

i18next.init({
    debug: false,
    fallbackLng: 'en',
    defaultNS,
    fallbackNS,
    resources: {
        en: {
            fallback: enFallback,
            spreadsheet: enSpreadsheet,
        },
    },
});

export const strings = i18next.t;
export type TStringKey = keyof typeof enFallback; // @TODO: handle nested keys
export type TStringValue = typeof enFallback[TStringKey];

export default i18next;
