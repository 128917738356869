import axios from 'axios';

import {strings} from '@/localization/i18n';

const ALLOWED_MAGIC_NUMBERS: Record<string, string[]> = {
    'image/jpeg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2'],
    'image/png': ['89504e47'],
    'image/jpg': ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2'],
};

type FileWithType = File & { type: string };

const isValidMagicNumber = (header: string, allowedTypes: string[]): boolean => {
    return allowedTypes.some((type) => ALLOWED_MAGIC_NUMBERS[type]?.includes(header));
};

export const isValidFileType = async (file: FileWithType, allowedTypes: string[]): Promise<boolean> => {
    try {
        const header = await _getFileHeader(file);

        return isValidMagicNumber(header, allowedTypes);
    } catch {
        return false;
    }
};

export const getExtensionFromFile = (file) => {
    return file.name.split('.').pop();
};

export const getFileNameFromFile = (file) => {
    return file.name?.split?.('.')?.[0];
};

export const uploadFileToURL = async (file, url) => {
    const options = {
        headers: {
            'Content-Type': file.type,
        },
    };

    return await axios.put(url, file, options);
};

const _getFileHeader = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onloadend = (e) => {
            const arrayBuffer = e.target?.result;
            if (!arrayBuffer) return reject(strings('failedToReadFile'));
            const uint = new Uint8Array(arrayBuffer).subarray(0, 4);
            const header = uint.reduce((acc, byte) => acc + byte.toString(16).padStart(2, '0'), '');
            resolve(header);
        };

        fileReader.onerror = () => reject(strings('failedToReadFile'));
        fileReader.readAsArrayBuffer(file.slice(0, 4));
    });
};

