import {Card, ContextMenu, Icon, Image, Label, useContextMenuRef} from '@aktek/f4kit';
import {faDatabase, faRefresh, faTrashAlt} from '@fortawesome/pro-regular-svg-icons';
import {useCallback} from 'react';

import {addRecentlyUsedDS} from '@/app/Admin/Data/pages/DataStructure/helpers/RecentlyUsedDataStructure';
import {focusOrOpenNewTab, openTab, Tabs} from '@/app/Home/Tabs';
import AskForConfirmation from '@/components/Modals/AskForConfirmation';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import AskForFolder from '@/components/Modals/AskForFolder';
import Env from '@/config/env';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {CopyToClipboard} from '@/helpers/CopyToClipboard.fn';
import {GetFolderPath} from '@/helpers/GetFolderPath.fn';
import {strings} from '@/localization/i18n';

import {FOLDERS_AND_DATA} from '../constants/constantIds.c';
import {GetFolderContextMenu, GetReportsContextMenu, GetShortcutContextMenu} from '../constants/getExplorerTreeContextMenu.c';
import {OnDeleteShortcut} from '../helpers/DeleteShortcutShortcut.fn';
import AddFolderButton from './AddFolderButton';
import EditMoveFolderModal from './EditMoveFolderModal';
import EmptyComponent from './EmptyComponent';
import ExplorerLoadingComponent from './ExplorerLoadingComponent';

const FoldersRightPanel = ({title}) => {
    const {folders: foldersPermissions} = useAdminPermissions();
    const {isLoading, parentFolder, setParentFolder, refresh, deleteFolder, deleteShortcut, openDataTable, moveDataTableLocation} = useGlobalFilesAndFolders();

    const handleDoubleClick = async (item) => {
        if (item.type == 'report') {
            const {type, label, id} = item;
            openDataTable({label, id, type});

            return;
        }

        if (item.type=='shortcut') return focusOrOpenNewTab(item.component, undefined, {selectedTab: item?.props?.selectedTab});

        setParentFolder(item);
    };

    if (!parentFolder?.children?.length) {
        return <EmptyComponent label={strings('no_folders_yet')} description={strings('no_folders_desc')} parentId={parentFolder?.id} />;
    }

    const contextMenuRef = useContextMenuRef();

    // Folder context menu functions
    const onDeleteFolder = useCallback((item) => {
        AskForConfirmation(
            `${strings('delete')} ${item.label}`,
            strings('delete_confirmation'),
            () => deleteFolder(item),
            'error',
            strings('delete'),
            faTrashAlt,
            null,
            ()=> {},
            item.id,
            null,
            null,
            {color: 'error-600', className: 'w-5 h-5'},
        );
    }, []);

    const onEditFolder = useCallback((item) => {
        AskForCreationModal(EditMoveFolderModal, {state: {mode: 'edit', _id: item.id}, item, refresh});
    }, []);

    const onCopyToClipboard = useCallback((item) => {
        CopyToClipboard(GetFolderPath(item.id));
    }, []);

    // Report context menu functions
    const onReportOpen = useCallback((item) => {
        const {label, id, type} = item;

        openDataTable({label, id, type});
    }, []);

    const moveDataTable = useCallback(async (item) => {
        const folderResults = await AskForFolder({item});
        moveDataTableLocation(folderResults as object);
    }, []);

    const editDataStructure = useCallback(async (item) => {
        openTab('dataStructureEditor', item.label, {sboId: item.id});
        const tab = {
            key: item.id,
            label: item.label,
            icon: faDatabase,
        };
        addRecentlyUsedDS(tab);
    }, []);

    const getContextMenu = (item) => {
        if (item.type == 'folder') {
            return GetFolderContextMenu(() => onCopyToClipboard(item), () => onEditFolder(item), () => onDeleteFolder(item));
        }

        if (item.type == 'report') {
            return GetReportsContextMenu(()=> onReportOpen(item), () => moveDataTable(item), () => editDataStructure(item));
        }

        if (item.type == 'shortcut') {
            return GetShortcutContextMenu(() => OnDeleteShortcut(item, deleteShortcut), ()=> focusOrOpenNewTab(item.component));
        }

        return null;
    };

    const onContextMenu = useCallback((event, item) => contextMenuRef.openAtEvent?.(event, getContextMenu(item)), []);
    const isAddButtonVisible = foldersPermissions?.can_create && (parentFolder.type == 'folder' || parentFolder.id == FOLDERS_AND_DATA);

    return (
        <div className="flex pb-6 flex-col px-4 gap-0 bg-neutral-50 w-full h-full overflow-y-auto overflow-x-hidden border-l border-neutral-200">
            <div className="flex flex-row py-4 bg-neutral-50 justify-between sticky elevation-2 !shadow-none top-0 w-full">
                <Label text={title} size="lg"
                    fontWeight="semibold" color="neutral-900"/>
                <div className="flex flex-row items-center justify-center gap-2" >
                    <Icon icon={faRefresh} color="neutral-600" className="cursor-pointer p-2.5" size="md" onClick={refresh} />
                    <AddFolderButton isVisible={isAddButtonVisible} refresh={refresh} parentId={parentFolder?.id} />
                </div>

            </div>

            <div className="grid gap-6 w-full max-w-none sm:grid-cols-3 lg:grid-cols-4">
                {parentFolder?.children?.map((item, index) => {
                    let icon;
                    if (item.type == 'shortcut' && item.component) icon = Tabs[item.component]?.icon;
                    else icon = item.icon;

                    const color = item.customColor ? item.customColor : 'neutral-400';

                    return <Card key={item.id + index}
                        className="flex items-center p-4 rounded-lg cursor-pointer border border-neutral-200 hover:border-neutral-300 transition-all ease-in-out duration-200 gap-3"
                        onContextMenu={(event) => onContextMenu(event, item)}
                        onDoubleClick={() => handleDoubleClick(item)}
                        borderColor="neutral-200"
                        backgroundColor="white"
                    >

                        {item?.customIcon
                        && <div className="w-6 h-6 border border-neutral-200 rounded-md">
                            <Image src={Env.getFileURL(item.customIcon)} alt="custom image" className="rounded-sm !w-full !h-full !object-cover"/>
                        </div>}
                        {!item?.customIcon && <Icon icon={icon} className="mr-2" color={color} />}

                        <Label text={item.label} size="xs"
                            className="cursor-pointer truncate !text-neutral-900" color="neutral-900" />
                    </Card>;
                })}
            </div>

            <ContextMenu contextMenuRef={contextMenuRef}><></></ContextMenu>
            <ExplorerLoadingComponent condition={isLoading} />
        </div>
    );
};

export default FoldersRightPanel;
