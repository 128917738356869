export default {
    sbo: null,
    columns: [],
    dataByKey: {1: []},
    count: 0,
    fields: [],
    forceTextCell: false,
    isSBOLoading: false,
    isDataLoading: false,
    isDataDeleting: false,
    isDataUpdating: false,
};
