import fetchSBOData from '../API/fetchSBOData';
import getClearAffectsByFieldId from './getClearAffectsByFieldId';
import getColumnFromField from './getColumnFromField';
import getEditableAndLockedFields from './getEditableAndLockedFields';
import getSupportedFields from './getSupportedFields';
import getTagColumn from './getTagColumn';
import mapFields from './MapFields';

export default async (sboId: string, mode) => {
    const response = await fetchSBOData(sboId);
    if (!response) return {sboId, columns: []};
    const {
        editableFieldsIDs,
        lockedSectionsAndFields,
        fieldsMap,
        sectionsMap,
        creationHasRecordTags,
        viewingHasRecordTags,
        sections,
    } = response;

    const isCreating = mode === 'creation';

    const {lockedSectionsSet, lockedFieldsSet, editableFieldsIDsSet}
     = getEditableAndLockedFields(editableFieldsIDs, lockedSectionsAndFields);

    const supportedFields = getSupportedFields(fieldsMap);

    const clearAffectsByField = getClearAffectsByFieldId(sections, sectionsMap, supportedFields, fieldsMap);

    const columns = mapFields(sections, sectionsMap, supportedFields, (field) => {
        if (!field) return;

        return getColumnFromField(field, lockedFieldsSet, lockedSectionsSet, editableFieldsIDsSet, fieldsMap, clearAffectsByField);
    });

    if (isCreating ? creationHasRecordTags : viewingHasRecordTags) {
        columns.push(getTagColumn(isCreating));
    }

    return {sboId, columns};
};

export const isConditionFunctionMatched = (conditionalStr)=>{
    const fn = new Function('o', `return ${conditionalStr};`);

    return fn;
};
