
import {Cell, Row} from '@tanstack/react-table';

import DatetimeCellView from '@/components/Fields/Datetime/Datetime.Cell.View';

export const CellDatetime = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = parseInt(row.getValue(cell.column.id));

            return <DatetimeCellView value={value} />;
        },

    };
};
