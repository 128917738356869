
import 'swiper/css';
import 'swiper/css/navigation';

import {ImageVideoViewer, Modal, TMediaTypes} from '@aktek/f4kit';
import {Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import downloadPlainFile from '@/helpers/handleDownloadFileHTTP';

type TFile = {
    url: string;
    mediaType?: TMediaTypes
    name?: string
}

type TImageVideoViewerSwiper = {
    files: Array<TFile>
    mediaType?: TImageVideoViewerProps['mediaType']
    sideLength?: TImageVideoViewerProps['sideLength']
    containerClassName: TImageVideoViewerProps['containerClassName']
}

import {TImageVideoViewerProps} from 'node_modules/@aktek/f4kit/dist/components/TierTwo/ImageVideoViewer/Types/ImageVideoViewerProps.t';

import {pagination} from './Pagination';

const ImageVideoViewerSwiper = ({files = [], mediaType, sideLength = 500, containerClassName}: TImageVideoViewerSwiper) => {
    const getContent = (_containerClassName, _sideLength) => files?.map?.((file, index) =>
        <SwiperSlide key={file.url + index} className="m-auto">
            <ImageVideoViewer
                sideLength={_sideLength}
                containerClassName={_containerClassName}
                url={file.url}
                mediaType={file.mediaType || mediaType || 'image'}
                label={file.name || ''}
                downloadFile={async (url, name) => {
                    return await downloadPlainFile(url, {name: name || file?.name}, null);
                }} />
        </SwiperSlide>,
    );

    return <Swiper
        pagination={pagination}
        grabCursor keyboard
        spaceBetween={0}
        onDoubleClick={(event) => {
        }}

        modules={[Pagination]}
        slidesPerView={1}
        mousewheel={false}>
        {getContent(containerClassName, sideLength)}
    </Swiper>;
};

export default ImageVideoViewerSwiper;
