import {FitHeight, TabsFlexLayout} from '@aktek/f4kit';
import {CompanySettingsService} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {focuseOrOpenTabFromShortcut, focusOrOpenNewTab} from '@/app/Home/Tabs';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import XApplyTheme from '@/helpers/ApplyTheme.fn';
import {strings} from '@/localization/i18n';

import AppHeader from './AppHeader';
import {TabFactory, TabsManager} from './Tabs';

export default function Home() {
    const {setLinkToFolder} = useGlobalFilesAndFolders();
    const [theme, setTheme] = useState(null);

    const getUpdatedDesign = async () => {
        const updatedDesign = await CompanySettingsService.getDesignSettings();

        const fetchedData = updatedDesign.getData()?.appearance;
        ls.theme = fetchedData;
        setTheme(fetchedData);
        const theme = {
            colors: fetchedData?.colors,
            font: fetchedData?.font,
        };
        XApplyTheme(theme);
    };

    const {shortcut, folder} = useParams();

    useEffect(() => {
        if (shortcut) focuseOrOpenTabFromShortcut(shortcut);

        if (folder) setLinkToFolder(folder);
    }, [shortcut, folder]);

    const {tabsSchema} = ls;
    useEffect(() => {
        !tabsSchema && focusOrOpenNewTab('home', undefined, undefined, {isPinned: true});
        getUpdatedDesign(); // @TODO: this call is buggy
    }, []);

    return (
        <FitHeight className="w-full h-full">
            <div className="px-2s">
                <AppHeader theme={theme} />
            </div>
            <div className="relative overflow-hidden h-full">
                <TabsFlexLayout
                    tabFactory={TabFactory}
                    tabsManager={TabsManager}
                    onModelChange={(modelJSON, cleanModelJSON) => ls.tabsSchema = modelJSON.toJson()}
                    defaultSchema={tabsSchema}
                />
            </div>
        </FitHeight>
    );
}

