import {LoadingIndicator} from '@aktek/f4kit';
import cn from 'classnames';

const ExplorerLoadingComponent = ({condition, className}: {condition:boolean, className?:string}) => {
    if (!condition) return null;

    return (
        <div className={cn('absolute inset-0 !shadow-none bg-neutral-200 opacity-50 elevation-5 flex items-center justify-center', className)}>
            <LoadingIndicator size="xl" />
        </div>
    );
};

export default ExplorerLoadingComponent;
