import React from 'react';

import {strings} from '@/localization/i18n';

interface BooleanProps {
    value: boolean | null | undefined; // Supports boolean and nullable values
    yesProp?: React.ReactNode; // Rendered if `value` is true
    noProp?: React.ReactNode; // Rendered if `value` is false
    className?: string; // Optional className for styling
    onChange?: (isTrue: boolean) => void
}

const Boolean: React.FC<BooleanProps> = ({value, yesProp = strings('yes'), noProp = strings('no'), className, onChange}) => {
    return (
        <div className={className} onClick={() => {
            console.log('....Boolean.tsx__16__: ', value, '\n_________');
            onChange?.(!value);
        }}>
            {value ? yesProp : noProp}
        </div>
    );
};

export default Boolean;
