import AutomaticCell from '@/components/Fields/Automatic/Automatic';

export const CellAutomatic = (columnDef) => {
    const isTime = columnDef.field?.dynamicField === 'Time';

    return {
        cell: ({cell}: { cell: Cell<unknown, unknown> }) => {
            const value = cell.getValue() + '';

            return <AutomaticCell value={value} isTime={isTime} />;
        },
    };
};
