import {F4Form, F4FormContainer, F4FormDiv, useF4FormRef} from '@aktek/f4form';
import {Stepper, TabNav, toast, TStepperProps, TTabNavProps} from '@aktek/f4kit';
import cn from 'classnames';
import _isFunction from 'lodash/isFunction';
import {F4FormData} from 'node_modules/@aktek/f4form/dist/Core/types/F4FormCommonTypes.t';
import {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';

import {F4CreatableFormContextProvider} from './F4CreatableFormContext';
import {F4CreatableFormProps} from './types/F4CreatableFormProps.t';

function F4CreatableForm({
    formKey,
    getFunction,
    storeFunction,
    onPreSave,
    f4FormProps = {},
    onSave,
    sideBarExtraElement,
    sideBarExtraElementPosition = 'top',
    steps,
    defaultStep,
    children,
    onStepChange,
    _id,
    mode = 'create',
    onDataLoad,
    onPreGetItem,
    extra,
    createSuccessMessage,
    editSuccessMessage,
    errorMessage,
    onSetValue,
    childrenClassName,
    className,
    isLoading,
    setIsLoading,
}: F4CreatableFormProps, ref) {
    const f4FormSteps = useMemo(() => {
        if (!steps?.length) return null;

        return steps.map((step) => step.key);
    }, [steps]);

    const hasSteps = !!steps?.length;

    const hasSidebar = hasSteps || !!sideBarExtraElement;
    const formRef = useF4FormRef();
    const isSaving = useRef<boolean>(false);

    const [currentStep, setCurrentStep] = useState(defaultStep ? steps?.findIndex?.((e) => e.key == defaultStep) : 0);

    const [initialValue, setInitialValue] = useState<F4FormData>({});

    const [errorSteps, setErrorSteps] = useState<string[]>();

    useImperativeHandle(ref, () => ({
        currentStep,
        isLoading,
        goToPreviousStep,
        goToNextStep,
        saveForm,
        setValue: (key, value) => formRef?.current?.setValue(key, value),
        getData: () =>{
            return formRef?.current?.getData();
        },

    }), [currentStep]);

    useEffect(() => {
        handleIDChange();
    }, [_id]);

    const handleIDChange = async () => {
        await onPreGetItem?.(_id);
        if (mode === 'create') return setInitialValue(null);

        if (_id && ['edit', 'view'].includes(mode)) {
            getItemCallback();
        }
    };

    const goToPreviousStep = () => {
        if (currentStep !== 0 ) {
            setCurrentStep(currentStep - 1);
            formRef.current.previousStep();
        } else {
            ref.current?.close?.();
        }
    };

    const goToNextStep = () => {
        const validation = formRef.current.validate({currentStepOnly: true});

        if (!validation.isFormValid) {
            setErrorSteps([steps[currentStep].key]);
            requestAnimationFrame(() => {
                const errorField = document.getElementById('error-field');

                if (errorField) {
                    errorField.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
                }
            });

            return;
        }

        if (currentStep < steps?.length - 1) {
            setCurrentStep(currentStep + 1);
            formRef.current.nextStep();
            setErrorSteps(['']);
        } else {
            _storeItem(formRef.current.getData(), mode);
        }
    };

    const saveForm = () => {
        const validation = formRef.current.validate();
        requestAnimationFrame(() => {
            const errorField = document.getElementById('error-field');

            if (errorField) {
                errorField.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
            }
        });

        if (!validation.isFormValid) return;
        else _storeItem(formRef.current.getData(), mode);
    };

    const getItemCallback = useCallback(async () => {
        if (!getFunction) return null;

        setIsLoading(true);
        const res = await getFunction(_id);

        if (res.isSuccessful()) {
            const data = res.getData() as F4FormData;
            const _data = _isFunction(onDataLoad) ? onDataLoad?.(data) : data;
            setInitialValue(_data);
        }
        // else {
        //     toast.error(strings('failed'));
        // }

        setIsLoading(false);
    }, [_id, getFunction]);

    const _storeItem = useCallback(async (item, mode?) => {
        if (isSaving.current) return;

        isSaving.current = true;
        if (!storeFunction) return null;
        const data = typeof onPreSave == 'function' ? onPreSave(item) : item;

        setIsLoading(true);

        const res = await storeFunction(data, mode, _id);

        if (res.isSuccessful()) {
            mode == 'create'
                ? toast.success(createSuccessMessage)
                :toast.success(editSuccessMessage);

            setIsLoading(false);
            onSave?.();
        }

        isSaving.current = false;
        setIsLoading(false);
    }, [storeFunction]);

    const defaultStepIndex = steps?.findIndex((e) => e.key === defaultStep);
    const NavElement = hasSteps && (mode == 'create' ? Stepper : TabNav);
    const navElementCondProps = hasSteps
        ? mode == 'create'
            ? {
                steps,
                errorSteps: errorSteps,
                buttonClassName: 'text-wrap text-left h-fit',
                value: currentStep,
                isJumpable: false,
                defaultValue: defaultStepIndex === -1 ? 0 : defaultStepIndex,
                orientation: 'vertical',
                onChange: (value: unknown) => {
                    setCurrentStep(value as number);
                    formRef.current.goToStepIndex(value as number);
                },
            } as Partial<TStepperProps>
            : {
                value: currentStep,
                orientation: 'vertical',
                tabs: steps,
                isDisabled: isLoading,
                defaultValue: defaultStep ? defaultStep : steps[0].key,
                onChange: (value: string) => {
                    const newValue = steps?.findIndex((e) => e.key == value);
                    setCurrentStep(newValue);
                    formRef.current.goToStepIndex(newValue);
                },
                className: 'w-full',
                buttonClassName: 'text-wrap text-left h-fit',
            } as Partial<TTabNavProps>
        : null;

    return (
        <F4Form
            className={cn(className, '')}
            containerClassName="h-full"
            formKey={formKey}
            ref={formRef}
            onSetValue={onSetValue}
            {...f4FormProps}
            printData="console"
            initialValue={initialValue}
            steps={f4FormSteps}
            defaultStep={defaultStep}
            onStepIndexChange={onStepChange}
            layout={{
                gap: 0.625,
                minColumnWidth: 6.25,
                hasGridLayout: true,
            }}
            isLoading={isLoading}>

            {hasSidebar
                && <F4FormDiv
                    className="sticky top-0 flex flex-col items-start gap-2 pl-2 pr-2 border-r border-neutral-100 h-full max-w-full"
                    colSpan={3}>
                    {sideBarExtraElementPosition == 'top' && sideBarExtraElement}

                    {hasSteps && <NavElement {...navElementCondProps} />}

                    {sideBarExtraElementPosition == 'bottom' && sideBarExtraElement}
                </F4FormDiv>
            }

            <F4FormContainer
                rowAutoSize="3.125rem"
                className={cn(childrenClassName, 'p-4 overflow-auto h-full')}
                colSpan={hasSidebar ? 9 : 12}
                layout={{hasGridLayout: true, gap: 20}}>
                <F4CreatableFormContextProvider extra={extra}>
                    {children}
                </F4CreatableFormContextProvider>
            </F4FormContainer>
        </F4Form>
    );
}

export default forwardRef(F4CreatableForm);
