import {Spreadsheet} from '@aktek/f4kit';
import React from 'react';

import createNewMorphs from './API/createNewMorphs';
import _deleteRows from './API/deleteRows';
import saveModifications from './API/saveModifications';
import {Config} from './MorphSpreadsheet.Config';
import transformPastedData from './PastedDataParsers/transformPastedData';
import renderCell from './Render/RenderCell';
import useMorphSpreadsheet from './useMorphSpreadsheet';
import ValidateBulkCell from './validations/ValidateBulkCell';
import validateCell from './validations/ValidateCell';

const MorphSpreadsheet = (props) => {
    const state = useMorphSpreadsheet(props);

    const tableManagerRef = React.useRef();

    if (state.isEmpty) {
        return <div> empty </div>;
    }

    if (!state.dataState.columns?.length) {
        return <div>
            refresh

        </div>;
    }

    return <Spreadsheet alertMode
        tableManagerRef={tableManagerRef}
        topBarRightAdditionalView={<div>Right</div>}
        idColumn="_id"
        allowAddingNewRecords={props.mode == 'creation'}
        initialCount={props.mode == 'creation' ? 1 : 0}
        sorting={state.sorting}
        setSorting={state.setSorting}
        deleteRows={(ids) => _deleteRows(props.sboId, ids)}
        onSave={props.onSave}
        confirm={async (title, message) => {
            console.log('....F4TableSpreadsheet.tsx__53__: confirm', {title, message}, '\n_________');

            return true;
        }}
        aSyncCellValidation={async (cellInfo) => {
            const result = await validateCell(cellInfo);

            console.log('....F4TableSpreadsheet.tsx__41__: ', {result}, '\n_________');

            return result;
        }}
        aSyncBulkCellValidation={async (cells) => {
            const result = ValidateBulkCell(cells);
            console.log('....F4TableSpreadsheet.tsx__57__: ', {result}, '\n_________');

            return result;
        }}
        onChange={(tableState) => {
            state.setTableState(tableState);
        }}
        columns={state.dataState.columns}
        pastedDataTransformer={(rowIndex, colIndex, pastedValue) => {
            const column = state.dataState.columns[colIndex];
            const field = column.field;

            return transformPastedData(field, pastedValue);
        }}
        defaultData={state.dataState.dataByKey[state.key]}
        cellRenderer={({colIndex, ...rest}) => {
            const column = state.dataState?.columns[colIndex];

            return renderCell({column, tableManagerRef, ...rest});
        }}
        customStyles={{
            thContainer: {whiteSpace: 'nowrap'},
        }}
        config={Config}
        createNewRows={(newIds, modifications) => createNewMorphs(props.sboId, newIds, modifications)}
        saveModifications={(modifications) => saveModifications(props.sboId, modifications)}
    />;
};

export default React.forwardRef(MorphSpreadsheet);
