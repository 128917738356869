
import {ImageVideoViewer} from '@aktek/f4kit';
import {Cell, Row} from '@tanstack/react-table';
import _isObject from 'lodash/isObject';

import ImageVideoViewerSwiper from '@/components/Swipers/ImageVideoViewerSwiper';
import Env from '@/config/env';
import downloadPlainFile from '@/helpers/handleDownloadFileHTTP';
import {strings} from '@/localization/i18n';

export const CellMediaImage = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = cell.getValue();

            if (Array.isArray(value)) {
                const files = value.map((valueItem) => {
                    const url = _getURLFromValue(valueItem, valueItem.isExternal);

                    return {
                        url,
                        name: valueItem.name,
                    };
                });

                return <div className="w-64">
                    <ImageVideoViewerSwiper
                        containerClassName="w-64 h-64"
                        mediaType="image"
                        sideLength={256}
                        files={files}
                    />
                </div>;
            }

            if (typeof value === 'string') {
                return <div className="w-64">
                    <ImageVideoViewer
                        sideLength={256}
                        containerClassName="w-64 h-64"
                        url={value}
                        mediaType={'image'}
                        label={strings('view_file')}
                        downloadFile={async (url, name) => {
                            return await downloadPlainFile(url, name, null);
                        }} />
                </div>;
            }

            return <div className="w-32">
            </div>;
        },

    };
};

const _getURLFromValue = (value, isExternal) => {
    if (!_isObject(value)) return Env.getFileURL(value);

    if (isExternal) return value.key;

    let url = Env.getFileURL(value.key);

    if (isExternal) url = value.key;

    return url;
};
