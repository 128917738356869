import {F4FormElementDecorator} from '@aktek/f4form';
import {Button} from '@aktek/f4kit';
import {AvatarLabelGroup} from '@aktek/f4kit';
import {UserService} from '@aktek/helios-frontend-sdk';
import {TAugmentedResponse} from '@aktek/helios-frontend-sdk/out/service';
import cn from 'classnames';
import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';

import CreateUserModal from '@/app/Admin/AccessControl/pages/Users/components/CreateUserModal';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import SearchBox from '@/components/SearchBox/SearchBox';
import Env from '@/config/env';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {GetNameInitials} from '@/helpers/GetNameInitial.fn';
import {strings} from '@/localization/i18n';

import AskForConfirmation from '../Modals/AskForConfirmation';

type TUserSelectorProps = {
    value: unknown,
    onChange: (value) => void;
    className?: string;
    fetchSelectedUsers?: () => Promise<TAugmentedResponse<unknown>>
    state?:unknown
    readOnly?:boolean
}

const UserSelector = ({value = [], onChange, className,
    fetchSelectedUsers, state: initialValue=[], readOnly = false} : TUserSelectorProps) => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState(null);
    const {users: usersPermissions} = useAdminPermissions();

    useEffect(() => {
        if (!usersPermissions?.can_view) return;
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        const res = await UserService.getAllUsers();

        if (res.isSuccessful()) {
            const data = res.getData() as object[];
            setUsers(data);
        }
    };

    const fetchSelectedUsersFunction = async () => {
        const response = await fetchSelectedUsers();

        if (response.isSuccessful()) {
            const data = response.getData() as object[];
            onChange(data);
        }
    };

    const filteredUsers = useMemo(() => {
        return _.chain(users)
            .filter((user) => {
                const userSearchTerm = user.name?.toLowerCase().includes(search);
                const userEmailSearchTerm = user.email?.toLowerCase().includes(search);

                return !search || userSearchTerm || userEmailSearchTerm;
            })
            .sortBy((user) => !value?.includes(user._id))
            .value();
    }, [users, search]);

    const resetUsers = () => {
        onChange(initialValue);
    };

    return (
        <div className={`${className}`}>
            <div className="flex items-center gap-2 py-4 cursor-pointer -mt-7
                justify-between w-full h-full sticky -top-4 elevation-5 !shadow-none bg-white" >
                <SearchBox
                    placeholder={strings('search_users')}
                    className="!bg-neutral-100 rounded-lg w-full -ml-1"
                    textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                    hasBottomBorder={false}
                    value={search}
                    onSearch={(value) => setSearch(value.toLowerCase())}
                />

                {!readOnly && <div className="flex flex-row items-end justify-end gap-2  w-fit ">

                    {usersPermissions?.can_create && <Button
                        label={strings('add_user')}
                        textColor="neutral-700"
                        variant="white"
                        onClick={() => AskForCreationModal(CreateUserModal, {onSave: () => {
                            fetchUsers();
                            fetchSelectedUsers && fetchSelectedUsersFunction();
                        }, state: {mode: 'create', _id: null}})}
                    />}

                    <Button
                        label={strings('reset_users')}
                        textColor="neutral-700"
                        variant="white"
                        isDisabled={_.isEqual(initialValue.sort(), value.sort())}
                        className={`ml-1 !px-5 ${_.isEqual(initialValue.sort(), value.sort()) && '!bg-neutral-200'}`}
                        onClick={() => AskForConfirmation(
                            strings('reset_users'),
                            strings('reset_users_confirmation'),
                            resetUsers,
                            'primary',
                            strings('reset_users'),
                        )}
                    />
                </div>}
            </div>

            <div className="overflow-y-auto pt-4" >
                {filteredUsers.length > 0 ? (
                    filteredUsers.map((user, index) => {
                        const isSelected = value?.includes(user._id);
                        const avatarAltLabel = !user.profilePicURL ? GetNameInitials(user.name) : null;
                        const img = user.profilePicURL ? {
                            src: Env.getFileURL(user.profilePicURL),
                            alt: 'Profile Picture',
                        }: null;

                        return (
                            <div
                                onClick={() => {
                                    if (readOnly) {
                                        return;
                                    }

                                    let mutableArray = Object.assign([], value);

                                    if (isSelected) {
                                        mutableArray = mutableArray.filter((e) => e != user._id);
                                    } else {
                                        mutableArray.push(user._id);
                                    }

                                    onChange(mutableArray);
                                }}
                                key={user._id}
                                className={`flex flex-col items-center `}
                            >
                                <AvatarLabelGroup
                                    key={user._id}
                                    isDisabled={readOnly}
                                    size="sm"
                                    label={{text: user.name}}
                                    subLabel={{text: user.email}}
                                    avatar={{
                                        img: img,
                                        avatarAltLabel: avatarAltLabel,
                                        avatarClassName: cn(
                                            {
                                                'border-none': avatarAltLabel && !isSelected,
                                            },
                                        ),
                                    }}
                                    value={isSelected}
                                    className="mb-2"
                                />
                            </div>
                        );
                    })
                ) : <div className="px-2 py-4 text-center text-neutral-600">{strings('no_results_found')}</div>
                }
            </div>
        </div>
    );
};

const F4UserSelector = F4FormElementDecorator<TUserSelectorProps>(UserSelector);
export default F4UserSelector;
