import ImageVideoViewerSwiperUpload from '@/components/Swipers/ImageVideoViewerSwiperUpload';
import Env from '@/config/env';
import {getExtensionFromFile, getFileNameFromFile} from '@/helpers/File';

const MediaImageCellEdit = ({value, onChange}) => {
    const files = value?.map?.((item) => {
        return {
            url: Env.getFileURL(item.key),
            name: item.name,
            mediaType: 'image',
        };
    });

    return <div className="">
        <ImageVideoViewerSwiperUpload
            extensions={['jpeg', 'jpg', 'png']}
            mediaType={'image'}
            files={files}
            onChange={(files) => onChange(files)}
            onNewFiles={(newFiles) => {
                const filesToSave = newFiles.map((newFile) => ({
                    key: newFile.name,
                    extension: getExtensionFromFile(newFile.originalFile),
                    name: getFileNameFromFile(newFile.originalFile),
                    type: newFile.originalFile?.type,
                }));
                onChange([...value, ...filesToSave]);
            }}
        />
    </div>;
};

export default MediaImageCellEdit;
