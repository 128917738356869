import _isFinite from 'lodash/isFinite';

export default (
    value: unknown,
    field: { min?: number | string, max?: number | string },
) => {
    const parseNumber = (val: unknown): number | null => {
        if (val === '' || val === undefined) return null;
        const parsed = typeof val === 'number' ? val : parseFloat(val);

        return _isFinite(parsed) ? parsed : null;
    };

    const parsed = parseNumber(value);
    if (parsed === null) return null;

    const min = parseNumber(field.min);
    const max = parseNumber(field.max);

    let result = parsed;
    if (min !== null) result = Math.max(result, min);
    if (max !== null) result = Math.min(result, max);

    return result;
};
