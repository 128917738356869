import {F4FormDiv, F4Nestor} from '@aktek/f4form';
import {Button, ContentDivider, Label} from '@aktek/f4kit';
import Atlas from '@aktek/ops-atlas';
import {useEffect, useRef} from 'react';

import {strings} from '@/localization/i18n';

import {DEFAULT_CENTER, DEFAULT_ZOOM} from '../constants/Settings.c';
import {TGeographicalProps} from '../types/GeographicalProps.t';

export default function Geographical({mapHeight, mapWidth, geo, setValue} : TGeographicalProps) {
    if (!geo) return;
    const {center = DEFAULT_CENTER, zoom = DEFAULT_ZOOM} = geo?.geo_center || {};
    const defaultLayer = geo?.default_base_layer;

    useEffect(() => {
        if (!mapRef?.current) return;
        setTimeout(() => {
            mapRef.current?.setMapZoom?.(zoom);
            mapRef.current?.setMapCenter?.(center);
        } );
    }, [zoom, center?.lat, center?.lng]);

    const mapRef = useRef();

    return (
        <F4Nestor name="geo">
            <F4FormDiv colSpan={12} className="mt-8">
                <ContentDivider
                    backgroundColor="neutral-50"
                    middleChild={
                        <Label text={strings('geographical')}
                            className="text-neutral-600 font-medium"
                        />}
                />
            </F4FormDiv>
            {/* <Button
                label={'zooom'}
                onClick={()=>{
                    mapRef.current?.setZoom(10);
                }}
            /> */}
            <F4FormDiv colSpan={12} className="flex justify-center items-center">
                <Atlas hasTimeline hideDrawing
                    // ref={mapRef}
                    center= {[center.lat ?? center[0], center.lng ?? center[1]]}
                    zoom={zoom}
                    defaultLayer={defaultLayer}
                    height={mapHeight}
                    width={mapWidth}
                    onMapClick= {(position) => setValue('geo_center.center', position)}
                    onMapViewChange= {(result) => setValue('geo_center.zoom', result.zoom)}
                    onMapTypeChange={(type) => setValue('default_base_layer', type)}
                />
            </F4FormDiv>
        </F4Nestor>
    );
}
