// Define arrays for true and false values in various languages
const TRUE_VALUES = [
    'true', '1', 'yes', 'y', 'on', 'si', 'oui', 'da', 'ja', 'sì', 'sim', 'tak', 'כן',
];

const FALSE_VALUES = [
    'false', '0', 'no', 'n', 'off', 'non', 'nein', 'não', 'нет', 'ไม่ใช่', 'نا',
];

export default (value) => {
    // Directly return if value is already a boolean
    if (typeof value === 'boolean') {
        return value;
    }

    // Check for null or undefined
    if (value === null || value === undefined) {
        return null;
    }

    // Convert value to string and trim whitespace
    const strValue = value.toString().trim().toLowerCase();

    // Check if the value is in the TRUE_VALUES array
    if (TRUE_VALUES.includes(strValue)) {
        return true;
    }

    // Check if the value is in the FALSE_VALUES array
    if (FALSE_VALUES.includes(strValue)) {
        return false;
    }

    // Return null if the value cannot be parsed to boolean
    return null;
};
