import {FileUpload} from '@aktek/f4kit';
import React from 'react';
import {useCallback} from 'react';

import ImageVideoViewerSwiper from '@/components/Swipers/ImageVideoViewerSwiper';
import Env from '@/config/env';
import {uploadFileToURL} from '@/helpers/File';

const ImageVideoViewerSwiperUpload = ({onChange, onNewFiles, files, mediaType, extensions}) => {
    const [loading, setLoading] = React.useState(false);

    const uploadFile = async (file) => {
        setLoading(true);
        const {url, fileName} = Env.getNewEmptyFileURL();

        try {
            // await uploadFileToURL(file, url);
            const _file = {
                url,
                name: fileName,
                mediaType: file.type,
                originalFile: file,
            };

            return _file;
        } catch (err) {
            console.log('....', err);
        } finally {
            setLoading(false);
        }
    };

    const upload = useCallback(async (files) => {
        if (loading) return;

        const newFiles = await Promise.all(files.map(uploadFile));
        onNewFiles(newFiles);
    }, []);

    const _onChange = useCallback((value) => {
        upload(value);
    }, []);

    return <div className="max-w-64">
        <ImageVideoViewerSwiper
            containerClassName="w-64 h-64"
            mediaType={mediaType}
            sideLength={256}
            onChange={(files) => onChange(files)}
            files={files}
        />
        <FileUpload
            extensions={extensions}
            uploadButtonSize={'xl'}
            label=""
            onChange={_onChange}
            description={''}
            className="p-1 w-10"
        />
    </div>;
};

export default ImageVideoViewerSwiperUpload;
