import {ActionsModal, Icon, Label} from '@aktek/f4kit';
import {faTrashAlt} from '@fortawesome/pro-regular-svg-icons';
import {forwardRef} from 'react';

import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';

import {getDeleteClasses} from '../styles/Delete.styles';

const DeleteModal = ({item}, ref:React.ForwardedRef<unknown>) => {
    const classes = getDeleteClasses();
    const {deleteFolder} = useGlobalFilesAndFolders();

    return (
        <ActionsModal
            hasXIcon={false}
            ref={ref}
            className={classes.actionsModal}
            confirmLabel={strings('delete')}
            confirmVariant="error"
            onConfirm={() => deleteFolder(item)}

        >
            <div className={classes.formDiv}>

                <div className={classes.iconDiv}>
                    <Icon icon={faTrashAlt} color="error-600"
                        className={classes.icon} />
                </div>

                <div className={classes.labelDiv}>
                    <Label text={`${strings('delete')} ${item.label}`}
                        fontWeight="semibold" size="lg"
                        className={classes.deleteDiv} />

                    <Label className="text-center" size="sm"
                        text={strings('delete_confirmation')} />
                </div>

            </div>

        </ActionsModal>
    );
};

export default forwardRef(DeleteModal);
