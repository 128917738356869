import {F4FormDiv, useF4FormRef} from '@aktek/f4form';
import {Icon, Label} from '@aktek/f4kit';
import {faFolder} from '@fortawesome/pro-regular-svg-icons';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isUndefined from 'lodash/isUndefined';
import {useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4TreeView from '@/components/F4Elements/F4TreeView';
import AskForModal from '@/components/Modals/AskForModal';
import ImageOrColorSelectorModal, {TImageColorSelectorProps} from '@/components/Modals/ImageOrColorSelectorModal';
import SearchBox from '@/components/SearchBox/SearchBox';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {GetFirstKey} from '@/helpers/GetFirstKey.fn';
import {strings} from '@/localization/i18n';

import {FOLDERS_AND_DATA} from '../constants/constantIds.c';
import useFilteredItems from '../hooks/useFilteredItems';

const EditMoveFolderModal = (props: F4CreatableModalProps) => {
    const {openOnMount, state, item, refresh, ...rest}= props;
    const {folders: items, editFolder} = useGlobalFilesAndFolders();
    const [searchValue, setSearchValue] = useState('');

    const [info, setInfo] = useState({
        folderName: item?.label,
        parentId: undefined,
        icon: item?.customIcon,
        // add the color later
    });

    const updateInfoState = (key, value) => setInfo((state) => ({...state, [key]: value}));

    const data = useFilteredItems(items, searchValue, true, 'folder', item.id, item.parentId);
    const hasData = data && data.length > 0;

    const ref = useF4FormRef();

    const getFunction = (finalInitialValue) => {
        const defaultParentId = finalInitialValue?.parentId ? {[finalInitialValue?.parentId]: true} : {[FOLDERS_AND_DATA]: true};

        const customizedInitialValue = {
            ...finalInitialValue,
            parentId: defaultParentId,
        };

        return {
            isSuccessful: () => true,
            getData: () => customizedInitialValue,
        };
    };

    // conditions for the disabling of the save button
    const rules = {
        isParentIdMatching: _isEqual(info.parentId, item?.parentId),
        isFolderNameMatching: _isEqual(info.folderName, item?.label),
        isFolderNameEmpty: _isEmpty(info.folderName),
        isParentIdUndefined: _isUndefined(info.parentId),
        isIconMatching: _isEqual(info.icon, item?.customIcon),
    };

    const isSaveButtonDisabled = (
    // Folder name must match or be empty
        (rules.isFolderNameMatching || rules.isFolderNameEmpty)
    // Parent ID must be undefined or match
    && (rules.isParentIdUndefined || rules.isParentIdMatching)
    // Icon must match
    && rules.isIconMatching
    );

    const handleTreeViewChange = (value) => {
        const itemChecked = GetFirstKey(value);
        if (itemChecked == FOLDERS_AND_DATA) return updateInfoState('parentId', null);
        updateInfoState('parentId', itemChecked);
    };

    const defaultTreeValue = item?.parentId ? {[item?.parentId]: true} : {[FOLDERS_AND_DATA]: true};
    const defaultTreeValueId = GetFirstKey(defaultTreeValue);
    const isFoldersAndDataSelected = defaultTreeValueId != FOLDERS_AND_DATA;

    return (
        <F4CreatableModal
            state={state}
            title={strings('folder')}
            formKey="folder"
            ref={ref}
            hasSidebar={false}
            openOnMount={openOnMount}
            onSave={refresh}
            storeFunction={editFolder}
            isDisabled={isSaveButtonDisabled}
            getFunction={() => getFunction(item)}
            editSuccessMessage={strings('edit_folder_success')}
            errorMessage={strings('edit_folder_failed')}
            {...rest}
        >

            <F4FormDiv>

                <F4FormDiv colSpan={12} >
                    <div className="sticky !shadow-none elevation-5 -top-4 bg-white -mt-1">

                        <div className="flex items-center justify-start gap-3 mb-6" >
                            <div className="bg-primary-25 rounded-xl p-4 flex items-center justify-center w-fit -mb-4">
                                <Icon icon={faFolder} color="primary-600"
                                    size="xl" onClick={() => AskForModal<TImageColorSelectorProps>(ImageOrColorSelectorModal, {
                                        imageFormName: 'icon',
                                        setValue: (name, value) => {
                                            ref?.current?.setValue(name, value);
                                            updateInfoState(name, value);
                                        },
                                        canSetColor: false,
                                    })} />
                            </div>

                            <F4Textbox
                                name="label"
                                label={strings('folder_name')}
                                required
                                placeholder=""
                                onChange={(e:string) => updateInfoState('folderName', e.trim())}
                                type="text"
                                colSpan={8}
                                f4FormDivClassName="flex flex-col justify-center items-start"
                            />
                        </div>
                        <SearchBox onSearch={setSearchValue}

                            className="!bg-neutral-100 rounded-lg mb-4"
                            textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                        />
                    </div>
                    {hasData && <F4TreeView
                        name="parentId"
                        data={data}
                        size="xs"
                        showOptional={false}
                        defaultValue={defaultTreeValue}
                        activeNode={defaultTreeValueId}
                        nodeMoveTargetFilter={(e) => e.type == 'folder'}
                        onChange={handleTreeViewChange}
                        scrollTimeOut={2200}
                        scrollToDefaultValue={isFoldersAndDataSelected}
                        areAllNodesExpanded={true}
                        checkboxFilter={(node) => node.type == 'folder'}
                        checkMode="single"
                    />}
                    {!hasData && <Label text={strings('no_results_found')} size="sm" className="mt-5 ml-3 overflow-hidden" color="neutral-600"/>}

                </F4FormDiv>
            </F4FormDiv>
        </F4CreatableModal>

    );
};

export default EditMoveFolderModal;
