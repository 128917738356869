import {RolesService} from '@aktek/helios-frontend-sdk';
import {faCrown} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {useMemo, useState} from 'react';

import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';

export function userRoleSelector(fetchSelectedRoles, onChange, value, search) {
    const [_roles, setRoles] = useState([]);

    const {roles} = useAdminPermissions();

    const fetchRoles = async () => {
        if (!roles?.can_view) return;
        const res = await RolesService.getAllRoles();

        if (!res.isSuccessful()) return;

        const data = res.getData();
        const mappedRoles = data.map((role) => ({
            key: role.id,
            label: role.name,
            icon: role.overridePermissions ? faCrown : null,
            iconColor: 'warning-300',
        }));
        setRoles(mappedRoles);
    };

    const fetchSelectedRolesFunction = async () => {
        const response = await fetchSelectedRoles();

        if (!response.isSuccessful()) return;
        const data = response.getData() as object[];
        onChange(data);
    };

    const filteredRoles = useMemo(() => {
        return _.chain(_roles)
            .filter((role) => {
                const roleSearchTerm = role.label?.toLowerCase().includes(search);

                return !search || roleSearchTerm;
            })
            .sortBy((role) => !value?.includes(role.key))
            .value();
    }, [_roles, search]);

    return {
        roles: filteredRoles,
        fetchRoles,
        fetchSelectedRolesFunction,
    };
}
