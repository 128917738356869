import {Drawer, TabNav, useTab} from '@aktek/f4kit';
import {useEffect} from 'react';

import useDocumentTitle from '@/hooks/useDocumentTitle';
import {strings} from '@/localization/i18n';
import {openCloseDrawer} from '@/utils/openCloseDrawer';

import {GetCompanySettingsTabs} from './constants/companySettings.c';
import Appearance from './pages/Appearance/Appearance';
import Settings from './pages/Settings/Settings';
import Tags from './pages/Tags/Tags';

export default function CompanySettings(props) {
    const tab = useTab();
    useEffect(() => {
        props.selectedTab && tab.setState({selectedTab: props.selectedTab});
    }, [tab.config?.tabSelectedAt]);

    const companySettingsTabs = GetCompanySettingsTabs();
    const firstVisibleTabKey = companySettingsTabs.find((tab)=>tab.isVisible)?.key;
    const selectedTab = tab.state?.selectedTab || firstVisibleTabKey;

    useDocumentTitle(strings('company_settings'));

    return (
        <div className="flex h-full">
            <Drawer
                sideWidth={96}
                defaultOpen={ls.drawer?.companySettings ?? true}
                tooltipDelay={1000}
                onOpenChange={(e) => openCloseDrawer('companySettings', !!e)}
                buttonClassName="elevation-2 !shadow-none"
                buttonSize="sm"
                className="sm:!w-2/4 md:!w-1/4  !w-11/12 "
            >

                <TabNav
                    size="sm"
                    orientation="vertical"
                    tabs={GetCompanySettingsTabs()}
                    value={selectedTab}
                    defaultValue={selectedTab || 'settings'}
                    onChange={(selectedTab) => tab.setState({selectedTab})}
                    buttonClassName="[&>:first-child]:[&>:first-child]:[&>:first-child]:!w-8"
                    className="p-4 !m-0"
                />
                <div className="h-full overflow-auto">
                    {(!selectedTab || selectedTab === 'settings') && <Settings />}
                    {selectedTab === 'appearance' && <Appearance />}
                    {selectedTab === 'tags' && <Tags />}
                </div>
            </Drawer>
        </div>
    );
}
