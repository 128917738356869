
import {TAvatarProps, TIconProps, toast, TVariant} from '@aktek/f4kit';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';

import AskForModal from '@/components/Modals/AskForModal';
import ConfirmationModal, {TConfirmationModalProps} from '@/components/Modals/ConfirmationModal';
import type {TEmptyFn} from '@/types/CommonTypes';

export default function(
    title: string,
    message?: string,
    onConfirm?,
    confirmButtonVariant?: TVariant,
    confirmButtonLabel?: string,
    icon?: IconDefinition,
    avatar?: TAvatarProps,
    onCancel?: TEmptyFn,
    id?: string,
    successMessage?: string,
    errorMessage?: string,
    iconProps?: Omit<TIconProps, 'icon'>,
) {
    return new Promise((resolve) => {
        return AskForModal<TConfirmationModalProps>(ConfirmationModal, {
            openOnMount: true,
            title,
            message,
            confirmButtonVariant,
            confirmButtonLabel,
            icon,
            avatar,
            onConfirm: async () => {
                const res = await onConfirm?.(id);
                if (res.isSuccessful()) toast.success(successMessage);
                else toast.error(errorMessage);

                resolve(true);
            },
            onCancel: () => {
                onCancel?.();
                resolve(false);
            },
            iconProps,
        });
    });
}

