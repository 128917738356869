import cn from 'classnames';

export const GetClasses = (className : string) => ({
    container: cn(
        'relative',
        'w-full max-h-full bg-white',
        'flex flex-col justify-between',
        'border border-solid border-neutral-200 rounded-xl',
        'overflow-hidden',
        className,
    ),
    actionsContainer: cn(
        'py-4 px-6 border-b border-solid border-neutral-200',
    ),
    paginationContainer: cn(
        '',
        '',
    ),
    loadingIndicator: cn(
        // 'backdrop-blur-sm',
        'flex justify-center items-center',
        'absolute top-0 left-0 right-0 bottom-0',
        'z-10',
    ),
});
export const GetActionsClasses = ({}) => ({
    overflow: 'flex flex-row gap-3',
    middleSpan: 'ml-auto',
    popover: 'flex-shrink-0',
    overflowContentContainer: `flex flex-col gap-2`,
    overflowButton: 'flex-shrink-0',
});
