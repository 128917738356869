import ImageVideoViewerSwiper from '@/components/Swipers/ImageVideoViewerSwiper';

const MediaAlbumCellView = ({value}) => {
    return <div className="w-64">
        <ImageVideoViewerSwiper
            containerClassName="w-64 h-64"
            mediaType="image"
            sideLength={256}
            files={value}
        />
    </div>;
};

export default MediaAlbumCellView;
