import {Image, Label} from '@aktek/f4kit';
import {useEffect} from 'react';

import AktekLogo from '@/assets/images/aktek_logo.png';

import AnimatedNetwork from '../pages/Login/components/LoginAnimation/AnimatedNetwork';
import {getClasses} from '../styles/Auth.styles';

export default function AuthContainer({children}) {
    useEffect(()=>{
        AnimatedNetwork.animate('animated-network', true, 40,
            {nodeColors:
                 [ls?.theme?.colors?.black || '#0e3059', ls?.theme?.colors?.neutral || '#2e5c94', ls?.theme?.colors?.primary || '#34465c'],
            netLineColor: ls?.theme?.colors?.primary || '#24524f',
            });

        return AnimatedNetwork.cleanup;
    });
    const classes = getClasses();

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <div className={classes.imageContainer}>
                    <Image alt="Company Logo" className={classes.image}
                        src={ls?.companyLogo || AktekLogo} />
                    {children}
                </div>
                <Label
                    text={`© Aktek IO ${new Date().getFullYear()}`}
                    className="fixed bottom-0 left-0 m-5 p-3 " size="sm" color="neutral-600" />
            </div>

            {ls.loginSideImage && <Image className="!w-screen !h-1/2 sm:!h-screen sm:!w-1/2 !object-cover "
                src={ls.loginSideImage} alt="Side image" />}

            {!ls.loginSideImage && <div className={classes.animationContainer}>
                <div className={classes.animation}/>
            </div>}

        </div>
    );
}

