import {Drawer, TabNav, useTab} from '@aktek/f4kit';
import cn from 'classnames';
import {useEffect} from 'react';

import useDocumentTitle from '@/hooks/useDocumentTitle';
import {strings} from '@/localization/i18n';
import {openCloseDrawer} from '@/utils/openCloseDrawer';

import {GetAccessControlTabs} from './constants/accessControl.c';
import Groups from './pages/Groups/Groups';
import RolesAndPermissions from './pages/RolesAndPermissions/RolesAndPermissions';
import TAC from './pages/TAC/TAC';
import Users from './pages/Users/Users';

export default function AccessControl(props) {
    const tab = useTab();
    useEffect(() => {
        props.selectedTab && tab.setState({selectedTab: props.selectedTab});
    }, [tab.config?.tabSelectedAt]);

    const accessControlTabs = GetAccessControlTabs();
    const firstVisibleTabKey = accessControlTabs.find((tab) => tab.isVisible)?.key;
    const selectedTab = tab.state?.selectedTab || firstVisibleTabKey;

    useDocumentTitle(strings('access_control'));

    return (
        <div className="flex h-full">
            <Drawer
                sideWidth={96}
                tooltipDelay={1000}
                defaultOpen={ls.drawer?.accessControl ?? true}
                onOpenChange={(e) => openCloseDrawer('accessControl', !!e)}
                buttonSize="sm"
                className="sm:!w-2/4 md:!w-1/4 !w-11/12 min-w-64"
            >

                <TabNav
                    orientation="vertical"
                    tabs={accessControlTabs}
                    value={selectedTab}
                    size="sm"
                    onChange={(selectedTab) => tab.setState({selectedTab})}
                    buttonClassName={cn(
                        '[&>:first-child]:[&>:first-child]:[&>:first-child]:!w-8',
                        ' text-wrap !h-full !text-left',
                    )}
                    className="p-4 !m-0"
                />
                <div className="p-4 bg-neutral-50 h-full border-l border-neutral-200">

                    {(!selectedTab || selectedTab === 'users') && <Users />}

                    {selectedTab === 'groups' && <Groups />}

                    {selectedTab === 'rolesAndPermissions' && <RolesAndPermissions />}
                    {selectedTab === 'tac' && <TAC />}
                </div>
            </Drawer>
        </div>

    );
}

