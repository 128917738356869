
import {applyLoginLogo, applyLoginSideImage} from '@/helpers/applyLoginAssets.fn';

import {useUserContext} from '../Provider';

export function useSignUserIn() {
    const {setUserAuth}= useUserContext();

    return (userAuthData) => {
        applyLoginSideImage(ls?.theme?.images?.background);

        applyLoginLogo(userAuthData?.currentCompany?.companyLogo);

        ls.signIn(userAuthData);
        setUserAuth(userAuthData);
    };
}

