import {F4Form, F4FormDiv} from '@aktek/f4form';
import {LoadingWrapper} from '@aktek/f4kit';
import isEqual from 'lodash/isEqual';
import {useState} from 'react';

import SettingsFooter from '../../components/SettingsFooter';
import FreezeAccount from './components/FreezeAccountSettings';
import Geographical from './components/GeographicalSettings';
import LoginSettings from './components/LoginSettings';
import PasswordSettings from './components/PasswordSettings';
import SecuritySettings from './components/SecuritySettings';
import TimezoneSettings from './components/TimezoneSettings';
import {DEFAULT_SETTINGS} from './constants/Settings.c';
import useSettings from './hooks/useSettings';

export default function Settings() {
    const {
        isLoading,
        mapWidth,
        mapHeight,
        savedValues,
        handleSave,
        settingsFormRef,
    } = useSettings();

    // FIXME: remove this when Atlas is fixed, synced with outside props
    // const [dataUpdated, setDataUpdate] = useState(Date.now());

    const handleReset = () => {
        setDataUpdate(Date.now()); // FIXME: remove this when Atlas is fixed, synced with outside props
        settingsFormRef.current.setData(DEFAULT_SETTINGS);
    };

    const handleCancel = () => {
        setDataUpdate(Date.now()); // FIXME: remove this when Atlas is fixed, synced with outside props
        settingsFormRef.current.setData(savedValues);
    };

    return (
        <div className="px-4 bg-neutral-50 h-fit min-h-full border-l border-neutral-200">
            <F4Form
                formKey="settings"
                ref={settingsFormRef}
                printData="console"
                initialValue={savedValues}
                className=" h-full "
            >

                {({getValue, setValue, data}) => {
                    return <F4FormDiv>
                        <LoadingWrapper isLoading={isLoading} isFull className="w-full" size="lg">
                            <F4FormDiv className="grid gap-4 grid-cols-9">
                                <FreezeAccount />
                                <Geographical
                                    // key={dataUpdated} // FIXME: remove this when Atlas is fixed, synced with outside props
                                    mapHeight={mapHeight} mapWidth={mapWidth}
                                    geo={getValue('geo')}
                                    setValue={(path, value) => setValue('geo.' + path, value)}
                                />
                                <LoginSettings />
                                <PasswordSettings/>
                                <SecuritySettings />
                                <TimezoneSettings />
                            </F4FormDiv>
                        </LoadingWrapper>
                        <SettingsFooter
                            onSave={handleSave}
                            handleCancel={handleCancel}
                            handleReset={handleReset}
                            isInitialValue={isEqual(savedValues, data)}
                            isDefault={isEqual(DEFAULT_SETTINGS, data)}
                        />
                    </F4FormDiv>;
                }}
            </F4Form>
        </div>
    );
}
