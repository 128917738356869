import {Avatar, Button, Icon, Label, Modal, TAvatarProps, TIconProps, TModalProps, TVariant, useModalRef} from '@aktek/f4kit';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import {useEffect} from 'react';

import {strings} from '@/localization/i18n';

export type TConfirmationModalProps = {
    modalProps?: Partial<TModalProps>;
    className?: string;
    title: string;
    message?: string;
    confirmButtonVariant: TVariant
    confirmButtonLabel: string
    icon?: IconDefinition,
    avatar?: TAvatarProps,
    onConfirm?: () => void;
    onCancel?: () => void;
    openOnMount?: boolean;
    iconProps?: Omit<TIconProps, 'icon'>,
}

export default function ConfirmationModal(props: TConfirmationModalProps) {
    const {className, icon, avatar, openOnMount, title, message, onConfirm, onCancel, modalProps={size: 'xs'}, confirmButtonVariant, confirmButtonLabel, iconProps} = props;

    const modalRef = useModalRef();

    useEffect(()=> {
        if (openOnMount) {
            modalRef.current.open();
        }
    }, []);

    const onConfirmCallback = () => {
        onConfirm?.();
        modalRef.current.close();
    };

    const onCancelCallback = () => {
        onCancel?.();
        modalRef.current.close();
    };

    return <Modal
        ref={modalRef}
        hasXIcon={false}
        headerClassName="p-0"
        className={cn('overflow-hidden !rounded-xl p-4 max-w-[400px]', className)}
        title={null}
        size={modalProps.size}
        // headerClassName={cn('border-b border-b-neutral-100 px-6 pt-4 pb-2 text-xl', modalProps.headerClassName)}
        onClose={onCancel}
        onChange={modalProps.onChange}
    >
        <div className="flex flex-col min-h-36 items-center gap-5 p-2">
            {icon
            && <div className="flex items-center justify-center w-12 h-12 bg-error-100 rounded-3xl">

                <Icon
                    icon={icon}
                    size="2xl"
                    // color="primary-600"
                    // className="bg-primary-25 rounded-full p-3"
                    {...iconProps}
                />
            </div>
            }

            {avatar
            && <Avatar
                icon={avatar.icon}
                iconProps={avatar.iconProps}
                statusIcon={avatar.statusIcon}
            />}
            <div className="flex flex-col items-center justify-center px-2">
                <Label
                    size="lg"
                    text={title}
                    className="select-text cursor-text px-5 block w-full break-words break-all hyphens-auto text-center"
                    fontWeight="semibold"
                />
                <Label
                    size="sm"
                    text={message}
                    color="neutral-900"
                    className="text-center"
                />
            </div>
            <div className="flex mt-4 w-full gap-4">
                <Button
                    label={strings('cancel')}
                    variant="white"
                    className="w-1/2"
                    onClick={onCancelCallback}
                />
                <Button
                    label={confirmButtonLabel || strings('confirm')}
                    className="w-1/2"
                    onClick={onConfirmCallback}
                    variant={confirmButtonVariant}
                />
            </div>
        </div>

    </Modal>;
}
