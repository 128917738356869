import {F4FormDiv} from '@aktek/f4form';
import {GroupsV4Service} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4UserSelector from '@/components/F4Elements/F4UserSelector';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

const CreateGroupModal = (props: F4CreatableModalProps) => {
    const {openOnMount, state, refreshTable, ...rest} = props;
    const [groupId, setGroupId] = useState();
    const [firstUsersState, setFirstUsersState] = useState();
    const {users} = useAdminPermissions();

    const getGroupById = async (id) => {
        setGroupId(id);
        const response = await GroupsV4Service.getGroupById(id);

        return response;
    };

    const fetchSelectedUsers = async () => {
        const usersResponse = await GroupsV4Service.getGroupById(groupId as string);

        const finalResponse = {
            isSuccessful: usersResponse.isSuccessful,
            getData: () => usersResponse.getData()?.users,
        };
        !firstUsersState && setFirstUsersState(finalResponse.getData());

        return finalResponse;
    };

    useEffect(() => {
        if (groupId) {
            fetchSelectedUsers();
        }
    }, [groupId]);

    return (
        <F4CreatableModal
            state={state}
            refreshTable={refreshTable}
            createSuccessMessage={strings('group_created_successfully')}
            editSuccessMessage={strings('group_edited_successfully')}
            openOnMount={openOnMount}
            title={strings('group')}
            formKey="groups"
            getFunction={getGroupById}
            storeFunction={GroupsV4Service.storeGroup}
            steps={[{key: 'usersAndInfo', label: strings('user_and_info')}]}
            {...rest}
        >
            <F4FormDiv colSpan={12} className="flex flex-col gap-4">

                <F4Textbox
                    required
                    name="name"
                    label={strings('group_name')}
                />

                {users?.can_view && <F4UserSelector
                    name="users"
                    state={firstUsersState}
                    fetchSelectedUsers={state?.mode == 'edit' && fetchSelectedUsers}
                    className={'mt-5'}
                    showOptional={false}/>}
            </F4FormDiv>
        </F4CreatableModal>
    );
};

export default CreateGroupModal;
