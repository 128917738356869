import {LinkViewer} from '@aktek/f4kit';
import {Cell, Row} from '@tanstack/react-table';
import _isObject from 'lodash/isObject';

import Env from '@/config/env';
import downloadPlainFile from '@/helpers/handleDownloadFileHTTP';
import {strings} from '@/localization/i18n';

export const CellMediaGeneric = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const value = cell.getValue();

            if (!value) return null;

            const url = _getURLFromValue(value, value.isExternal);

            return <LinkViewer
                url={url}
                mediaType={null}
                label={strings('view_file')}
                downloadFile={async (url, name) => {
                    return await downloadPlainFile(url, name, null);
                }} />;
        },

    };
};

const _getURLFromValue = (value, isExternal) => {
    if (!_isObject(value)) return Env.getFileURL(value);

    if (isExternal) return value.key;

    let url = Env.getFileURL(value.key);

    if (isExternal) url = value.key;

    return url;
};
