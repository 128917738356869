import {strings} from '@/localization/i18n';

export function SearchFunction(array, searchText) {
    const ignore = strings('folders_and_data');

    function searchRecursive(items) {
        return items.reduce((acc, item) => {
            // Ignore the folders and data string that match it during the search
            const shouldIgnore = ignore && ignore.includes(item.label);
            const isMatch = !shouldIgnore && item.label?.toLowerCase().includes(searchText.toLowerCase());
            let newItem = null;

            if (isMatch) {
                newItem = {...item};
            }

            if (item.children) {
                const matchedChildren = searchRecursive(item.children);

                if (isMatch) {
                    newItem.children = item.children;
                } else if (matchedChildren.length > 0) {
                    newItem = {...item, children: matchedChildren};
                }
            }

            // Include the folders and data only if it has children or its part of the hierarchy
            if (shouldIgnore && newItem?.children?.length === 0) {
                newItem = null; // Exclude ignored items without matching children
            }

            if (newItem) {
                acc.push(newItem);
            }

            return acc;
        }, []);
    }

    return searchRecursive(array);
}
