import {TextBox} from '@aktek/f4kit';

const IntegerCellEdit = ({value, onChange}) => {
    return <TextBox isGhost autoFocus allowDecimal={false}
        type="number"
        value={value}
        onChange={onChange}
    />;
};

export default IntegerCellEdit;
