import {toast} from '@aktek/f4kit';
import {FoldersService, RecentlyUsedService, SBOService} from '@aktek/morph-frontend-sdk';
import {TTreeNode} from 'node_modules/@aktek/f4kit/dist/components/TierTwo/TreeView/Types/TreeViewProps';
import {useEffect, useState} from 'react';

import {FOLDERS_AND_DATA} from '@/app/Explorer/constants/constantIds.c';
import {focusOrOpenNewTab} from '@/app/Home/Tabs';
import {FindFolderById} from '@/helpers/FindFolderById.fn';
import {GetFirstKey} from '@/helpers/GetFirstKey.fn';
import {strings} from '@/localization/i18n';

import {getFilesData} from '../app/Explorer/utils/getFilesData.fn';

type TState = {
        recentFolders: Array<unknown>
        allFolders: Array<unknown>
}

export type TFilesAndFoldersState = {
        GetAllFolders: () => void
        folders: Array<unknown>
        refresh: () => void
        deleteFolder: (item: unknown) => Promise<void>
        deleteShortcut: (item: unknown) => Promise<void>
        storeFolder: (value: unknown) => Promise<{ isSuccessful: () => boolean; getData: () => unknown}>
        editFolder: (value: unknown) => Promise<{ isSuccessful: () => boolean; getData: () => unknown}>
        recentlyUsed: Array<unknown>
        isLoading: boolean
        switchToRecentView: () => void
        switchToFoldersView: () => void
        switchToShortcutsView: () => void
        rightPanelView: RightPanelView
        parentFolder: TTreeNode
        setParentFolder: (item) => void
        setLinkToFolder: (id: string) => void
        openDataTable: ({label, type, id}: { label: string, type: string, id: string}) => Promise<void>
        moveDataTableLocation: (value: object) => void
}

enum RightPanelView {
  RECENT = 'recent',
  FOLDERS = 'folders',
  SHORTCUTS = 'shortcuts',
}

const useFilesAndFoldersState: () => TFilesAndFoldersState = () => {
    const [state, setState] = useState<TState>({
        recentFolders: [],
        allFolders: [],
    });
    const [rightPanelView, setRightPanelView] = useState<RightPanelView>(RightPanelView.RECENT);

    const [parentFolder, _setParentFolder] = useState<TTreeNode>();
    const [linkToFolder, _setLinkToFolder] = useState();

    const switchToRecentView = () => setRightPanelView(RightPanelView.RECENT);
    const switchToFoldersView = () => setRightPanelView(RightPanelView.FOLDERS);

    const switchToShortcutsView = () => setRightPanelView(RightPanelView.SHORTCUTS);

    const setParentFolder = (item) => _setParentFolder(item);
    const setLinkToFolder = (id) => _setLinkToFolder(id);

    const updateState= <T>(key, value: T | void) => setState((state) => ({...state, [key]: value}));

    const folders = state.allFolders;
    const recentlyUsed = state.recentFolders;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!linkToFolder) return;

        const foundItem = FindFolderById(folders, linkToFolder);
        _setParentFolder(foundItem);
        switchToFoldersView();
    }, [linkToFolder, folders]);

    // updating the parentFolder in case of a change
    useEffect(() => {
        if (parentFolder?.id == FOLDERS_AND_DATA) {
            _setParentFolder((prev) => ({
                ...prev,
                children: folders as TTreeNode[],
            }));

            return;
        }

        if (parentFolder?.id) {
            const foundItem = FindFolderById(folders, parentFolder?.id);
            _setParentFolder(foundItem);

            return;
        }
    }, [folders]);

    const refresh = () => {
        GetAllFolders();
        GetRecentTabs();
    };

    const GetRecentTabs = async () => {
        setIsLoading(true);
        const response = await RecentlyUsedService.getRecentTabs();
        const data = response.getData();
        const success = response.isSuccessful?.();
        success && updateState<unknown[]>('recentFolders', data?.tabs);
        setIsLoading(false);
    };

    const GetAllFolders = async () => {
        setIsLoading(true);
        const response = await FoldersService.getAllFolders();
        const success = response.isSuccessful?.();
        success && updateState<unknown[]>('allFolders', getFilesData(response?.Body));
        setIsLoading(false);
    };

    const deleteFolder = async (item) => {
        setIsLoading(true);
        const response = await FoldersService.deleteFolder(item.id);
        await refresh();
        const isDeleted = response.isSuccessful?.();

        setIsLoading(false);

        if (!isDeleted) return toast.error?.(strings('create_folder_failed'));

        toast.success?.(strings('create_folder_success'));
    };

    const deleteShortcut = async (item) => {
        setIsLoading(true);
        const response = await FoldersService.deleteShortcut(item.id);
        await refresh();
        const isDeleted = response.isSuccessful?.();

        setIsLoading(false);

        if (!isDeleted) return toast.error?.(strings('shortcut_delete_fail'));

        toast.success?.(strings('shortcut_delete_success'));
    };

    const storeFolder = async (value) => {
        const itemChecked = GetFirstKey(value.parentId);
        const parentFolderId = itemChecked == FOLDERS_AND_DATA ? null : itemChecked;

        const response = await FoldersService.storeFolder(value.folderName, parentFolderId, value.icon, value.color);
        const success = response.isSuccessful?.();

        // if (success) await refresh();

        return {
            isSuccessful: () => success,
            getData: () => response.getData(),
        };
    };

    const editFolder = async (value) => {
        const itemChecked = GetFirstKey(value.parentId);
        const parentFolderId = itemChecked == FOLDERS_AND_DATA ? null : itemChecked;

        const [moveResponse, editResponse] = await Promise.all([
            FoldersService.moveFolder(value.id, parentFolderId),
            FoldersService.editFolder(value.id, value.label, value.image || ''),
        ]);

        // await refresh();
        const success = moveResponse.isSuccessful?.() && editResponse.isSuccessful?.();

        return {
            isSuccessful: () => success,
            getData: () => moveResponse.getData(),
        };
    };

    const openDataTable = async ({label, type, id}) => {
        focusOrOpenNewTab('dataTable', label, {label, sboId: id}, {tabKey: 'dataTable-' + id});
        await RecentlyUsedService.updateRecentTabs(label, label, {open: 'dataTable', type: type, sboId: id});
        await refresh();
    };

    const moveDataTableLocation = async (value) => {
        setIsLoading(true);
        const sboByIdResponse = await SBOService.getCompleteSBOById(value.id);
        const success = sboByIdResponse.isSuccessful();

        if (!success) {
            toast.error(strings('failed_to_move_data_table'));
            setIsLoading(false);

            return {
                isSuccessful: () => success,
                getData: () => sboByIdResponse.getData(),
            };
        }

        const manipulatedData = {
            ...sboByIdResponse.getData() as object,
            folder: value.parentId,
        };

        const storeResponse = await SBOService.storeSBO(manipulatedData);

        if (!storeResponse.isSuccessful()) toast.error(strings('failed_to_move_data_table'));

        if (storeResponse.isSuccessful()) {
            toast.success(strings('data_table_moved_successfully'));
            await refresh?.();
        }

        setIsLoading(false);

        return {
            isSuccessful: () => storeResponse.isSuccessful(),
            getData: () => storeResponse.getData(),
        };
    };

    return {
        GetAllFolders,
        folders,
        refresh,
        deleteFolder,
        deleteShortcut,
        recentlyUsed,
        isLoading,
        rightPanelView,
        switchToRecentView,
        switchToFoldersView,
        switchToShortcutsView,
        parentFolder,
        setParentFolder,
        setLinkToFolder,
        openDataTable,
        storeFolder,
        editFolder,
        moveDataTableLocation,
    };
};

export default useFilesAndFoldersState;
