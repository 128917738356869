import {F4FormDiv, useF4FormRef} from '@aktek/f4form';
import {Icon, Label} from '@aktek/f4kit';
import {faFolder} from '@fortawesome/pro-regular-svg-icons';
import {useState} from 'react';

import F4CreatableModal from '@/components/F4CreatableModal/F4CreatableModal';
import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import F4TreeView from '@/components/F4Elements/F4TreeView';
import AskForModal from '@/components/Modals/AskForModal';
import ImageOrColorSelectorModal, {TImageColorSelectorProps} from '@/components/Modals/ImageOrColorSelectorModal';
import SearchBox from '@/components/SearchBox/SearchBox';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {GetFirstKey} from '@/helpers/GetFirstKey.fn';
import {useDebouncedCallback} from '@/hooks/useDebouncedCallback';
import {strings} from '@/localization/i18n';

import {FOLDERS_AND_DATA} from '../constants/constantIds.c';
import useFilteredItems from '../hooks/useFilteredItems';

const CreateFolderModal = (props: F4CreatableModalProps) => {
    const {openOnMount, state, refresh, parentId, ...rest}= props;
    const {folders, storeFolder} = useGlobalFilesAndFolders();
    const [searchValue, setSearchValue] = useState('');
    const items = useFilteredItems(folders, searchValue, true, 'folder');

    const scrollTimeOut = 2200;
    const debounceTime = 250;

    const hasItems = items && items.length > 0;
    const [info, setInfo] = useState({
        folderName: '',
        parentId: undefined,
    });
    const updateInfoState = (key, value) => setInfo((state) => ({...state, [key]: value}));

    const _onChange = (value: string) => {
        setInfo((prevInfo) => ({
            ...prevInfo,
            folderName: value.trim(),
        }));
    };

    const handleTextBoxChange = useDebouncedCallback(debounceTime, _onChange);

    const ref = useF4FormRef();

    const handleTreeViewChange = (value) => {
        const itemChecked = GetFirstKey(value);
        updateInfoState('parentId', itemChecked);
    };

    const defaultTreeValue = parentId ? {[parentId]: true} : {[FOLDERS_AND_DATA]: true};
    const defaultTreeValueId = GetFirstKey(defaultTreeValue);
    const isFoldersAndDataSelected = defaultTreeValueId != FOLDERS_AND_DATA;

    return (
        <F4CreatableModal
            state={state}
            title={strings('folder')}
            formKey="folder"
            ref={ref}
            className="!w-1/2"
            hasSidebar={false}
            onSave={refresh}
            isDisabled={info.folderName === '' || info.parentId === undefined}
            openOnMount={openOnMount}
            storeFunction={storeFolder}
            createSuccessMessage={strings('folder_created_successfully')}
            errorMessage={strings('folder_created_failed')}
            {...rest}
        >

            <F4FormDiv>

                <F4FormDiv colSpan={12}>
                    <div className="sticky !shadow-none elevation-5 -top-4 bg-white -mt-1">
                        <div className="flex items-center justify-start gap-3 mb-6" >

                            <div className="bg-primary-25 rounded-xl p-4 flex items-center justify-center w-fit -mb-4">
                                <Icon icon={faFolder} color="primary-600"
                                    size="xl" onClick={() => AskForModal<TImageColorSelectorProps>(ImageOrColorSelectorModal, {
                                        colorFormName: 'color',
                                        imageFormName: 'icon',
                                        setValue: ref?.current?.setValue,
                                    })} />
                            </div>

                            <F4Textbox autoFocus required
                                name="folderName"
                                label={strings('name')}
                                placeholder=""
                                colSpan={12}
                                onChange={handleTextBoxChange}
                                type="text"
                                f4FormDivClassName="flex flex-col justify-center items-start"
                            />
                        </div>

                        <SearchBox onSearch={setSearchValue}
                            className="!bg-neutral-100 rounded-lg  mb-4"
                            textBoxInputClassName="!bg-neutral-100  !font-normal !text-neutral-900"
                            size="md"
                            autoFocusOnOpen={false}
                        />

                    </div>
                    {hasItems && <F4TreeView
                        name="parentId"
                        showOptional={false}
                        size="xs"
                        data={items}
                        nodeMoveTargetFilter={(e) => e.type == 'folder'}
                        checkMode="single"
                        scrollTimeOut={scrollTimeOut}
                        scrollToDefaultValue={isFoldersAndDataSelected}
                        checkboxFilter={(node)=> node.type == 'folder'}
                        activeNode={defaultTreeValueId}
                        onChange={handleTreeViewChange}
                        areAllNodesExpanded={isFoldersAndDataSelected}
                        defaultValue={defaultTreeValue}
                        labelProps={{className: 'block w-full break-words break-all hyphens-auto leading-tight cursor-pointer'}}
                    />}

                    {!hasItems && <Label text={strings('no_results_found')} size="sm" className="mt-5 ml-3 overflow-hidden" color="neutral-600"/>}
                </F4FormDiv>

            </F4FormDiv>

        </F4CreatableModal>

    );
};

export default CreateFolderModal;
