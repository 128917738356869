import {isFinite, isNumber, isObject} from 'lodash';

import BooleanParser from './BooleanParser';
import ColorParser from './ColorParser';
import DateParser from './DateParser';
import DateTimeParser from './DateTimeParser';
import FloatParser from './FloatParser';
import GPSTransformer from './GPSParser';
import IntegerParser from './IntegerParser';
import TagsParser from './TagsParser';
import TimeParser from './TimeParser';

const transformPastedData = (field, pastedValue) => {
    if (typeof pastedValue === 'string') {
        pastedValue = pastedValue.trim();
    }

    if (field.type == 'tags') {
        let value;

        if (Array.isArray(pastedValue?.tags)) {
            value = {
                tags: pastedValue.tags,
                joined: pastedValue.tags.join(','),
            };
        }

        if (typeof pastedValue=='number') pastedValue = pastedValue + '';

        if (typeof pastedValue=='string') {
            value = {
                tags: [],
                joined: '',
            };
            // const tags = TagsParser(pastedValue, tagsStore.getOptions(), false);
            // value = {
            //     tags,
            //     joined: tags.join(','),
            // };
        }

        return {
            status: 'final',
            value,
        };
    }

    const type = field.type.toString();

    if (pastedValue == null || pastedValue == undefined || (typeof pastedValue == 'string' && pastedValue?.trim()?.length == 0)) {
        return {
            status: 'final',
            value: null,
        };
    }

    switch (type) {

        case ('string'): {
            return {
                status: 'final',
                value: pastedValue?.toString?.(),
            };
        }

        case ('boolean'): {
            return {
                status: 'final',
                value: BooleanParser(pastedValue),
            };
        }

        case ('integer'): {
            return {
                status: 'final',
                value: IntegerParser(pastedValue, {min: field.minimum, max: field.maximum}),
            };
        }

        case ('float'): {
            return {
                status: 'final',
                value: FloatParser(pastedValue, {min: field.minimum, max: field.maximum}),
            };
        }

        case ('gps'): {
            if (isObject(pastedValue) && ('lat' in pastedValue|| 'lng' in pastedValue)) {
                return {
                    status: 'final',
                    value: pastedValue,
                };
            }

            if (typeof pastedValue == 'string') {
                return {
                    status: 'final',
                    value: GPSTransformer(pastedValue),
                };
            }

            break;
        }

        case ('date'): {
        // Means it's Unix Timestamp
            if (isFinite(Number(pastedValue))) {
                return {
                    status: 'final',
                    value: pastedValue,
                };
            }

            if (typeof pastedValue == 'string') {
                return {
                    status: 'final',
                    value: DateParser(pastedValue),
                };
            }

            break;
        }

        case ('time'): {
        // Means it's Unix Timestamp
            if (isFinite(Number(pastedValue))) {
                return {
                    status: 'final',
                    value: pastedValue,
                };
            }

            if (typeof pastedValue == 'string') {
                return {
                    status: 'final',
                    value: TimeParser(pastedValue),
                };
            }

            break;
        }

        case ('datetime'): {
        // Means it's Unix Timestamp
            if (isFinite(Number(pastedValue))) {
                return {
                    status: 'final',
                    value: pastedValue,
                };
            }

            if (typeof pastedValue == 'string') {
                return {
                    status: 'final',
                    value: DateTimeParser(pastedValue),
                };
            }

            break;
        }

        case ('meta'): {
            return {
                status: 'text',
                value: pastedValue,
            };
        }

        case ('ref'): {
            if (!pastedValue) {
                return {
                    status: 'final',
                    value: null,
                };
            }

            if (Array.isArray(pastedValue) && '_id' in pastedValue[0] && isFinite(pastedValue[0]._id)) {
                return {
                    status: 'final',
                    value: {_id: pastedValue[0]?._id},
                };
            }

            if (isObject(pastedValue) && ('_id' in pastedValue) && isNumber(pastedValue._id)) {
                return {
                    status: 'final',
                    value: {_id: pastedValue._id},
                };
            }

            return {
                status: 'text',
                value: reshape(pastedValue),
            };
        }

        case ('refList'): {
            if (!pastedValue) {
                return {
                    status: 'final',
                    value: null,
                };
            }

            if (Array.isArray(pastedValue) && pastedValue.every((e)=> ('_id' in e) && isFinite(e._id))) {
                return {
                    status: 'final',
                    value: pastedValue.map((e)=> ({_id: e._id})),
                };
            }

            if (typeof pastedValue?._id == 'number') {
                return {
                    status: 'final',
                    value: [{_id: pastedValue?._id}],
                };
            }

            if (typeof pastedValue == 'string') {
                return {
                    status: 'text',
                    // value: reshape(pastedValue)
                    value: reshapeArray(pastedValue),
                };
            }

            break;
        }

        case ('connectedRef'): {
            if (!pastedValue) {
                return {
                    status: 'final',
                    value: null,
                };
            }

            if (isObject(pastedValue) && isNumber(pastedValue?._id)) {
                return {
                    status: 'final',
                    value: pastedValue,
                };
            }

            return {
                status: 'text',
                value: reshape(pastedValue),
            };
        }

        case ('closedList'): {
            if (typeof pastedValue == 'string') {
                return {status: 'final',
                    value: pastedValue,
                };
            }
        }

        case ('color'): {
            if (typeof pastedValue == 'string') {
                return {status: 'final',
                    value: ColorParser(pastedValue)};
            }

            break;
        }

        // case ('formula'): {
        //     break;
        // }

        default: {
            return {
                status: 'final',
                value: null,
            };
        }
    }

    return {
        status: 'final',
        value: null,
    };
};

export default transformPastedData;

const reshape = (value) => {
    if (typeof value == 'string') {
        return value.trim();
    }

    return value;
};

const reshapeArray = (value) => {
    if (typeof value == 'string') {
        return value.split(',').map((val) => val.trim()).join(',');
    }

    return value;
};
