import {Label, TActionsModalController, TreeView} from '@aktek/f4kit';
import {faDatabase} from '@fortawesome/pro-regular-svg-icons';
import {useCallback, useEffect, useRef, useState} from 'react';

import {addRecentlyUsedDS} from '@/app/Admin/Data/pages/DataStructure/helpers/RecentlyUsedDataStructure';
import {focusOrOpenNewTab, openTab} from '@/app/Home/Tabs';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import AskForFolder from '@/components/Modals/AskForFolder';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {CopyToClipboard} from '@/helpers/CopyToClipboard.fn';
import {FindFolderById} from '@/helpers/FindFolderById.fn';
import {GetFolderPath} from '@/helpers/GetFolderPath.fn';
import {strings} from '@/localization/i18n';

import {FOLDERS_AND_DATA, RECENTLY_USED, REPOSITORIES, SHORTCUTS} from '../constants/constantIds.c';
import {GetFolderContextMenu, GetRecentlyUsedContextMenu, GetReportsContextMenu, GetShortcutContextMenu} from '../constants/getExplorerTreeContextMenu.c';
import {OnDeleteShortcut} from '../helpers/DeleteShortcutShortcut.fn';
import {getTreeItems} from '../utils/getTreeItems.fn';
import DeleteModal from './DeleteModal';
import EditMoveFolderModal from './EditMoveFolderModal';
import ExplorerLoadingComponent from './ExplorerLoadingComponent';

export default function ExplorerTree({searchValue}) {
    const deleteActionRef = useRef<TActionsModalController>();
    const {folders: items, isLoading, refresh, switchToFoldersView, deleteShortcut,
        switchToRecentView, switchToShortcutsView, recentlyUsed, setParentFolder, parentFolder, openDataTable, moveDataTableLocation} = useGlobalFilesAndFolders();
    const [rightClickedItem, setRightClickedItem] = useState({});

    const data = getTreeItems(items, searchValue, recentlyUsed);
    const hasData = data && data.length > 0;

    const treeNodeSections = [FOLDERS_AND_DATA, SHORTCUTS, REPOSITORIES, RECENTLY_USED];

    const handleRecentlyUsedClick = (label, id) => {
        focusOrOpenNewTab('dataTable', label, {label, sboId: id}, {tabKey: 'dataTable-' + id});
    };

    // this useEffect to make sure the right side data are set correctly after the search
    useEffect(() => {
        if (!parentFolder || searchValue) return;
        const result = FindFolderById(data, parentFolder?.id);
        setParentFolder(result);
    }, [searchValue, data]);

    const onNodeClickFunction = async (node) => {
        const {type, label, id} = node;

        if (treeNodeSections.includes(id)) {
            ls.openExplorerSection('explorer-' + id);
        }

        if (type == 'container' && (id !== RECENTLY_USED && id !== SHORTCUTS)) {
            switchToFoldersView();

            // avoiding state updates when clicking on the current directory.
            if (parentFolder?.id == node.id) return;
            setParentFolder(node);

            return;
        }

        if (type == 'recent' || id == RECENTLY_USED) {
            switchToRecentView();

            if (type == 'container') {
                // avoiding state updates when clicking on the current directory.
                if (parentFolder?.id == node.id) return;
                setParentFolder(node);

                return;
            }

            handleRecentlyUsedClick(label, id);

            return;
        }

        if (type == 'report') {
            openDataTable({label, id, type});

            return;
        }

        if (id == SHORTCUTS) switchToShortcutsView();

        // avoiding state updates when clicking on the current directory.
        if (parentFolder?.id == node.id && node.type != 'shortcut') return;

        if (node.type == 'shortcut') {
            focusOrOpenNewTab(node.component, undefined, {selectedTab: node?.props?.selectedTab});
        }

        switchToFoldersView();
        setParentFolder(node);

        return;
    };

    const onCaretClickFunction = (node) => {
        const {id} = node;

        ls.toggleExplorerSection('explorer-' + id);
    };

    // Recently used menu functions
    const openRecentlyUsed = useCallback((item) => {
        handleRecentlyUsedClick(item.label, item.id);
    }, []);

    // Files context menu functions
    const openReport = useCallback(async (item) => {
        const {id, type, label} = item;

        openDataTable({label, id, type});
    }, []);

    const moveDataTable = useCallback(async (item) => {
        const folderResults = await AskForFolder({item});
        moveDataTableLocation(folderResults as object);
    }, []);

    const editDataStructure = useCallback(async (item) => {
        openTab('dataStructureEditor', item.label, {sboId: item.id});
        const tab = {
            key: item.id,
            label: item.label,
            icon: faDatabase,
        };
        addRecentlyUsedDS(tab);
    }, []);

    // Folder context menu functions
    const copyLinkToFolder = useCallback((item) => {
        CopyToClipboard(GetFolderPath(item.id));
    }, []);

    const openEditModal = useCallback((item) => {
        AskForCreationModal(EditMoveFolderModal, {state: {mode: 'edit', _id: item.id}, item: item, refresh});
    }, []);

    const openDeleteModal = useCallback(() => {
        deleteActionRef.current?.open();
    }, []);

    const getContextMenu = (item) => {
        if (treeNodeSections.includes(item.id)) return null;
        setRightClickedItem(item);

        if (item.type == 'folder') {
            return GetFolderContextMenu(() => copyLinkToFolder(item), () => openEditModal(item), () => openDeleteModal());
        }

        if (item.type == 'report') {
            return GetReportsContextMenu(()=> openReport(item), () => moveDataTable(item), () => editDataStructure(item));
        }

        if (item.type == 'recent') {
            return GetRecentlyUsedContextMenu(()=> openRecentlyUsed(item));
        }

        if (item.type == 'shortcut') {
            return GetShortcutContextMenu(
                () => OnDeleteShortcut(item, deleteShortcut),
                ()=>focusOrOpenNewTab(item.component, undefined, {selectedTab: item?.props?.selectedTab}),
            );
        }

        return null;
    };

    return <div className="py-2 h-full w-full overflow-y-auto">

        {hasData
        && <TreeView
            size="xs"
            getContextMenu={getContextMenu}
            onNodeClick={onNodeClickFunction}
            onCaretClick={onCaretClickFunction}
            className="[&>:first-child]:pl-5"
            activeNode={parentFolder?.id || RECENTLY_USED}
            nodeMoveTargetFilter={(e) => e.type == 'folder'}
            data={data}
            labelProps={{
                color: 'neutral-900',
                fontWeight: 'normal',
            }}
        />}

        {!hasData && !isLoading
        && <Label text={strings('no_results_found')} size="sm" className="mt-5 ml-4 overflow-hidden" color="neutral-600"/>}
        <DeleteModal ref={deleteActionRef} item={rightClickedItem} />

        <ExplorerLoadingComponent className="h-screen" condition={!data || isLoading} />
    </div>;
}
