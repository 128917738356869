export default (inputString: string) => {
    const latLngRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;

    const match = inputString.match(latLngRegex);

    if (!match) return null;
    const lat = parseFloat(match[1]);
    const lng = parseFloat(match[3]);

    return {lat, lng};
};

