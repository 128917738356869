import {TagService} from '@aktek/morph-frontend-sdk';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import {TF4ViewableComponentPropsReferences} from '@/components/F4ViewableComponent/types/F4ViewableComponentProps.t';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import useTableRef from '@/components/Table/Hooks/useTable';
import {CanCreateTags} from '@/constants/Permissions.c';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import CreateTagModal from './components/CreateTagModal';
import {GetRightClickRowTagsActions, TAG_COLUMNS} from './constants/Tags.columns';
import useTags from './hooks/useTags';

export default function Tags() {
    const {getTableData} = useTags();
    const tableRef = useTableRef();
    const {tags} = useAdminPermissions();

    const refreshTable = () => tableRef.refresh?.();

    const TagsOnRowAction = (references: TF4ViewableComponentPropsReferences) => async (row, action) => {
        if (action.name == 'delete') return references.deleteModalRef.current.open({id: row.original.Tag[0].label, name: row.original.Tag[0].label, message: 'delete_tag'});
        if (action.name == 'edit') return AskForCreationModal(CreateTagModal, {state: {mode: 'edit', _id: row.original.Tag[0].label}, refreshTable});
    };

    return (
        <div className="flex h-full bg-neutral-50 p-4 border-l border-neutral-200">
            <F4ViewableComponent
                canCreate={CanCreateTags()}
                deleteSuccessMessage={strings('delete_tag_success')}
                name={strings('tag')}
                emptyProps={{addLabel: strings('new_tag')}}
                tableColumns={TAG_COLUMNS}
                getAllData={getTableData}
                tableRef={tableRef}
                onRowAction={TagsOnRowAction}
                getRightClickRowActions={GetRightClickRowTagsActions(tags)}
                modal={CreateTagModal}
                deleteItem={TagService.deleteTag}
                noPagination
            />
        </div>
    );
}
