import {F4FormContainer, F4FormDiv, F4FormElementDecorator, F4FormStep, F4Nestor} from '@aktek/f4form';
import {Label} from '@aktek/f4kit';
import {LicensePlanService} from '@aktek/helios-frontend-sdk';
import {useEffect, useState} from 'react';

import {useF4CreatableFormContext} from '@/components/F4CreatableForm/F4CreatableFormContext';
import F4Dropdown from '@/components/F4Elements/F4Dropdown';
import LicenseFeature from '@/components/LicenseComponents/LicenseFeature';
import {LICENSE_FEATURES, LIMITS_FOR_METRICS} from '@/constants/LicenseFeatures.c';
import {strings} from '@/localization/i18n';

import useAgency from '../hooks/useAgency';

export const LicenseInfo = () => {
    const {getLicenses, dropdownOptions} = useAgency();
    const {extra} = useF4CreatableFormContext();
    const companyId = extra.companyId as string;
    const ref = extra.ref;
    const companyInfo = ref?.current?.getData(companyId);
    const mode = extra?.state?.mode;
    const isViewMode = mode == 'view';

    const [hasCustomizations, setHasCustomizations] = useState(false);

    useEffect(() => {
        getLicenses(null, {id: 'plan', order: 1}, 0, 100);
    }, []);

    useEffect(() => {
        if (!!companyInfo?.license?.licensePlan) {
            return setHasCustomizations(true);
        }

        setHasCustomizations(false);
    }, [companyInfo, hasCustomizations]);

    const onLicenseSelect = async (license) => {
        if (!license) {
            setHasCustomizations(false);

            return;
        }

        setHasCustomizations(false);

        // Empty the values before setting the new ones
        if (ref?.current && ref?.current?.getData() !== null) {
            ref?.current?.setValue('license.customizations.limits', {});
            ref?.current?.setValue('license.customizations.features', {});
        }

        const response = await LicensePlanService.getLicensePlan(license);
        const success = response.isSuccessful();

        if (ref?.current && ref?.current?.getData() !== null && success) {
            response?.Body?.limits && ref?.current?.setValue('license.customizations.limits', response?.Body?.limits);
            response?.Body?.features && ref?.current?.setValue('license.customizations.features', response?.Body?.features);
        }

        setHasCustomizations(true);
    };

    return (

        <F4FormStep step="license" autoNest>

            <F4FormDiv className="overflow-y-auto flex flex-col gap-4 pb-4">

                <F4FormContainer rowSpan={12} className="w-full flex flex-col">

                    <F4Dropdown
                        options={dropdownOptions || {}}
                        showOptional={!isViewMode}
                        placeholder={isViewMode ? strings('not_specified') : undefined}
                        isDisabled={isViewMode}
                        hideArrow={isViewMode}
                        isClearable={!isViewMode}
                        show={dropdownOptions}
                        label={strings('license_plan')}
                        onChange={onLicenseSelect}
                        name="licensePlan" />

                    <F4Nestor name="customizations">
                        <F4FormDiv className="flex flex-col justify-between items-center" show={hasCustomizations} >

                            <F4Nestor name="features">

                                <F4FormDiv className="flex flex-col justify-between items-center pr-6 pb-2">

                                    <F4FormDiv className="flex justify-between items-center py-4">
                                        <Label className="w-full" size="xl" text={strings('features')} color="neutral-700" fontWeight="medium" />
                                        {!isViewMode && <Label className="pr-2" size="sm" text={strings('customize')} color="neutral-600" fontWeight="medium" />}
                                    </F4FormDiv>

                                    {LICENSE_FEATURES.map((item, index) => {
                                        return <LicenseFeature key={index} item={item} mode={mode} />;
                                    })}
                                </F4FormDiv>
                            </F4Nestor>

                            <F4FormDiv className="w-full h-px bg-neutral-200 mx-auto my-4" />

                            <F4Nestor name="limits">
                                <F4FormDiv className="flex flex-col justify-between items-center pt-2 pr-6">
                                    <F4FormDiv className="flex justify-between items-center py-4">
                                        <Label size="xl" text={strings('iq_areas_limits')} color="neutral-700" fontWeight="medium" />
                                        {!isViewMode && <Label className="pr-2" size="sm" text={strings('customize')} color="neutral-600" fontWeight="medium" />}
                                    </F4FormDiv>

                                    {LIMITS_FOR_METRICS.map((item, index) => {
                                        return <LicenseFeature key={index} item={item} mode={mode} />;
                                    })}
                                </F4FormDiv>
                            </F4Nestor>
                        </F4FormDiv>
                    </F4Nestor>
                </F4FormContainer>

            </F4FormDiv>
        </F4FormStep>
    );
};

const F4LicenseInfo = F4FormElementDecorator(LicenseInfo);
export default F4LicenseInfo;
