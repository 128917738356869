import dayjs from 'dayjs';

// Function to extract date and time from a single timestamp
export const extractDateAndTime = (timestamp: number) => {
    if (!timestamp) return {date: null, time: null};

    const date = dayjs(timestamp).startOf('day').valueOf(); // Date without time
    const time = timestamp - date; // Milliseconds since the start of the day

    return {date, time};
};

// Function to combine date and time into a single timestamp
export const combineDateAndTime = (date: number, time: number) => {
    if (!date || time === null || time === undefined) return null;

    return date + time;
};
