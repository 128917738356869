
export default {
    Root: '/',
    VerifyURL: '/pages/verify-url',
    ResetPassword: '/pages/reset-pass',
    requestResetPassword: '/pages/request-reset-pass',
    Login: '/login',
    Shortcut: '/shortcut/:shortcut',
    Folder: '/folder/:folder',
};
