
export default (inputDate) => {
    // Define regex pattern for validating date format
    const datePattern = /^\d{2}\/\d{2}\/\d{4}/;

    // fieldct the date part from the input
    const match = inputDate.match(datePattern);

    if (!match) {
        // Invalid format
        return null;
    }

    try {
        // Split and destructure date components
        const [day, month, year] = match[0].split('/');

        // Construct ISO 8601 date string for midnight UTC
        const isoDateString = `${year}-${month}-${day}T00:00:00Z`;

        // Create Date object and return timestamp in milliseconds
        const date = new Date(isoDateString);

        return date.getTime();
    } catch (error) {
        // Handle any unexpected errors
        console.error('Error parsing date:', error);

        return null;
    }
};
