
import {Cell, Row} from '@tanstack/react-table';

import ColorCellView from '@/components/Fields/Color/Color.Cell.View';

export const CellColor = (columnDef) => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const columnDef = cell.column.columnDef;
            const value =row.getValue(cell.column.id);
            if (!value) return null;
            const isArray = Array.isArray(value);

            const isSquare =columnDef.isSquare || (!isArray && value.isSquare);

            return <ColorCellView value={value} isSquare={isSquare}/>;
        },
    };
};
