import {TextBox} from '@aktek/f4kit';

const FloatCellEdit = ({value, onChange}) => {
    return <TextBox isGhost autoFocus
        type="number"
        value={value}
        onChange={onChange}
    />;
};

export default FloatCellEdit;
