import ImageVideoViewerSwiper from '@/components/Swipers/ImageVideoViewerSwiper';
import Env from '@/config/env';

const MediaImageCellView = ({value}) => {
    const files = value?.map?.((item) => {
        return {
            url: Env.getFileURL(item.key),
            name: item.name,
            mediaType: 'video',
        };
    });

    return <div className="w-64">
        <ImageVideoViewerSwiper
            containerClassName="w-64 h-64"
            mediaType="video"
            sideLength={256}
            files={files}
        />
    </div>;
};

export default MediaImageCellView;
