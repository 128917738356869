import {Label} from '@aktek/f4kit';

import F4NSwitch from '@/components/F4Elements/F4NSwitch';
import F4Textbox from '@/components/F4Elements/F4TextBox';
import countriesC from '@/constants/countries.c';
import {strings} from '@/localization/i18n';

import F4Dropdown from '../F4Elements/F4Dropdown';

type LicenseFeatureProps = {
    item: {
      name: string;
      value: boolean | number | string[];
      key: string;
    }
    isLicensePage?:boolean
    mode?: 'edit' | 'view' | 'create'
  };

const LicenseFeature = ({item, isLicensePage, mode} :LicenseFeatureProps) => {
    const isViewMode = mode == 'view';
    const options = [
        {
            label: strings('no'),
            value: false,
        },
        {
            label: strings('yes'),
            value: true,
        },
    ];

    const isBoolean = (typeof item.value) === 'boolean';

    const mappedCountries = countriesC.reduce((acc, country) => {
        acc[country.value] = {label: country.label, value: country.value};

        return acc;
    }, {} as Record<string, { label: string; value: string }>);

    return (
        <div className="flex flex-col justify-center items-center w-full">
            <div className="w-full flex flex-row items-center justify-between gap-4 my-2">
                <Label text={item.name} color="neutral-600" fontWeight="medium" />
                {item.key === 'amp_wlc'
                && <div className="flex flex-col justify-center items-center">
                    <F4NSwitch
                        name="has_amp_wlc"
                        options={options}
                        isDisabled={isViewMode}
                        defaultValue={false}
                        size="sm"
                        nullifyVars={['limits.amp_wlc']}
                        showOptional={false}
                    />
                </div>}
                {item.key !== 'amp_wlc'
                && <div className="h-full flex items-center justify-center [&>:first-child]:[&>:first-child]:[&>:first-child]:hidden">

                    {isBoolean && <F4NSwitch name={item.key} options={options}
                        isDisabled={isViewMode} size="sm" showOptional={false} defaultValue={false} />}
                    {!isBoolean && <F4Textbox className="!w-[7.2rem]" name={item.key} isDisabled={isViewMode}
                        placeholder={item.value !== 0 ? item.value.toString() : ''} type="number" size="sm" showOptional={false} />}

                </div>}
            </div>
            {item.key === 'amp_wlc'
                && <F4Dropdown
                    name={item.key}
                    isMulti
                    placeholder={isViewMode ? strings('not_specified') : strings('select_countries')}
                    isDisabled={isViewMode}
                    hideArrow={isViewMode}
                    isClearable={!isViewMode}
                    // This condition is to make sure it will work on both agency and licensing
                    show={(valueof)=> !!valueof('limits.has_amp_wlc') || !!valueof('license.customizations.limits.has_amp_wlc')}
                    options={mappedCountries}
                    f4FormDivClassName="mb-2"
                    showOptional={false}
                />}
        </div>
    );
};

export default LicenseFeature;
