import {Modal, toast, useModalRef} from '@aktek/f4kit';
import {TAugmentedResponse} from '@aktek/helios-frontend-sdk/out/service';
import _get from 'lodash/get';

import {TResponse} from '@/components/F4ViewableComponent/types/F4ViewableComponentProps.t';
import Table from '@/components/Table/Table';
import {TColumn} from '@/components/Table/Types/Table.t';
import {strings} from '@/localization/i18n';

export type THistoryModalProps = {
    id: string,
    getDataFunction?:(userId: string, search: string, sort: object, skip: number, limit: number)=>Promise<TAugmentedResponse<unknown>>,
    columns:TColumn[],
}

export default function HistoryModal(props: THistoryModalProps) {
    const {id, getDataFunction, columns} = props;
    const modalRef = useModalRef();

    const DEFAULT_SORTING_STATE = [{id: 'unixDate', desc: true}];
    const DEFAULT_PAGE_SIZE = 20;

    return (
        <Modal
            defaultIsOpened
            ref={modalRef}
            size="xl"
            className=" !w-2/3 !rounded-3xl !h-4/5"
            headerClassName="!py-0 !px-2 rounded-xl hidden">
            <Table
                emptyProps={{
                    className: 'mb-[20vh]',
                }}
                className=" !rounded-3xl h-full"
                baseTableClasses={{
                    tbodyCellTD: ' py-6 px-6  text-neutral-600  text-sm text-left',
                }}
                name={strings('action')}
                defaults={{pageSize: DEFAULT_PAGE_SIZE, sortingState: DEFAULT_SORTING_STATE}}
                getColumns={async ()=> columns}
                getRows={
                    async (paginationState, sortingState, countState, searchState) => {
                        const {pageSize, currentPage} = paginationState;
                        const search = searchState.query.trim();
                        const sort = {id: sortingState[0]?.id, order: sortingState[0]?.desc ? '-1' : '1'};
                        const offset = pageSize * (currentPage -1);

                        const response = await getDataFunction(id, search, sort, offset, pageSize);

                        const data = response.getData() as TResponse;

                        if (!data) { // @TODO: double check this
                            toast.error(strings('error_fetching_data'));

                            return [];
                        }

                        const items = (Array.isArray(data) ? data : data?.data) || [];
                        const count = _get(data, ['metadata', 0, 'count']);

                        if (count !== undefined) countState.setCount(count);

                        return items;
                    }}
                ribbonActions={
                    {
                        title: strings('user_history'),
                        rightActions: {
                            // search: {
                            //     searchProps: {
                            //         onDebouncedChange(state, value) {
                            //             state.searchState.setQuery(value);
                            //         },
                            //     },
                            // },
                            close: {
                                isGhost: true,
                                variant: 'primary',
                                className: '-mr-6',
                                onRibbonActionClick: () => modalRef.current?.close(),
                            },
                        },
                    }
                }
            />
        </Modal>
    );
}
