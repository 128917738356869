import {UserService} from '@aktek/helios-frontend-sdk';
import * as xlsx from 'xlsx';

import {ConvertUnixToDate, ConvertUnixToDateAndTime} from '@/helpers/globalDates.fn';
import {getPhoneFromString} from '@/helpers/phoneFromString.fn';

export const handleDownload = async () => {
    try {
        const users = await UserService.getUsers(null, {id: 'name', order: '1'}, 0, 1000);

        if (users.isSuccessful()) {
            const data = users.getData().data;

            const filteredData = data.map((user) => {
                const formattedUser = {
                    'Name': user.name || '',
                    'Email Address': user.email || '',
                    'User Type': user.userType || '',
                    'Language Setting': user.language || '',
                    'Last Login Date': user.unixLastLoginDate
                        ? ConvertUnixToDateAndTime(user.unixLastLoginDate)
                        : '',
                    'Date Joined': user.creationDate
                        ? ConvertUnixToDate(user.creationDate)
                        : '',
                    'Phone Number': user.phone
                        ? `00 ${user.phone.countryCode} ${user.phone.number}`
                        : ''};

                return formattedUser;
            });

            const worksheet = xlsx.utils.json_to_sheet(filteredData);
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'Users');

            const filename = `Users_${new Date().toLocaleDateString()}.xlsx`;
            xlsx.writeFile(workbook, filename);
        }
    } catch (error) {
        console.error('Error while downloading users data:', error);
    }
};

const USER_TYPE_MAPPING = {
    'Regular User': 'REGULAR',
    'Agency Manager': 'AGENCYMANAGER',
    'Account Manager': 'ACCOUNTMANAGER',
};

const LANGUAGE_MAPPING = {
    'English': 'en',
    'French': 'fr',
    'Kiwashili': 'sw',
    'Somali': 'so',
    'Spanish': 'es',
    'Russian': 'ru',
    'Ukrainian': 'ua',
    'Portuguese': 'pt',
    'Arabic': 'ar',
};

export const bulkStoreUsersFromFile = (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, {type: 'array'});
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert the sheet to JSON
        const jsonData = xlsx.utils.sheet_to_json(worksheet, {defval: '', header: 1});

        // Get the header row
        const headers = jsonData[0];

        // Map the data rows
        const mappedData = jsonData.slice(1).map((row) => {
            const user = {};
            headers.forEach((header, index) => {
                user[header] = row[index];
            });

            return user;
        });

        const finalData = mappedData
            .filter((u) => u['First Name (Str)'] && u['Last name (Str)'] && u['Email Address (Str)'])
            .map((u) => ({
                name: (u['First Name (Str)'] + ' ' + u['Last name (Str)']).trim(),
                email: u['Email Address (Str)'],
                userType: USER_TYPE_MAPPING[u['User Type(Closed List)']] || 'REGULAR',
                language: LANGUAGE_MAPPING[u['Platform Language(Closed List)']] || 'en',
                phone: getPhoneFromString(u['Phone Number (Str)']),
            }));

        await UserService.storeBulkUsers(finalData);
    };

    reader.readAsArrayBuffer(file);
};
