
import {FOLDERS_AND_DATA} from '@/app/Explorer/constants/constantIds.c';
import {F4CreatableModalProps} from '@/components/F4CreatableModal/types/F4CreatableModalProps.t';
import {GetFirstKey} from '@/helpers/GetFirstKey.fn';
import InjectAsyncElement from '@/utils/InjectAsyncElement';

import MoveToLocationModal from '../MoveToLocationModal/MoveToLocationModal';

export default function AskForFolder(
    props?: Partial<F4CreatableModalProps | unknown>,
): Promise<unknown> {
    return InjectAsyncElement((resolve, reject, remove) => {
        const getParentId = (id) =>{
            if (GetFirstKey(id) == FOLDERS_AND_DATA) return null;

            return GetFirstKey(id);
        };

        return <MoveToLocationModal
            {...props}
            openOnMount={true}
            onClose={remove}
            getResult={(result) => {
                const finalResults = {
                    ...result,
                    parentId: getParentId(result?.parentId),
                };
                resolve(finalResults);
                remove();
            }}
        />;
    } );
}
