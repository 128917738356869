import React from 'react';

import Boolean from './Boolean';

const BooleanCellView = ({value}) => {
    return <Boolean
        value={value}
    />;
};

export default BooleanCellView;
