import {SpreadsheetService} from '@aktek/morph-frontend-sdk';
import isEmpty from 'lodash/isEmpty';
type EditObject = {

}

function transformUndefinedToRemove(originalObj: EditObject): EditObject {
    const result = {};
    Object.entries(originalObj)
        .forEach(([rowId, rowModifications]) => {
            if (isNaN(rowId)) return;
            const value = getConvertedRowModifications(rowModifications);

            if (rowId != value._id) return;

            result[rowId] = {
                action: 'edit',
                value,
            };
        });

    return result;
}

const getConvertedRowModifications = (rowModifications) => {
    const result = {};
    Object.entries(rowModifications).forEach(([fieldId, value]) => {
        const isDeleted =rowModifications[fieldId] === undefined;
        result[fieldId] = isDeleted ?'$$REMOVE' : value;
        delete result.rowId;
    });

    return result;
};

export default async (sboId, modifications) => {
    const modifiedData = transformUndefinedToRemove(modifications);

    if (!isEmpty(modifiedData)) {
        const modifiedResponse = await SpreadsheetService.storeSpreadsheetData(sboId, modifiedData);
        const isSuccessful = modifiedResponse.isSuccessful();

        return isSuccessful;
    }

    return true;
};
