import {Icon, TtabsManager} from '@aktek/f4kit';
import {faBuilding, faClone, faGears, faTurnRight} from '@fortawesome/pro-regular-svg-icons';
import {
    faBarChart,
    faDashboard, faDatabase, faDiagramProject, faDisplayChartUp, faGear,
    faHome, faLock, faNetworkWired, faSatellite, faTable,
    faUser} from '@fortawesome/pro-regular-svg-icons';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';

import Explorer from '@/app//Explorer/Explorer';
import AKTEKiQ from '@/app//iQ/iQ';
import Users from '@/app/Admin/AccessControl/pages/Users/Users';
import Admin from '@/app/Admin/Admin';
import Agency from '@/app/Admin/Agency/Agency';
import CompanySettings from '@/app/Admin/CompanySettings/CompanySettings';
import Data from '@/app/Admin/Data/Data';
import UsageMetrics from '@/app/Admin/UsageMetrics/UsageMetrics';
import Analysis from '@/app/Analysis/Analysis';
import Dashboards from '@/app/Analysis/pages/Dashboards';
import Networks from '@/app/Analysis/pages/Networks';
import DataTableView from '@/app/DataTable/DataTable.View';
import AskForModal from '@/components/Modals/AskForModal';
import Env from '@/config/env';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {CopyToClipboard} from '@/helpers/CopyToClipboard.fn';
import {strings} from '@/localization/i18n';

import AccessControl from '../Admin/AccessControl/AccessControl';
import Advanced from '../Admin/Advanced/Advanced';
import DataStructureEditor from '../Admin/Data/pages/DataStructure/components/DataStructureEditor';
import GeoRepository from '../Admin/GeoRepository/GeoRepository';
import iOJobs from '../Admin/iOJobs/iOJobs';
import Licensing from '../Admin/Licensing/Licensing';
import CreateShortcutModal, {TShortcutModalProps} from './components/CreateShortcutModal';

export const TabsManager: TtabsManager = {};

export enum ETabs {analysis, home, admin, dashboards, networks, AKTEKiQ, dataStructure, dataStructureEditor,
     users, companySettings, accessControl, usageMetrics, agency, licensing, iOJobs, advanced, geoRepository, dataTable}

export const Tabs: Record<keyof typeof ETabs, {component: unknown, title: string, icon: string | IconDefinition}> = {
    home: {
        component: Explorer,
        title: strings('home'),
        icon: faHome,
    },
    admin: {
        component: Admin,
        title: strings('admin'),
        icon: faUser,
    },
    accessControl: {
        component: AccessControl,
        title: strings('access_control'),
        icon: faLock,
    },
    dataStructure: {
        component: Data,
        title: strings('data'),
        icon: faDatabase,
    },
    analysis: {
        component: Analysis,
        title: strings('analysis'),
        icon: faDisplayChartUp,
    },
    AKTEKiQ: {
        component: AKTEKiQ,
        title: strings('aktek_iq'),
        icon: faSatellite,
    },
    users: {
        component: Users,
        title: strings('users'),
        icon: faUser,
    },
    companySettings: {
        component: CompanySettings,
        title: strings('company_settings'),
        icon: faGear,
    },
    usageMetrics: {
        component: UsageMetrics,
        title: strings('usage_metric'),
        icon: faBarChart,
    },
    agency: {
        component: Agency,
        title: strings('agency'),
        icon: faBuilding,
    },
    licensing: {
        component: Licensing,
        title: strings('licensing'),
        icon: faGears,
    },
    dashboards: {
        component: Dashboards,
        title: strings('dashboards'),
        icon: faDashboard,
    },
    networks: {
        component: Networks,
        title: strings('networks'),
        icon: faNetworkWired,
    },
    iOJobs: {
        component: iOJobs,
        title: strings('io_jobs'),
        icon: faNetworkWired,
    },
    advanced: {
        component: Advanced,
        title: strings('advanced_sections'),
        icon: faGears,
    },
    geoRepository: {
        component: GeoRepository,
        title: strings('geo_repository'),
        icon: faDiagramProject,
    },
    dataStructureEditor: {
        component: DataStructureEditor,
        title: strings('data_structure_editor'),
        icon: faDatabase,
    },
    dataTable: {
        component: DataTableView,
        title: strings('data_table'),
        icon: faTable,
    },
};

type TopenTab = (tab: keyof typeof ETabs, title?: string, props?: object, options?: object) => void

export const openTab : TopenTab = (type, title = type, props, options) => TabsManager.openNewTab?.(type, title, props, options);
export const focusOrOpenNewTab : TopenTab = (type, title = type, props, options) => TabsManager.focusOrOpenNewTab?.(type, title, props, options);
export const openTabFromShortcut = (shortcut) => TabsManager.openTabFromShortcut?.(shortcut);
export const focuseOrOpenTabFromShortcut = (shortcut) => TabsManager.focusOrOpenTabFromShortcut?.(shortcut);

const getComponent= ({component, config = {}}) => {
    const {props = {}} = config;
    const Component = Tabs[component]?.component;
    if (!Component) return null;

    // return <Component {...props} />;
    return {
        Component,
        props,
    };
};

const getDropDownOptions = (node) => {
    const component = node.component;
    const title = Tabs[node.name]?.title;
    const icon = Tabs[node.name]?.icon;
    const state = node.state;
    const shortcut = node.getShortcut();
    const {refresh} = useGlobalFilesAndFolders();

    return [
        {
            label: strings('create_a_new_shortcut'),
            option: 'Icon',
            icon: {icon: faTurnRight},
            onClick: () => {
                AskForModal<TShortcutModalProps>(CreateShortcutModal, {
                    component,
                    title,
                    tabState: state,
                    refresh,
                    icon,
                });
            },
        },
        {
            label: strings('copy_direct_link'),
            option: 'Icon',
            icon: {icon: faClone},
            onClick: () => {
                if (!shortcut) return;
                const envURL = Env.baseURL;
                const url = `${envURL}/#/shortcut/${shortcut}`;
                CopyToClipboard(url);
            },
        },
    ];
};

const getTitle= ({name, title}) => {
    return title || Tabs?.[name]?.title || name || '';
};

const getIcon= ({name}) => <Icon icon={Tabs?.[name]?.icon || faDatabase} />;

const avatar ={
    alt: 'User Avatar',
    src: 'https://www.aktek.io/hubfs/Brand%20Assets/logo%20AKTEK%20black.png',
};

export const TabFactory = {getComponent, getTitle, getIcon, getDropDownOptions};

