const generateGetDeniedColumnProperty = (lockedFieldsSet, lockedSectionsSet, editableFieldsIDsSet, fieldsMap, field) => {
    const getDenied = (row, column, isNewRow) => {
        const locked = lockedFieldsSet.has(field.id) || lockedSectionsSet.has(field.parentSection);

        if (isNewRow) {
            return {isDenied: locked || !fieldsMap?.[field.id]};
        }

        return {isDenied: locked ||!editableFieldsIDsSet.has(field.id)};
    };

    return getDenied;
};

export default generateGetDeniedColumnProperty;
