// TargetColumn.js
import {Button, Icon, Label, Tag} from '@aktek/f4kit';
import {faPlus, faTimes} from '@fortawesome/pro-regular-svg-icons';

import {strings} from '@/localization/i18n';

const TagTargetColumn = ({
    target,
    id,
    handleTargetClick,
    handleUserTagRemove,
    handleGroupTagRemove,
    clearColumn,
    users,
    groups,
}) => {
    return (
        <div className="flex items-center w-6/12 p-3 pl-8 group min-h-[3.75rem]">
            {!target && (
                <div className="w-10/12 flex flex-wrap">
                    <Button size="sm" isGhost label={strings('everyone')} onClick={() => handleTargetClick(id, strings('everyone'))} />
                    <Button size="sm" isGhost label={strings('everyone_else')} onClick={() => handleTargetClick(id, strings('everyone_else'))} />
                    <Button size="sm" isGhost label={strings('select_audience')} onClick={() => handleTargetClick(id, strings('select_audience'))} />
                </div>
            )}

            {target && typeof target === 'string' && <Label text={target} size="sm" color="neutral-600" className="w-10/12"/>}

            {target && typeof target !== 'string' && (
                <div className="w-10/12 flex flex-wrap gap-2">
                    {target.users?.map?.((audience, i) => (
                        <Tag
                            key={i}
                            size="sm"
                            label={users && users?.[audience]}
                            backgroundColor="neutral-300"
                            className=" !rounded-xl"
                            rightElement={()=><Icon icon={faTimes} size="xs" onClick={() => handleUserTagRemove(id, audience)}/>}
                        />
                    ))}
                    {target.groups?.map?.((audience, i) => (
                        <Tag
                            key={i}
                            size="sm"
                            label={groups && groups?.[audience]}
                            backgroundColor="warning-300"
                            className=" !rounded-xl"
                            rightElement={()=><Icon icon={faTimes} size="xs" onClick={() => handleGroupTagRemove(id, audience)}/>}
                        />
                    ))}
                </div>
            )}

            {target && (
                <div className="w-2/12 flex gap-1 opacity-0 group-hover:opacity-100 transition-all duration-200 ease-in-out">
                    {typeof target !== 'string' && <Button isGhost
                        icon={faPlus}
                        size="xs"
                        variant="neutral"
                        className="!h-3 !w-3"
                        onClick={() => handleTargetClick(id, strings('select_audience'))}
                    />}
                    {typeof target === 'string' && <Button className="!h-3 !w-3 invisible"/>}
                    <Button isGhost
                        icon={faTimes}
                        size="xs"
                        variant="neutral"
                        className="!h-3 !w-3"
                        onClick={() => clearColumn(id, false)}
                    />
                </div>
            )}
        </div>
    );
};

export default TagTargetColumn;
