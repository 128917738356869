import {SpreadsheetService} from '@aktek/morph-frontend-sdk';

// import ParallelServiceRequestsManager from '@@Logical/TagSelector/ParallelServiceRequestsManager';
import ref from './ref';
import refList from './refList';

const connectedRef = async ({value, _id, field, parentValue, affecterValue, affecterColumn}) => {
    if (Number.isInteger(value?._id)) {
        return {isValid: true, finalValue: value};
    }

    if (typeof value != 'string') {
        return {isValid: false};
    }

    let _finalParentValue;

    if (affecterColumn) {
        let _finalParentResponse;

        if (affecterColumn.type === 'ref') {
            _finalParentResponse = await ref({
                field: {sboId: affecterColumn.sboId, fieldId: affecterColumn.fieldId, canCreateNew: false},
                value: affecterValue,
                readMode: true,
                _id: null,
            });
            _finalParentValue = _finalParentResponse?.finalValue;
        }

        if (affecterColumn.type === 'refList') {
            _finalParentResponse = await refList({
                field: {sboId: affecterColumn.sboId, fieldId: affecterColumn.fieldId, canCreateNew: false},
                value: affecterValue,
                readMode: true,
                _id: null,
            });
            _finalParentValue = _finalParentResponse?.finalValue?.[0];
        }
    }

    const request = {
        parentSBOId: affecterColumn.sboId,
        sboId: field.sboId,
        fieldId: field.fieldId,
        values: {},
        canCreate: field.canCreateNew,
    };
    request.values[1] = {parentValue: _finalParentValue?._id, value};
    const key = 'connectedRef-' + field.sboId + '_' + field.fieldId + '-' + field.canCreateNew + '-' + value;

    // const res = await ParallelServiceRequestsManager.dedup(SpreadsheetService.transformConnectedRef, request, key);
    const res = await SpreadsheetService.transformConnectedRef(request);

    if (!res.isSuccessful()) return {isValid: false};

    const data = res.getData();

    const _value = data[1];

    if (_value) {
        return {isValid: true, finalValue: _value};
    }

    return {isValid: true, finalValue: null};
};

export default connectedRef;

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
