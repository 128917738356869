import Env from '@/config/env';
import {strings} from '@/localization/i18n';

async function getImageAsBase64(url) {
    // Fetch the image as a blob
    const response = await fetch(url);
    const blob = await response.blob();

    // Create a FileReader to convert blob to Base64
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result); // Base64 string
        reader.onerror = reject;
        reader.readAsDataURL(blob); // Read blob as Base64
    });
}

export const applyLoginLogo = async (logo) => {
    if (!logo) {
        ls.companyLogo = null;

        return;
    }

    try {
        const base64Image = await getImageAsBase64(Env.getFileURL(logo));
        ls.companyLogo = base64Image;
    } catch (error) {
        console.error(strings('base64_error'), error);
    }
};

export const applyLoginSideImage = async (logo) => {
    if (!logo) {
        ls.loginSideImage = null;

        return;
    }

    try {
        const base64Image = await getImageAsBase64(Env.getFileURL(logo));
        ls.loginSideImage = base64Image;
    } catch (error) {
        console.error(strings('base64_error'), error);
    }
};

