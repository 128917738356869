export const isValidNumber = (value: any, min: string | number | undefined, max: string | number | undefined): boolean => {
    // Convert min and max to numbers if they are valid, otherwise set them to undefined
    const minimum = typeof min === 'number' ? min : parseFloat(min as string);
    const maximum = typeof max === 'number' ? max : parseFloat(max as string);

    // Ensure value is a number
    const numValue = parseFloat(value);

    if (isNaN(numValue)) return false;
    if (minimum && (numValue < minimum)) return false;
    if (maximum && (numValue > maximum)) return false;

    return true;
};
