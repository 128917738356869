import {FieldTypes} from '@aktek/types';
import {pickBy} from 'lodash';

const RequiredColumns = [
    // FieldTypes.String,
    // FieldTypes.Integer,
    // FieldTypes.Float,
    // FieldTypes.Ref,
    // FieldTypes.RefAnnex,
    // FieldTypes.RefList,
    // FieldTypes.RefListAnnex,
    // FieldTypes.ConnectedRef,
    // FieldTypes.Color,
    // FieldTypes.ClosedList,
    // FieldTypes.Signature,
    // FieldTypes.Boolean,
    // FieldTypes.Gps,
    // FieldTypes.MediaAudio,
    FieldTypes.MediaImage,
    FieldTypes.MediaVideo,
    FieldTypes.MediaAlbum,
    // FieldTypes.MediaGeneric,
    // FieldTypes.Date,
    // FieldTypes.Time,
    // FieldTypes.Datetime,
    // FieldTypes.Multisubform,
    // FieldTypes.Subform,
    // FieldTypes.Meta,
    // FieldTypes.Automatic,
    // FieldTypes.Stopwatch,
    // FieldTypes.Formula,
];

export default (fieldsMap) => pickBy(fieldsMap, (field) => !!RequiredColumns.includes(field?.type));
