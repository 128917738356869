import {Card, ContextMenu, Icon, Label} from '@aktek/f4kit';
import {faCircleNodes, faDatabase, faLink, faRefresh} from '@fortawesome/pro-regular-svg-icons';

import {focusOrOpenNewTab} from '@/app/Home/Tabs';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';

import {getRecentlyClasses} from '../styles/Recently.styles';
import EmptyComponent from './EmptyComponent';
import ExplorerLoadingComponent from './ExplorerLoadingComponent';

export default function RecentlyUsed() {
    const {isLoading, recentlyUsed, refresh} = useGlobalFilesAndFolders();

    const handleReportDoubleClick = (item) => {
        const id = item._id;
        const label = item.component;
        focusOrOpenNewTab('dataTable', label, {label, sboId: id}, {tabKey: 'dataTable-' + id});
    };

    const classes = getRecentlyClasses();

    if (!recentlyUsed?.length) {
        return <EmptyComponent label={strings('no_recent_files')} description={strings('no_recent_files_desc')} />;
    }

    const IconByType = {
        report: faDatabase,
        shortcut: faLink,
        geo: faCircleNodes,
    };

    const getIcon = (type) => IconByType[type || faLink];

    return (

        <div className={classes.container}>
            <div className={classes.header}>
                <Label text={strings('recently_used')} size="lg"
                    fontWeight="semibold" color="neutral-900"/>
                <Icon icon={faRefresh} color="neutral-600" className="cursor-pointer p-2.5" size="md" onClick={refresh} />
            </div>

            <div className={classes.mapWrapper}>
                {recentlyUsed?.map((item, index) => {
                    return <ContextMenu key={item._id + index}
                        width="full"
                        items={[
                            {label: strings('open'), onClick: () => handleReportDoubleClick(item)},
                            // {label: strings('remove')},
                        ]}
                    >
                        <Card className={classes.card}
                            borderColor="neutral-200"
                            backgroundColor="white"
                            onDoubleClick={() => handleReportDoubleClick(item)}>
                            <Icon
                                icon={getIcon(item?.props?.type)} className="mr-2" color="neutral-400" />

                            <Label text={item.name} size="xs"
                                className={classes.label} color="neutral-900" />
                        </Card>
                    </ContextMenu>;
                })}
            </div>
            <ExplorerLoadingComponent condition={isLoading} />

        </div>
    );
}

