import {SBOService} from '@aktek/morph-frontend-sdk';

export default async (sboId: string) => {
    const [viewingResponse, creationResponse] = await Promise.all([
        SBOService.getViewingSBOById(sboId),
        SBOService.getCreationSBOById(sboId),
    ]);

    if (!viewingResponse.isSuccessful() || !creationResponse.isSuccessful()) {
        return null;
    }

    const viewingData = viewingResponse.getData();
    const creationData = creationResponse.getData();

    const viewingSBO = viewingData.sbo;
    const creationSBO = creationData;

    return {
        lockedSectionsAndFields: viewingSBO.lockedSectionsAndFields,
        editableFieldsIDs: viewingData.editableFieldsIDs,
        fieldsMap: viewingSBO.fieldsMap,
        sections: viewingSBO.sections,
        sectionsMap: viewingSBO.sectionsMap,
        viewingHasRecordTags: viewingSBO.hasRecordTags,
        creationHasRecordTags: creationSBO.hasRecordTags,
    };
};
