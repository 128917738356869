
export const CloudFrontKeys = {
    CloudFrontPolicyKey: 'CloudFront-Key-Pair-Id',
    CloudFrontKeyPairIdKey: 'CloudFront-Policy',
    CloudFrontSignatureKey: 'CloudFront-Signature',
};

export enum LocalStorageKeys {
    THEME = 'Theme',
    FONT = 'Font',
    TOKEN = 'Token',
    TAB_SCHEMA = 'tabsSchema',
    TABS_PROPS = 'tabsProps',
    COMPANY_LOGO = 'companyLogo',
    LOGIN_SIDE_IMAGE = 'companySideImage',
    GROUP_BACKGROUND = 'groupBackground',
    LANGUAGE = 'language',
    CURRENT_USER = 'currentUser',
    PRIMARY_COMPANY = 'primaryCompany',
    CURRENT_COMPANY = 'currentCompany',
    COMPANY_SETTINGS = 'companySettings',
    USER_COMPANIES = 'userCompanies',
    USER_PERMISSION = 'userPermission',
    PERMISSION_SECTION = 'permissionSection',
    DRAWER = 'drawer',
    RECENTLY_USED_DS = 'recentlyUsedDS',
    EXPLORER_SECTIONS_UI = 'explorerSectionsUI'
}
