import {uuid} from 'short-uuid';

// TODO: remove this
const DEV_MODE_API = 'dev.aktek.io';
const BASE_URL = localStorage.devMode === 'true' ? DEV_MODE_API : process.env.BASE_URL;

const SCHEMA = 'https://';

const getFileURL = (fileName) => {
    return getURL(process.env.UPLOAD_FILE_HOST || 'sandbox.aktek.io/media') + '/' + fileName;
};

const getNewEmptyFileURL = () => {
    const fileName = uuid();

    const url = getFileURL(fileName);

    return {url, fileName};
};

const getURL = (url) => SCHEMA + url;

const baseURL = getURL(BASE_URL || '');
const GoogleMapLatLngAPI = getURL(process.env.GOOGLE_MAP_LAT_LNG_API);
const local = getURL(process.env.LOCAL);

const Env = {
    getURL,
    baseURL,
    getFileURL,
    getNewEmptyFileURL,
    GoogleMapLatLngAPI,
    local,
};

export default Env;
