import {Color} from '@aktek/f4kit';
import _isString from 'lodash/isString';
import React from 'react';

const ColorCellView = ({value, isSquare}) => {
    if (!value) return null;
    const isArray = Array.isArray(value);
    const isString = _isString(value);

    return <div className="flex items-center gap-1">
        {isArray && value.map?.(({isSquare, color}, index) => <Color
            className="shadow-sm"
            key={color + index}
            value={color}
            isSquare={isSquare}
        />)}
        {!isArray && <Color
            className="shadow-sm"
            value={isString ? value : value?.color}
            isSquare={isSquare}
        />}
    </div>;
};

export default ColorCellView;
