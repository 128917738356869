import {LicensePlanService} from '@aktek/helios-frontend-sdk';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import {TF4ViewableComponentPropsReferences} from '@/components/F4ViewableComponent/types/F4ViewableComponentProps.t';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import useTableRef from '@/components/Table/Hooks/useTable';
import {strings} from '@/localization/i18n';

import CreateLicenseModal from './components/CreateLicenseModal';
import {GetRightClickRowLicensingActions, LICENSING_COLUMNS} from './Licensing.columns';

export
const Licensing = () => {
    const tableRef = useTableRef();
    const refreshTable = ()=> tableRef.refresh?.();

    //    useEffect(() => {
    //     if (!openOnMount) return;

    //     AskForCreationModal(CreateLicenseModal, {refreshTable});
    // }, []);

    const LicensingOnRowAction = (references: TF4ViewableComponentPropsReferences) => (row, action, event, state) => {
        if (action.name == 'edit') {
            AskForCreationModal(CreateLicenseModal, {state: {mode: 'edit', _id: row.original._id}, refreshTable});
        }
    };

    return (
        <div className="bg-neutral-50 h-full p-4">
            <F4ViewableComponent
                name="license"
                tableRef={tableRef}
                modal={CreateLicenseModal}
                tableColumns={LICENSING_COLUMNS}
                onRowAction={LicensingOnRowAction}
                getRightClickRowActions={GetRightClickRowLicensingActions()}
                defaultSortedColumn="plan"
                emptyProps={{addLabel: strings('new_license')}}
                deleteSuccessMessage={strings('delete_license_successfully')}
                getAllData={LicensePlanService.getLicensePlans}
            />
        </div>
    );
};

export default Licensing;
