import getConnectedFieldDependencies from './getConnectedRefDependencies';
import mapFields from './MapFields';

export default (sections, sectionsMap, supportedFields, fieldsMap) => {
    const clearAffectsByField = {};

    mapFields(sections, sectionsMap, supportedFields, (field) => {
        if (field?.type !== 'connectedRef') return;
        clearAffectsByField[field.connectedFieldId] = clearAffectsByField[field.connectedFieldId] || [];
        clearAffectsByField[field.connectedFieldId] = getConnectedFieldDependencies({
            dependencies: clearAffectsByField[field.connectedFieldId],
            connectedField: fieldsMap[field.connectedFieldId],
            field,
        });
    });

    return clearAffectsByField;
};

