
import validateCell from './ValidateCell';

const validateBulkCell = async (cells) => {
    const result = await Promise.all(cells.map(async (cell) => await validateCell(cell)));
    console.log('....ValidateBulkCell.tsx__6__: ', result, '\n_________');

    return result;
};

export default validateBulkCell;
