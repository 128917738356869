import {MorphService} from '@aktek/morph-frontend-sdk';

export default async (sboId, newIds: string[], modifications: object) => {
    if (!newIds?.length) return [];

    const morphsToCreate = newIds.map((id) => {
        const morph = {sboId, ...(modifications?.[id] || {})};
        delete morph._id;
        delete morph.rowId;

        return morph;
    }).reverse();
    const bulkstoreResponse = await MorphService.bulkstoreNewObjects( morphsToCreate, sboId);

    if (!bulkstoreResponse.isSuccessful()) {
        return null;
    }

    const newCreatedData = bulkstoreResponse.getData();

    return newCreatedData?.objects as object[];
};
