import {SBOService} from '@aktek/morph-frontend-sdk';
import {faDatabase} from '@fortawesome/pro-regular-svg-icons';

import {openTab} from '@/app/Home/Tabs';
import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import useTableRef from '@/components/Table/Hooks/useTable';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import {DATA_COLUMNS} from './DataStructure.columns';
import {addRecentlyUsedDS} from './helpers/RecentlyUsedDataStructure';

const DataStructure = () => {
    const tableRef = useTableRef();
    const adminPermissions = useAdminPermissions();
    const {reportStructure} = adminPermissions;

    const dataOnRowAction = (references: TF4ViewableComponentPropsReferences) => (row, action, event, state) => {
        if (action.name == 'edit') {
            openTab('dataStructureEditor', row.original.name, {sboId: row.original._id, refreshTable: ()=>tableRef.refresh?.(),
            });
            const tab = {
                key: row.original._id,
                label: row.original.name,
                icon: faDatabase,
            };
            addRecentlyUsedDS(tab);
        }

        if (action.name == 'delete') {
            references.deleteModalRef.current.open({id: row.original._id, name: row.original.name, message: strings('delete_data_structure_message')});
        }
    };

    return (
        <F4ViewableComponent
            name="data structure"
            tableRef={tableRef}
            tableColumns={DATA_COLUMNS}
            tabName={'dataStructureEditor'}
            onRowAction={dataOnRowAction}
            getAllData={SBOService.getUsableSBOs}
            deleteItem={SBOService.deleteSBO}
            deleteSuccessMessage={strings('data_structure_deleted_successfully')}
            emptyProps={{addLabel: strings('new_data_structure')}}
            canCreate={reportStructure?.can_create}
        />
    );
};

export default DataStructure;
