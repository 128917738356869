import {Drawer, TabNav, useTab} from '@aktek/f4kit';
import {useEffect} from 'react';

import useDocumentTitle from '@/hooks/useDocumentTitle';
import {strings} from '@/localization/i18n';
import {openCloseDrawer} from '@/utils/openCloseDrawer';

import {GetDataStructureTabs} from './constants/data.c';
import ConditionalColoring from './pages/ConditionalColoring/ConditionalColoring';
import DataStructure from './pages/DataStructure/DataStructureTable';

type TDataProps = {
    selectedTab: string
}

export default function Data(props: TDataProps) {
    useDocumentTitle(strings('data_structure'));
    const tab = useTab();

    useEffect(() => {
        props.selectedTab && tab.setState({selectedTab: props.selectedTab});
    }, [props.selectedTab, tab]);

    const dataStructureTabs = GetDataStructureTabs();
    const firstVisibleTabKey = dataStructureTabs.find((tab) => tab.isVisible)?.key;

    const selectedTab = tab.state?.selectedTab || firstVisibleTabKey;
    const TabComponents = {
        dataStructure: <DataStructure />,
        conditional_coloring: <ConditionalColoring />,
        translations: <ConditionalColoring />,
    };

    return (
        <div className="flex h-full">
            <Drawer
                sideWidth={96}
                tooltipDelay={1000}
                defaultOpen={ls.drawer?.dataStructure ?? true}
                onOpenChange={(e) => openCloseDrawer('dataStructure', !!e)}
                buttonSize="sm"
                className="sm:!w-2/4 md:!w-1/4  !w-11/12 px-4 py-2"
            >

                <TabNav
                    size="sm"
                    orientation="vertical"
                    tabs={dataStructureTabs}
                    value={selectedTab}
                    defaultValue={selectedTab || 'dataStructure'}
                    onChange={(selectedTab) => tab.setState({selectedTab})}
                    className="overflow-x-visible"
                    buttonClassName="[&>:first-child]:[&>:first-child]:[&>:first-child]:!w-8"
                />

                <div className="p-4 bg-neutral-50 h-full border-l border-neutral-200">
                    {TabComponents[selectedTab] || <DataStructure />}
                </div>
            </Drawer>
        </div>
    );
}

