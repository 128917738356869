import {CompaniesService} from '@aktek/helios-frontend-sdk';
import {faPieChart} from '@fortawesome/pro-regular-svg-icons';

import F4ViewableComponent from '@/components/F4ViewableComponent/F4ViewableComponent';
import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import AskForModal from '@/components/Modals/AskForModal';
import useTableRef from '@/components/Table/Hooks/useTable';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import {AGENCY_COLUMNS, GetRightClickRowAgencyActions} from './Agency.Columns';
import CreateCompanyModal from './components/CreateCompanyModal';
import UsageMetricsModal from './components/UsageMetricsModal';

const Agency = () => {
    const tableRef = useTableRef();
    const refreshTable = ()=> tableRef.refresh?.();
    const {companies} = useAdminPermissions();

    const AgencyOnRowAction = () => (row, action) => {
        if (action.name == 'edit') {
            return AskForCreationModal(CreateCompanyModal, {state: {mode: 'edit', _id: row.original._id}, refreshTable});
        }

        // this add is not for the create is for the view
        if (action.name == 'add') {
            return AskForCreationModal(CreateCompanyModal, {state: {mode: 'view', _id: row.original._id}});
        }

        if (action.name == 'history') {
            return AskForModal(UsageMetricsModal, {companyId: row.original._id, openOnMount: true,
                header: row.original.companyName});
        }
    };

    return (
        <div className="bg-neutral-50 h-full p-4">
            <F4ViewableComponent
                name="company"
                modal={CreateCompanyModal}
                tableRef={tableRef}
                getRightClickRowActions={GetRightClickRowAgencyActions?.(companies)}
                hasViewMode
                tableColumns={AGENCY_COLUMNS}
                canCreate={companies?.can_create}
                emptyProps={{addLabel: strings('new_company')}}
                onRowAction={AgencyOnRowAction}
                defaultSortedColumn="companyName"
                getAllData={CompaniesService.getCompanies}
                additionalLeftActions={{history: {icon: faPieChart, tooltip: strings('global_usage_metrics'),
                    onRibbonActionClick:
                         ()=> AskForModal(UsageMetricsModal, {companyId: 'Global', openOnMount: true, header: 'Global'})}}}
            />
        </div>
    );
};

export default Agency;
