// Constants
export const MAX_STACK_SIZE = 15; // Define the maximum size of the stack
export const STORAGE_KEY = 'recentlyUsedDS';

// Function to add a tab object to the recently used stack
export function addRecentlyUsedDS(tab) {
    // Get the existing stack from local storage or initialize an empty array
    let stack = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];

    // Check if the tab object already exists in the stack
    stack = stack.filter((item) => item.key !== tab.key);

    // Add the new tab object to the beginning of the stack with a timestamp
    const tabWithTimestamp = {...tab, timestamp: Date.now()};
    stack.unshift(tabWithTimestamp);

    // Remove the oldest entry if the stack exceeds the maximum size
    if (stack.length > MAX_STACK_SIZE) {
        stack.pop();
    }

    // Save the updated stack back to local storage
    localStorage.setItem(STORAGE_KEY, JSON.stringify(stack));
}

// Function to get and sort the recently used tabs
export function getRecentlyUsedDS() {
    // Get the stack from local storage
    const stack = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];

    // Sort the stack by timestamp in descending order (most recent first)
    stack.sort((a, b) => b.timestamp - a.timestamp);

    return stack;
}

