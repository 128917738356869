
const mapFields = (sections, sectionsMap, supportedFields, fn) => {
    const results = [];
    sections?.forEach((sectionId) => {
        const section = sectionsMap[sectionId];
        section.fields?.forEach((fieldId) => {
            const field = supportedFields[fieldId];
            const result = fn(field, fieldId);
            result && results.push(result);
        });
    });

    return results;
};

export default mapFields;
