import {useModalRef} from '@aktek/f4kit';
import {faColumns} from '@fortawesome/pro-regular-svg-icons';
import _get from 'lodash/get';

import {openTab} from '@/app/Home/Tabs';
import F4DeleteModal from '@/components/F4DeleteModal/F4DeleteModal';
import Table from '@/components/Table/Table';
import {strings} from '@/localization/i18n';

import F4ConfirmationModal from '../F4ConfirmationModal/F4ConfirmationModal';
import AskForCreationModal from '../Modals/AskForCreationModal';
import useTableRef from '../Table/Hooks/useTable';
import {TF4ViewableComponentProps} from './types/F4ViewableComponentProps.t';

export default function F4ViewableComponent({
    tableColumns,
    getAllData,
    modal: getModal,
    deleteItem,
    additionalLeftActions, additionalRightActions,
    onRowAction,
    noPagination,
    hasSearch = true,
    canCreate = true,
    defaultSortedColumn,
    deleteIcon = true,
    emptyProps,
    tableRef = useTableRef(),
    deleteSuccessMessage,
    name,
    tabName,
    hasViewMode = false,
    getRightClickRowActions,
} : TF4ViewableComponentProps) {
    // TODO: don't rely on refs to open any modals
    const modalRef = useModalRef();
    const deleteModalRef = useModalRef();
    const confirmationModalRef = useModalRef();

    const refreshTable = () => tableRef.refresh?.();

    return (
        <>
            <F4DeleteModal deleteIcon={deleteIcon} ref={deleteModalRef}
                deleteFunction={deleteItem} onDelete={refreshTable} deleteSuccessMessage={deleteSuccessMessage}/>

            <F4ConfirmationModal ref={confirmationModalRef} />

            <Table
                baseTableClasses={{
                    theadCellTH: 'py-3 px-6 text-neutral-600 font-medium text-xs text-left relative min-w-40',
                }}
                emptyProps={emptyProps}
                events={{
                    onRowDoubleClick: (event, table, row) => {
                        if (hasViewMode) {
                            return AskForCreationModal(getModal, {state: {mode: 'view', _id: row.original._id}});
                        }
                    },
                }}
                name={name}
                tableRef={tableRef}
                noPagination={noPagination}
                columns={tableColumns}
                defaults={{
                    sortingState: [{id: defaultSortedColumn, desc: false}],
                }}
                getRightClickRowActions={getRightClickRowActions?.(refreshTable, deleteModalRef)}
                onRowAction={onRowAction({modalRef, deleteModalRef, confirmationModalRef})}
                getRows={async (paginationState, sortingState, countState, searchState) => {
                    const {pageSize, currentPage} = paginationState;
                    const search = searchState.query.trim();
                    const sort = {id: sortingState[0]?.id ||'name', order: sortingState[0]?.desc ? '-1' : '1'};
                    const offset = pageSize * (currentPage -1);
                    const response = await getAllData(search, sort, offset, pageSize);

                    if (!response.isSuccessful()) return [];

                    const data = response.getData() as TResponse;

                    if (!data) return [];

                    const items = (Array.isArray(data) ? data : data?.data) || [];
                    const count = _get(data, ['metadata', 0, 'count']);

                    if (count !== undefined) countState.setCount(count);

                    return items;
                }}
                ribbonActions={
                    {
                        leftActions: {
                            add: canCreate && {
                                tooltip: emptyProps?.addLabel ? `${strings('add')} ${emptyProps?.addLabel}` : strings('add_new'),
                                onRibbonActionClick: () => {
                                    getModal && AskForCreationModal(getModal, {refreshTable});
                                    tabName && openTab('dataStructureEditor', 'New Data Structure', {refreshTable});
                                },
                            },
                            ...additionalLeftActions,
                            refresh: {
                                tooltip: strings('refresh'),
                                onRibbonActionClick: (name, e, state) => {
                                    state.rowsState.fetchRows();
                                },
                            },
                        },
                        rightActions: {
                            search: hasSearch && {
                                searchProps: {
                                    onDebouncedChange(state, value) {
                                        state.searchState.setQuery(value);
                                    },
                                },
                            },
                            ellipsis: {
                                tooltip: strings('options'),
                                options: {
                                    hide_show_columns: {
                                        label: strings('hide_show_columns'),
                                        type: 'item', option: 'Icon', icon: {color: 'neutral-700', icon: faColumns}, isDisabled: false,
                                        onClick: () => {
                                            tableRef?.editColumnsVisibility?.();
                                        },
                                    },
                                },
                            },
                            ...additionalRightActions,
                        },
                    }
                }
            />
        </>
    );
}
