import {faDatabase, faFolder} from '@fortawesome/pro-regular-svg-icons';

import {Tabs} from '@/app/Home/Tabs';
import Env from '@/config/env';

export type TFileNode = Record<string, {
    _id: string,
    label: string,
    type: 'folderName' | 'label' | 'i18nName',
    children: TFileNode,
    parentId?: string,
    isPublic?: boolean,
    creatorId?:string,
    component?:string,
    customIcon?:string,
    customColor?:string,
    props?: {
        selectedTab?: string,
    },
}>

type TConvertFilesToTreeView = (node: TFileNode) => void

const convertFilesToTreeView: TConvertFilesToTreeView = (input = null) => {
    return Object.values(input).map((item) => {
        return (
            {
                id: item._id,
                label: getLabel(item),
                type: item.type,
                icon: getIcon(item),
                parentId: item?.parentId,
                children: item.children ? convertFilesToTreeView(item.children): null,
                isPublic: item?.isPublic,
                creatorId: item?.creatorId,
                component: item?.component,
                customIcon: item?.customIcon,
                customColor: item?.customColor,
                props: item?.props,
                customImage: item?.customIcon ? Env.getFileURL(item?.customIcon) : null,
            }
        );
    });
};

const LabelByType = {
    folder: 'folderName',
    shortcut: 'label',
    report: 'i18nName',
};

const IconByType = (item) => {
    const shortcutIcon = item.type == 'shortcut' && Tabs[item.component]?.icon;

    return {
        folder: faFolder,
        report: faDatabase,
        shortcut: shortcutIcon,
    };
};

const getLabel = (item) => item[LabelByType[item.type]] || item.name;
const getIcon = (item) => IconByType(item)[item.type];

export default convertFilesToTreeView;
