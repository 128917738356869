import {F4Form, F4FormDiv, F4Nestor} from '@aktek/f4form';
import {LoadingWrapper} from '@aktek/f4kit';
import {isEqual} from 'lodash';
import {useState} from 'react';

import LoginSideImage from '@/assets/images/login_side_image.png';
import AvatarImageSelector from '@/components/F4Elements/F4AvatarImageSelector';
import F4ButtonColorPicker from '@/components/F4Elements/F4ButtonColorPicker';
import F4Dropdown from '@/components/F4Elements/F4Dropdown';
// import F4ImageSelector from '@/components/F4Elements/F4ImageSelector';
import F4RadioButtonGroup from '@/components/F4Elements/F4RadioButtonGroup';
import XApplyTheme from '@/helpers/ApplyTheme.fn';
import {isEmptyOrAllNill} from '@/helpers/IsEmptyOrAllNill.fn';
import {strings} from '@/localization/i18n';

import SettingsFooter from '../../components/SettingsFooter';
import {DEFAULT_APPEARANCE, FONT_SIZES, FONT_STYLES, THEME_COLORS} from './constants/Appearance.c';
import useAppearance from './hooks/useAppearance';

export default function Appearance() {
    const {isLoading, appearanceFormRef, initialValue, handleSave, handleCancel, handleReset} = useAppearance();

    const handleFileReaderChange = (files) => {
        if (files.length !== 0) {
            if (!(files[0] instanceof Blob)) return;
            appearanceFormRef?.current?.importData(files[0]);
        }
    };

    const [isEdited, setIsEdited] = useState(false);
    const [isDefault, setIsDefault] = useState(false);

    return (
        <div className="px-4 bg-neutral-50 h-fit min-h-full border-l border-neutral-200">
            <F4Form
                formKey="appearance"
                ref={appearanceFormRef}
                printData="console"
                onChange={(appearance)=>{
                    const theme = {
                        colors: appearance.colors,
                        font: appearance.font,
                    };
                    if (isEqual(initialValue?.appearance, appearance)) setIsEdited(false);
                    else setIsEdited(true);
                    if (isEqual(DEFAULT_APPEARANCE, appearance)) setIsDefault(true);
                    else setIsDefault(false);

                    if (!isEmptyOrAllNill(theme)) XApplyTheme(theme);
                }}
                initialValue={initialValue?.appearance}
                className="h-full"
            >
                <F4FormDiv>
                    <LoadingWrapper isLoading={isLoading} isFull className="w-full absolute mx-auto my-auto" size="lg">
                        <F4FormDiv className="grid gap-4 grid-cols-9">

                            <AvatarImageSelector
                                name="images.logo"
                                label={strings('company_logo')}
                                wrapper={{className: 'mt-4'}}
                                size="3xl"
                                showOptional={false}
                                allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                                maxSizeMB={1}
                            />

                            <F4Nestor name="font">
                                <F4Dropdown
                                    name="fontStyle"
                                    wrapper={{className: 'mt-4'}}
                                    required
                                    colSpan={4}
                                    label="Font"
                                    renderOption={(option)=> {
                                        const fontKey = Object.keys(FONT_STYLES).find((key) => FONT_STYLES[key] === option);

                                        return <p style={{fontFamily: fontKey}}>{option as string}</p>;
                                    }}
                                    options={FONT_STYLES} />

                                <F4RadioButtonGroup
                                    name="fontSize"
                                    required
                                    colSpan={8}
                                    wrapper={{className: 'mt-10'}}
                                    options={FONT_SIZES} />
                            </F4Nestor>

                            <AvatarImageSelector
                                name="images.background"
                                label={strings('login_image')}
                                uploadLabel={strings('upload_image')}
                                replaceLabel={strings('replace_image')}
                                wrapper={{className: 'mt-4'}}
                                size="3xl"
                                className=" !h-44 !w-32 "
                                showOptional={false}
                                defaultImage={{src: LoginSideImage, alt: 'AKTEK'}}
                                allowedTypes={['image/jpeg', 'image/png', 'image/jpg']}
                                maxSizeMB={2}
                            />

                            <F4Nestor name="colors">
                                {THEME_COLORS.map((color : {name : string, label : string, value : string}) => (
                                    <F4ButtonColorPicker
                                        size="3xl"
                                        name={color.name}
                                        required
                                        colSpan={2}
                                        key={color.name}
                                        label={strings(color.label)}
                                    />))}
                            </F4Nestor>
                        </F4FormDiv>
                    </LoadingWrapper>
                    <SettingsFooter
                        onSave={handleSave}
                        handleCancel={handleCancel}
                        handleReset={handleReset}
                        onFileUpload={handleFileReaderChange}
                        appearanceFormRef={appearanceFormRef}
                        isInitialValue={!isEdited}
                        isDefault={isDefault}
                    />
                </F4FormDiv>
            </F4Form>
        </div>
    );
}

