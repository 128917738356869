import {F4FormDiv} from '@aktek/f4form';
import {ContentDivider, Label, TextBox} from '@aktek/f4kit';

import {strings} from '@/localization/i18n';

function DataStructureSectionsFields() {
    return (
        <F4FormDiv>
            {/* ContentDivider */}
            <F4FormDiv>
                <ContentDivider
                    backgroundColor="neutral-50"
                    middleChild={
                        // <Label
                        //     text={strings('Section 1')}
                        //     onClick={()=> {}}
                        //     className="text-neutral-600 !cursor-pointer text-sm select-none" />
                        <TextBox isGhost placeholder="Section 1" className="text-neutral-600 text-sm !bg-neutral-100 rounded-none placeholder-neutral-300"/>
                    }
                />
            </F4FormDiv>
        </F4FormDiv>
    );
}

export default DataStructureSectionsFields;
