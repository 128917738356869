import {Header, Icon, Label} from '@aktek/f4kit';
import {faBell, faDisplayChartUp, faHome, faRightFromBracket, faSatellite, faSearch, faUser} from '@fortawesome/pro-regular-svg-icons';
import compact from 'lodash/compact';
import {useCallback, useMemo} from 'react';

import AKTEKLogo from '@/assets/images/aktek_logo.png';
import AskForModal from '@/components/Modals/AskForModal';
import Env from '@/config/env';
import {CanAccessCompanySwitcher} from '@/constants/Permissions.c';
import {useAppPermissions} from '@/context/UserContext/Hooks/useAppPermissions';
import {useSignUserOut} from '@/context/UserContext/Hooks/useSignUserOut';
import {useUserContext} from '@/context/UserContext/Provider';
import Permissions from '@/helpers/Permissions.fn';
import {strings} from '@/localization/i18n';
import {useNavigateToLogin, useNavigateToRoot} from '@/routing/navigations';

import SwitchCompanyModal from './components/SwitchCompanyModal';
import {focusOrOpenNewTab} from './Tabs';

function AppHeader({theme}) {
    const {userAuth} = useUserContext();

    const {userCompanies, currentUser, currentCompany} = userAuth;
    const {companyName} = currentCompany || {};
    const profilePicURL = currentUser?.profilePicURL;
    const {admin} = useAppPermissions();

    const signOut = useSignUserOut();

    const navigateToRoot = useNavigateToRoot();
    const navigateToLogin = useNavigateToLogin();

    const toggleCompanySwitcherModal = () => {
        if (currentUser && userCompanies) {
            AskForModal(SwitchCompanyModal);
        }
    };

    const getHeader = useCallback(
        ({onLogo, onLogout, profilePicURL, onAdmin}) => ({
            leftItems: compact([
                {
                    type: 'image',
                    src: theme?.images?.logo ? Env.getFileURL(theme.images.logo) : AKTEKLogo,
                    onClick: onLogo,
                    label: 'Logo',
                    className: ls.theme?.images?.logo ? '!p-0' : '',
                },
                {
                    type: 'button',
                    icon: faHome,
                    label: strings('home'),
                    onClick: () => focusOrOpenNewTab('home', undefined, undefined, {isPinned: true}),
                },
                Permissions.App().PERMISSIONS_EXISTS() && {
                    type: 'button',
                    icon: faDisplayChartUp,
                    label: strings('analysis'),
                    onClick: () => focusOrOpenNewTab('analysis'),
                },

                Permissions.App().PERMISSIONS_EXISTS() && {
                    type: 'button',
                    icon: faSatellite,
                    label: 'AKTEK iQ',
                    onClick: () => focusOrOpenNewTab('AKTEKiQ'),

                },
                // admin?.can_access && {
                Permissions.App().ACCESS('Admin') && {
                    type: 'button',
                    icon: faUser,
                    label: 'Admin',
                    onClick: onAdmin,
                },
            ]),

            rightItems: compact([
                {
                    type: 'button',
                    label: companyName || '',
                    className: `bg-neutral-100 ${!CanAccessCompanySwitcher() && 'cursor-default hover:!text-neutral-700'} `,
                    onClick: CanAccessCompanySwitcher() && toggleCompanySwitcherModal,
                },
                {type: 'icon', icon: faSearch, label: 'Search', className: 'h-8 w-8'},
                {type: 'icon', icon: faBell, label: 'Notifications', className: 'h-8 w-8'},
                {
                    type: 'avatar',
                    src: profilePicURL,
                    label: 'Avatar',
                    size: 'xl',
                    subMenu: [
                        {
                            type: 'custom',
                            component: (
                                <div className="flex flex-row items-center justify-center mx-2 mt-2">
                                    <Icon icon={faUser} size="xs" color="neutral-700" />
                                    <div className="flex flex-col items-start justify-start">
                                        <Label
                                            text={currentUser?.name || 'Unknown'}
                                            size="xs"
                                            color="neutral-700"
                                            fontWeight="semibold"
                                            className="ml-4"
                                        />
                                        <Label
                                            text={currentUser?.email || ''}
                                            size="xs"
                                            color="neutral-700"
                                            fontWeight="normal"
                                            className="ml-4 -mt-0"
                                        />
                                    </div>
                                </div>
                            ),
                        },
                        {
                            type: 'button',
                            size: 'md',
                            icon: faRightFromBracket,
                            label: strings('account_settings'),
                        },
                        {
                            type: 'button',
                            size: 'md',
                            icon: faRightFromBracket,
                            label: strings('logout'),
                            onClick: onLogout,
                        },
                    ],
                },
            ]),
        }),
        [currentUser, companyName, profilePicURL, Permissions.Admin, theme?.images],
    );

    const header = useMemo(
        () =>
            getHeader({
                onLogout: () => {
                    signOut();
                    navigateToLogin(true);
                },
                onLogo: navigateToRoot,
                onAdmin: () => focusOrOpenNewTab('admin'),
                profilePicURL: profilePicURL && Env.getFileURL(profilePicURL),
            }),
        [profilePicURL, Permissions.Admin(), currentCompany, theme?.images],
    );

    return <Header value={header} />;
}

export default AppHeader;
