import {useNavigate} from 'react-router-dom';

import Paths from './Path';

export const useNavigateToVerifyURL = () => {
    const navigate = useNavigate();

    return () => navigate(Paths.VerifyURL);
};

export const useNavigateToRequestResetURL = () => {
    const navigate = useNavigate();

    return () => navigate(Paths.requestResetPassword);
};

export const useNavigateToLogin = () => {
    const navigate = useNavigate();

    return (replace) => navigate(Paths.Login, {replace});
};

export const useNavigateToRoot = () => {
    const navigate = useNavigate();

    return (replace) => navigate('/', {replace});
};

export const useNavigateToURL = () => {
    const navigate = useNavigate();

    return (path) => navigate('/' + path);
};
