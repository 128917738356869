
import Boolean from './Boolean';

const BooleanCellEdit = ({value, onChange}) => {
    return <Boolean
        onChange={onChange}
        value={value}
    />;
};

export default BooleanCellEdit;
