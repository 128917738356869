import 'react-virtualized/styles.css';

import {LoadingIndicator} from '@aktek/f4kit';
import {setIn} from 'immutable';
import _ from 'lodash';
import _get from 'lodash/get';
import {useCallback, useEffect, useRef, useState} from 'react';
import {AutoSizer, Table as VirtualTable} from 'react-virtualized';

import {strings} from '@/localization/i18n';

import {Sizes} from '../constants/PermissionConstants';
import ScopesACL from '../constants/ScopesACL';
import _getRowHeight from '../helpers/getRowHeight';
import updatePermissions from '../helpers/updatePermissions.fn';
import {TPermissionEditor, TPermissionsProps} from '../types/PermissionProps.t';
import PermissionEditorHeader from './PermissionEditorHeader';
import PermissionHeaderRow from './PermissionHeaderRow';
import PermissionRow from './PermissionRow';

const PermissionEditor = ({
    permissions: propPermissions,
    onPermissionsChange,
    isLoading,
    onCheckBoxChange,
    isSuperAdmin, setIsSuperAdmin,
    resetPermissions,
    disabled,
    canResetPassword,
}: TPermissionEditor) => {
    const [permissions, setPermissions] = useState<TPermissionsProps | object>({});
    const [selectedSection, setSelectedSection] = useState(ls.permissionSection || 'admin');
    const [searchValue, setSearchValue] = useState('');
    const [isAllSelected, setIsAllSelected] = useState({
        admin: false,
        app: false,
        dashboard: false,
        report: false,
    });
    const [rowExpansionDict, setRowExpansionDict] = useState({});
    const tableRef = useRef(null);

    useEffect(() => {
        if (!propPermissions) return;
        setPermissions(propPermissions);
        tableRef.current?.recomputeRowHeights();
    }, [propPermissions]);

    const checkAllPermissionsAndChildren = (permission, aclType) => {
        const areAllSelected = aclType.every((type) => permission[type] === 1);

        if (!permission.children) return areAllSelected;

        return areAllSelected && permission.children.every((child) =>
            checkAllPermissionsAndChildren(child, aclType),
        );
    };

    const areAllPermissionsSelected = (updatedPermissions, aclType) => {
        return updatedPermissions[selectedSection]?.every((permissionRow) =>
            checkAllPermissionsAndChildren(permissionRow, aclType),
        );
    };

    const isAllCheckboxSelected = areAllPermissionsSelected(permissions, ScopesACL[selectedSection]?.acls);

    useEffect(() => {
        setIsAllSelected((prevState) => ({
            ...prevState,
            [selectedSection]: isAllCheckboxSelected,
        }));
    }, [permissions, selectedSection]);

    const handlePermissionsChange = (newPermissions) => {
        setPermissions(newPermissions);

        if (onPermissionsChange) {
            onPermissionsChange(newPermissions);
        }
    };

    const onToggleHelperClick = useCallback((toggleProps, value) => {
        if (toggleProps.category !== 'all' && isAllSelected[selectedSection]) {
            setIsAllSelected((prevState) => ({
                ...prevState,
                [selectedSection]: false,
            }));
        }

        const updatedPermissions = updatePermissions(permissions, toggleProps, value);
        handlePermissionsChange(updatedPermissions);
    }, [permissions]);

    const recomputeTableLayout = useCallback(() => {
        tableRef.current?.recomputeRowHeights();
        tableRef.current?.forceUpdateGrid();
    }, []);

    const onCheckBoxClick = (obj) => {
        if (isAllSelected[selectedSection]) {
            setIsAllSelected((prevState) => ({
                ...prevState,
                [selectedSection]: false,
            }));
        }

        onCheckBoxChange?.(obj);

        const {path, aclType} = obj;
        const pathArray = path.split('.');
        const currentEntry = _get(permissions, path);
        const newACLValue = currentEntry[aclType] == 0 ? 1 : 0;
        const newPermissions = setIn(permissions, pathArray.concat(aclType), newACLValue);
        handlePermissionsChange(newPermissions);
    };

    const getRow = ({index}) => {
        const data = getFilteredPermissions();

        return data?.[index];
    };

    const toggleRowExpansion = (id) => {
        setRowExpansionDict((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
        recomputeTableLayout();
    };

    const onSelectAll = () =>{
        onToggleHelperClick({selectedSection: selectedSection, category: 'all'}, isAllSelected[selectedSection] ? 0 : 1);
        setIsAllSelected((prevState) => ({
            ...prevState,
            [selectedSection]: !prevState[selectedSection],
        }));

        // setIsSuperAdmin(false);
    };

    const _setIsSuperAdmin = (value) => {
        if (value) {
            onToggleHelperClick({category: 'superadmin'}, 1);
        }

        setIsSuperAdmin(value);
    };

    const renderPermissionRow = (permission, rowIndex, selectedSection, style, parentCategory, depth = 0, currentPath = '') => {
        return (
            <PermissionRow
                key={`PermissionRow-${rowIndex}${permission.id}${currentPath}`}
                permission={permission}
                disabled={disabled}
                rowIndex={rowIndex}
                selectedSection={selectedSection}
                style={style}
                parentCategory={parentCategory}
                depth={depth}
                currentPath={currentPath}
                onToggleHelperClick={onToggleHelperClick}
                onCheckBoxClick={onCheckBoxClick}
                rowExpansionDict={rowExpansionDict}
                toggleRowExpansion={toggleRowExpansion}
                setIsSuperAdmin={setIsSuperAdmin}
                isSuperAdmin={isSuperAdmin}
            />
        );
    };

    const renderPermissionHeaderRow = ( style) => {
        return (
            <PermissionHeaderRow
                disabled={disabled}
                isAllSelected={isAllSelected[selectedSection]}
                onSelectAll={onSelectAll}
                permissions={permissions}
                selectedSection={selectedSection}
                style={style}
                onToggleHelperClick={onToggleHelperClick}
                setIsSuperAdmin={setIsSuperAdmin}
                isSuperAdmin={isSuperAdmin}
            />
        );
    };

    const getRowHeight = ({index}) => {
        if (!permissions?.[selectedSection]) return 0;

        const data = getFilteredPermissions();
        const permission = data[index];
        const isExpanded = rowExpansionDict[permission.id];

        return _getRowHeight(Sizes.rowHeight, permission?.children?.length, isExpanded);
    };

    const getFilteredPermissions = () => {
        const sectionPermissions = permissions[selectedSection];

        if (!sectionPermissions) return [];

        if (searchValue.length < 1) return sectionPermissions;

        const searchLower = searchValue.toLowerCase();

        return sectionPermissions.filter(({name}) =>
            name.toLowerCase().includes(searchLower),
        );
    };

    const filteredData = getFilteredPermissions().length;

    const rowCount = permissions?.[selectedSection] ? getFilteredPermissions().length : 0;
    const useDynamicRowHeight = true;

    return (
        <div className="bg-white py-3 h-fuldl wf-full " >
            <PermissionEditorHeader
                permissions={propPermissions}
                disabled={disabled}
                setSelectedSection={setSelectedSection}
                selectedSection={selectedSection}
                isSuperAdmin={isSuperAdmin}
                setIsSuperAdmin={_setIsSuperAdmin}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                resetPermissions={resetPermissions}
                canResetPassword={canResetPassword} />

            <div className="border border-neutral-200 !h-full rounded-xl">

                {filteredData == 0 && !_.isEmpty(permissions)
                 && <div className="px-2 py-4 text-center text-neutral-600">{strings('no_results_found')}</div>}
                {((filteredData == 0 && _.isEmpty(permissions)) || isLoading)
                && <div className="flex items-center h-full justify-center">
                    <LoadingIndicator size="xl" />
                </div>
                }

                {filteredData != 0 && <AutoSizer disableHeight>
                    {({width}) => {
                        return (
                            <VirtualTable
                                ref={tableRef}
                                autoHeight
                                // This big number just to give enough space to the items to be shown
                                height={500000}
                                width={width}
                                disableHeader={false}
                                headerHeight={Sizes.rowHeight}
                                style={{outline: 'none', background: 'white'}}
                                headerStyle={{height: Sizes.rowHeight, textAlign: 'center'}}
                                rowHeight={useDynamicRowHeight ? getRowHeight : Sizes.rowHeight}
                                overscanRowCount={20}
                                rowGetter={getRow}
                                rowRenderer={({rowData, index, style}) => renderPermissionRow(
                                    rowData, index, selectedSection, style, selectedSection,
                                    0, `${selectedSection}.${rowData?.originalIndex}`)
                                }
                                headerRowRenderer={({style}) => renderPermissionHeaderRow(style)}
                                rowCount={rowCount}
                            />
                        );
                    }}
                </AutoSizer>}
            </div>
        </div>
    );
};

export default PermissionEditor;
