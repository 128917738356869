import {F4FormDiv, F4FormElementDecorator, F4FormStep} from '@aktek/f4form';
import {Label} from '@aktek/f4kit';
import {useState} from 'react';

import {useF4CreatableFormContext} from '@/components/F4CreatableForm/F4CreatableFormContext';
import LicenseIssued from '@/components/LicenseComponents/LicenseIssued';
import AskForConfirmation from '@/components/Modals/AskForConfirmation';
import {ConvertUnixToDate, DaysUntil} from '@/helpers/globalDates.fn';
import {strings} from '@/localization/i18n';

import {TCompanyProgram} from '../Agency';
import useAgency from '../hooks/useAgency';
import ValidityDatePicker from './ValidityDatePicker';

const ActivationInfo = () => {
    const {extra} = useF4CreatableFormContext();

    const {revokeFunction} = useAgency();

    const companyProgram = extra.companyProgram as TCompanyProgram;
    const companyId = extra.companyId as string;
    const ref = extra.ref;
    const companyInfo = ref?.current?.getData(companyId);
    const mode = extra?.state?.mode;

    const isViewMode = mode == 'view';

    const pickedDateState = ref?.current?.getData()?.activation?.validityDate;

    const [hasValidity, setHasValidity] = useState(false);

    const isLicenseActive = Object.keys(companyProgram).length > 0 && companyProgram?.isActive && companyProgram?.isValid;

    const onClickHandler = (timestamp) => {
        const daysRemaining = DaysUntil(timestamp);

        if (ref?.current?.getData() === null) return;
        ref?.current?.setValue('activation.validity', daysRemaining);
        ref?.current?.setValue('activation.validityDate', ConvertUnixToDate(timestamp));
    };

    const onClearField= () => {
        if (ref?.current?.getData() === null) return;

        ref?.current?.setValue('activation.validity', null);
        ref?.current?.setValue('activation.validityDate', null);
    };

    if (!companyInfo?.license?.licensePlan) {
        return <F4FormStep step="activation" autoNest>
            {!isViewMode && <F4FormDiv className="flex flex-col gap-2 pb-3">
                <Label text={strings('please_choose_license_to_proceed_with_activation')} color="neutral-600" size="md" />
                <Label text={strings('or_click_save_to_create_company_without_activation')} color="neutral-600" size="md" />
            </F4FormDiv>}
            {isViewMode && <Label text={strings('this_company_is_not_activated_yet')} color="neutral-600" size="md" /> }
        </F4FormStep>;
    }

    return (
        <F4FormStep step="activation" autoNest>
            <F4FormDiv className="overflow-y-auto">
                {!isViewMode && <F4FormDiv className="flex flex-col gap-2 pb-3">
                    <Label text={strings('activation_description')} color="neutral-600" size="md" />
                </F4FormDiv>}

                {(hasValidity || !companyInfo?.license?.expiryDate)
                && <ValidityDatePicker onClickHandlerFunction={onClickHandler} pickedDateState={pickedDateState} onClearField={onClearField} />}

                {companyProgram?.validity && companyInfo?.license?.expiryDate
                && <F4FormDiv className="flex items-center justify-center p-5">
                    <LicenseIssued
                        validity={isLicenseActive ? companyProgram.validity : 0}
                        issueDate={ConvertUnixToDate(companyProgram.issuedOn)}
                        expiryDate={ConvertUnixToDate(companyProgram.expiryDate)}
                        isActive={isLicenseActive}
                        mode={mode}
                        onRevoke={() => AskForConfirmation(
                            strings('revoke_license'),
                            strings('revoke_license_confirm'),
                            () => revokeFunction(companyId, companyProgram),
                            'error',
                            strings('revoke_license'),
                        )}
                        onReissue={() => setHasValidity(true)}/>
                </F4FormDiv>}

            </F4FormDiv>

        </F4FormStep>
    );
};

const F4ActivationInfo = F4FormElementDecorator(ActivationInfo);
export default F4ActivationInfo;
