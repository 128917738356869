// import {TDataRow} from '../../../Spreadsheet/Types/DataRow.t';
// import {TColumn} from '../../../Spreadsheet/Types/TColumn.t';
const strings = {
    number_of_files_range: 'Number of files accepted is between #min and #max',
    field_is_required: 'Field is required',
    not_valid: 'Not valid',
};
import {isValidNumber} from './isValidNumber';

const REQUIRED_VALIDATION_NUMBER_TYPES = ['integer', 'float'];
const REQUIRED_VALIDATION_REF_TYPES = ['ref', 'refList', 'connectedRef'];
const MEDIA_TYPES = ['mediaImage', 'mediaVideo', 'mediaAlbum', 'mediaGeneric'];

export default (field) => {
    const isNumberField = REQUIRED_VALIDATION_NUMBER_TYPES.includes(field.type);
    const isRefField = REQUIRED_VALIDATION_REF_TYPES.includes(field.type);
    const isMedia = MEDIA_TYPES.includes(field.type);

    const mediaLimitationsRequired = isMedia && (field.maximum !== undefined || field.minimum !== undefined);

    if (!field.isRequired && !isNumberField && !mediaLimitationsRequired) return null;

    const validate = (row, column) => {
        const value = row[column.key];

        const isValueAvailable = !(value === '' || value === undefined || value === null);

        if (mediaLimitationsRequired) {
            const message = strings.number_of_files_range?.replace('#min', field.minimum).replace('#max', field.maximum);

            if (field.minimum && (value?.length || 0) < field.minimum) {
                return {isValid: false, message};
            }

            if (field.maximum && (value?.length || 0) > field.maximum) {
                return {isValid: false, message};
            }
        }

        if (isRefField && field?.isRequired) {
            if (field.type == 'ref') {
                return {isValid: !!value?._id, message: strings.field_is_required};
            }

            if (field.type == 'refList') {
                const isValid = Array.isArray(value) && !!value.length && value.every((item) => !!item._id);

                return {isValid, message: strings.field_is_required};
            }

            if (field.type == 'connectedRef') {
                return {isValid: !!value?._id, message: strings.field_is_required};
            }
        }

        // if no value, no need to check range
        if (!field.isRequired && !isValueAvailable ) {
            return {isValid: true};
        }

        if (isNumberField && isValueAvailable) {
            const isValueValid = isValidNumber(value, field.minimum, field.maximum);

            return {
                isValid: isValueValid,
                message: strings.not_valid,
            };
        }

        return {
            isValid: isValueAvailable,
            message: strings.field_is_required,
        };
    };

    return validate;
};
