import F4TableSpreadsheet from '@/components/F4TableSpreadsheet/F4TableSpreadsheet';
import Table from '@/components/Table/Table';
import Env from '@/config/env';

import getColumns from './helpers/getColumns';
import getCount from './helpers/getCount';
import getRows from './helpers/getRows';

const DataTableView = (props) => {
    const {sboId} = props;

    // console.log('....DataTable.View.tsx__11__: ', props, '\n_________');

    return <div className="m-4">
        { Env.local && <F4TableSpreadsheet sboId={sboId} />}
        <Table
            getColumns={getColumns(sboId)}
            getRows={getRows(sboId)}
            getCount={getCount(sboId)}
            headerActions={{
                leftActions: {
                    add: {
                        tooltip: 'Add new record',
                        variant: 'primary',
                        onHeaderClick: () => {
                        },

                    },

                },
                rightActions: {},
            }}
        />
    </div>;
};

export default DataTableView;
