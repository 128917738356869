
import {Cell, Row} from '@tanstack/react-table';

import BooleanCellView from '@/components/Fields/Boolean/Boolean.Cell.View';

export const CellBoolean = () => {
    return {
        cell: ({cell, row}: {row: Row<unknown>, cell: Cell<unknown, unknown>}) => {
            const selected = row.getValue(cell.column.id);

            return <BooleanCellView value={!!selected} />;
        },
    };
};
