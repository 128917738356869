const cleanString = (string) => (string || '').trim?.().toLowerCase().replace(/\s+/g, '');

export default async ({value, _id, field}) => {
    if (value === null) {
        // return {isValid: !field.isRequired, finalValue: null}
    }

    // const dictionary = await MetaField.getDataDictionary(field.dynamicField) || {};
    const dictionary = {}; // TODO: Fix this

    let convertedValue = value;

    if (field?.isMulti) {
        if (typeof value === 'string') {
            const values = value.split(',');
            convertedValue = values.map((_value) => dictionary[cleanString(_value)] || _value);
        }
    }

    if (!field?.isMulti) {
        if (typeof value === 'string') {
            const _value = value?.split(',')[0];
            convertedValue = dictionary[cleanString(_value)] || _value;
        }

        if (Array.isArray(value)) {
            const _value = value[0];
            convertedValue = dictionary[cleanString(_value)] || _value;
        }
    }

    const hasValidValue = Object.values(dictionary).includes(convertedValue);

    const isValid = hasValidValue || (!field.isRequired && (convertedValue == null));

    const finalValue = hasValidValue ? convertedValue : null;

    return {isValid: true, finalValue};
};
