import {Button, Empty} from '@aktek/f4kit';
import {faPlus, faRefresh} from '@fortawesome/pro-regular-svg-icons';
import _compact from 'lodash/compact';

import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {strings} from '@/localization/i18n';

import CreateFolderModal from './CreateFolderModal';
import ExplorerLoadingComponent from './ExplorerLoadingComponent';

const EmptyComponent = ({label, description, parentId}:{label:string, description: string, parentId?:string}) => {
    const {folders: foldersPermissions} = useAdminPermissions();
    const {isLoading, refresh} = useGlobalFilesAndFolders();

    const emptyActionElements = [
        foldersPermissions?.can_create && <Button
            key={1}
            variant="neutral"
            isGhost={false}
            isOutlined={true}
            isCircle={false}
            edgeIcons={{left: {icon: faPlus, color: 'neutral-600'}}}
            label={strings('new_folder')}
            onClick={() => AskForCreationModal(CreateFolderModal, {state: {mode: 'create', _id: null}, refresh, parentId})}
            size={'sm'} />,
        <Button
            key={2}
            variant="primary"
            isGhost={false}
            isOutlined={false}
            isCircle={false}
            label={strings('refresh')}
            onClick={refresh}
            size={'sm'}
            icon={faRefresh}
        />];

    return (
        <div className="flex justify-center bg-neutral-50 w-full h-full border-l border-neutral-200 ">
            <Empty
                label={label}
                size="lg"
                titleClassName="!text-base"
                descriptionClassName="!text-sm"
                className="max-w-lg mt-40 flex items-center justify-start"
                description={description}
                descriptionColor="neutral-600"
                actionElements={_compact(emptyActionElements)}/>

            <ExplorerLoadingComponent condition={isLoading} />

        </div>

    );
};

export default EmptyComponent;

