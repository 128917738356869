import {F4FormDiv, F4FormStep} from '@aktek/f4form';
import {Label} from '@aktek/f4kit';
import {useState} from 'react';

import {FOLDERS_AND_DATA} from '@/app/Explorer/constants/constantIds.c';
import useFilteredItems from '@/app/Explorer/hooks/useFilteredItems';
import {useGlobalFilesAndFolders} from '@/context/UserContext/Hooks/useGlobalFilesAndFolders';
import {GetFirstKey} from '@/helpers/GetFirstKey.fn';
import {strings} from '@/localization/i18n';

import F4CreatableModal from '../F4CreatableModal/F4CreatableModal';
import {F4CreatableModalProps} from '../F4CreatableModal/types/F4CreatableModalProps.t';
import F4TreeView from '../F4Elements/F4TreeView';
import SearchBox from '../SearchBox/SearchBox';

const MoveToLocationModal = (props: F4CreatableModalProps) => {
    const {openOnMount, item, getResult, ...rest}= props;
    const [searchValue, setSearchValue] = useState('');

    const scrollTimeOut = 2200;

    const {folders} = useGlobalFilesAndFolders();
    const data = useFilteredItems(folders, searchValue, true, 'folder', item?.id, item?.parentId, false);
    const defaultTreeValue = item?.parentId ? {[item?.parentId]: true} : {[FOLDERS_AND_DATA]: true};

    const getFunction = (finalInitialValue) => {
        const defaultParentId = finalInitialValue?.parentId
            ? {[finalInitialValue?.parentId]: true}
            : {[FOLDERS_AND_DATA]: true};

        const customizedInitialValue = {
            ...finalInitialValue,
            parentId: defaultParentId,
        };

        return {
            isSuccessful: () => true,
            getData: () => customizedInitialValue,
        };
    };

    return (
        <F4CreatableModal isDisabled
            state={{mode: 'edit', _id: item?.id, actionTitle: ' '}}
            title={strings('select_directory')}
            formKey="report"
            hasSidebar={false}
            openOnMount={openOnMount}
            storeFunction={(value)=>{
                getResult(value as object);

                return null;
            }}
            getFunction={() => getFunction(item)}
            {...rest}
        >
            <F4FormStep autoNest>
                <F4FormDiv colSpan={12} className="-mt-4">
                    <SearchBox onSearch={setSearchValue}
                        className="!bg-neutral-100 rounded-lg sticky elevation-5 !shadow-none -top-4 mt-6 mb-4"
                        textBoxInputClassName="!bg-neutral-100 !text-base !font-normal !text-neutral-900"
                    />
                    {!!data.length && <F4TreeView
                        name="parentId"
                        data={data}
                        size="xs"
                        showOptional={false}
                        defaultValue={defaultTreeValue}
                        nodeMoveTargetFilter={(e) => e.type == 'folder'}
                        scrollTimeOut={scrollTimeOut}
                        scrollToDefaultValue={GetFirstKey(defaultTreeValue) != FOLDERS_AND_DATA}
                        areAllNodesExpanded={true}
                        checkboxFilter={(node) => node.type == 'folder'}
                        checkMode="single"
                    />}
                    {!data?.length && <Label text={strings('no_results_found')} size="sm" className="mt-5 ml-3 overflow-hidden" color="neutral-600"/>}
                </F4FormDiv>
            </F4FormStep>
        </F4CreatableModal>
    );
};

export default MoveToLocationModal;
