import {Icon, Label, TabNav, TIconType} from '@aktek/f4kit';
import {SBOService} from '@aktek/morph-frontend-sdk';
import {faArrowUpRight, faDatabase, faPlusSquare} from '@fortawesome/pro-regular-svg-icons';
import {useEffect, useState} from 'react';

import {focusOrOpenNewTab, openTab} from '@/app/Home/Tabs';
import AskForCloseWindowModal from '@/components/Modals/AskForCloseWindowModal';
import SearchBox from '@/components/SearchBox/SearchBox';
import {strings} from '@/localization/i18n';

import {addRecentlyUsedDS, STORAGE_KEY} from '../helpers/RecentlyUsedDataStructure';

type TRecentDataStructure ={
    isEdited: boolean
    key: string
    label: string
    icon: TIconType,
}

type TDataStructureEditorSidebarProps ={
    recentDS: Array<TRecentDataStructure>
    selectedSboId: string
    isEdited?: boolean
    updateState?: (key:string, value:boolean) => void
    refreshTable?: () => Promise<void>
}

function DataStructureEditorSidebar(props: TDataStructureEditorSidebarProps) {
    const [recentlyUsedSbos, setRecentlyUsedSbos] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchSbos = async () => {
        const response = await SBOService.getUsableSBOs(searchTerm, {id: 'name', order: '1'}, 0, 15);

        if (response.isSuccessful()) {
            const data = response.getData();
            const tabs = data?.data?.map((item) => ({
                key: item._id,
                label: item.name,
                icon: faDatabase,
            }));

            setRecentlyUsedSbos(tabs);
            localStorage.setItem(STORAGE_KEY, JSON.stringify(tabs));
        }
    };

    useEffect(()=>{
        if (searchTerm) {
            fetchSbos();
        } else {
            if (props.recentDS.length !== 15) {
                fetchSbos();
            } else setRecentlyUsedSbos(props.recentDS);
        }
    }, [props.recentDS, searchTerm]);

    const tabClick =(newTab, myTab, e) => {
        if (newTab ) {
            openTab('dataStructureEditor', myTab.label, {sboId: myTab.key, refreshTable: ()=> props?.refreshTable()});
            addRecentlyUsedDS(myTab);
            e.stopPropagation();
        } else if (e === null && !newTab) {
            if (props.isEdited) {
                AskForCloseWindowModal(()=> {
                    focusOrOpenNewTab('dataStructureEditor', myTab.label, {sboId: myTab.key, refreshTable: ()=> props?.refreshTable()});
                    props.updateState('isEdited', false);
                });
            } else {
                focusOrOpenNewTab('dataStructureEditor', myTab.label, {sboId: myTab.key, refreshTable: ()=> props?.refreshTable()});
            }

            addRecentlyUsedDS(myTab);
        }
    };

    return (
        <div className="flex flex-col gap-4 p-4 pt-0">
            <div className="flex flex-col gap-4 sticky top-0 bg-white elevation-1 !shadow-none pb-2 pt-4">

                <div className="flex justify-between items-center px-3">
                    <Label text={strings('recent_data_structures')} color="neutral-600" className="text-sm" fontWeight="semibold" />
                    <Icon tooltipProps={{showDelay: 1000}} tooltip="Add New Data Structure" icon={faPlusSquare} className="h-5 w-5" color="neutral-600" onClick={()=>openTab('dataStructureEditor', 'New Data Structure', {refreshTable: ()=> props?.refreshTable()})}/>
                </div>

                <SearchBox
                    onSearch={(e)=>setSearchTerm(e)}
                    placeholder={strings('search_data_structures')}
                    hasBottomBorder={false}
                    textBoxInputClassName="!bg-neutral-100 !rounded-lg !text-neutral-500 !font-normal"
                />
            </div>

            {recentlyUsedSbos.length > 0
                ? <TabNav
                    size="sm"
                    orientation="vertical"
                    tabs={recentlyUsedSbos}
                    value={props?.selectedSboId || recentlyUsedSbos[0]}
                    onChange={(tabId)=> {
                        const tab = recentlyUsedSbos.find((sbo) => sbo.key === tabId);
                        const myTab = {
                            key: tabId,
                            label: tab.label,
                            icon: faDatabase,
                        };
                        tabClick(false, myTab, null);
                    }}
                    className="-mt-2"
                    buttonProps={{allowIconAutoPlacement: true}}
                    buttonClassName="hover:bg-primary-25 hover:text-primary-700 !min-h-10 !h-full text-wrap !text-left group"
                    getButtonProps={(tab) => {
                        return {
                            edgeIcons: {right:
                         {icon: faArrowUpRight, color: 'neutral-700', className: 'hidden group-hover:!flex  rounded-lg hover:primary-100 bg-primary-100 p-1 w-4 h-4',
                             onClick: (e) => {
                                 const myTab = {
                                     key: tab.key,
                                     label: tab.label,
                                     icon: faDatabase,
                                 };
                                 tabClick(true, myTab, e);
                             }}},
                        };
                    }}
                />
                :strings('no_data_structures_found')}
        </div>
    );
}

export default DataStructureEditorSidebar;
