
import {Icon} from '@aktek/f4kit';
import {faCopy, faMapLocation} from '@fortawesome/pro-regular-svg-icons';
import _compact from 'lodash/compact';

import Env from '@/config/env';
import {CopyToClipboard} from '@/helpers/CopyToClipboard.fn';

const GpsCellEdit = ({lat, lng, onChange}) => {
    const hasValues = !(isNaN(lat) || isNaN(lng));
    const label = _compact([lat, lng]).join(',');

    const textToCopy = `${lat}, ${lng}`;

    return <div className="flex flex-col items-center gap-1">
        <div> {label} </div>
        { hasValues && <div className="flex items-center gap-1">
            <Icon
                tooltip="Click to copy"
                onClick={() => CopyToClipboard(textToCopy)} icon={faCopy}/>
            <Icon
                tooltip="Open in Google Maps"
                onClick={() => openInGoogleMaps(lat, lng)} icon={faMapLocation}/>
        </div>}
    </div>;
};

export default GpsCellEdit;

const openInGoogleMaps = (lat, lng) => {
    const googleMapsUrl = Env.GoogleMapLatLngAPI + `${lat},${lng}`;
    window.open(googleMapsUrl, '_blank');
};
