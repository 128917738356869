import {F4FormDiv, F4FormStep} from '@aktek/f4form';
import {FitHeight} from '@aktek/f4kit';

import {useF4CreatableFormContext} from '@/components/F4CreatableForm/F4CreatableFormContext';

import {storingToF4Form} from '../helpers/storingToF4Form';
import usePermissions from '../hooks/usePermissions';
import PermissionEditor from './PermissionEditor';
import PermissionLoading from './PermissionLoading';

export function RolesPermissions() {
    const {extra} = useF4CreatableFormContext();
    const state = extra?.state;

    if (!state) {
        ls.permissionSection = 'admin';
    }

    const ref = extra.ref;

    // This is temporary, fix by using context api
    const isSuperAdminKey = 'overridePermissions';

    const _setIsSuperAdmin = (value) => {
        ref?.current?.setValue(isSuperAdminKey, value);
        setIsSuperAdmin(value);
    };

    const {
        updatedPermissionsState,
        resetPermissions,
        setUpdatedPermissionsState,
        loading,
        isSuperAdmin, setIsSuperAdmin, // remove this after convert to context api
        canResetPassword,
    } = usePermissions(extra.roleId, ref);

    return (
        <F4FormStep step="permissions" autoNest>
            <F4FormDiv className="-mt-2">

                {loading && <PermissionLoading />}
                <FitHeight className="flex-col h-full bg-white rounded-lg">

                    {!loading && <FitHeight className="h-full -mt-1">
                        {updatedPermissionsState?.permissions
                                && <PermissionEditor
                                    key={updatedPermissionsState?.key + '' + loading}
                                    permissions={updatedPermissionsState?.permissions}
                                    onPermissionsChange={(data) => {
                                        storingToF4Form(data, ref);
                                        setUpdatedPermissionsState(data);
                                    }}
                                    isSuperAdmin={isSuperAdmin as boolean}
                                    setIsSuperAdmin={_setIsSuperAdmin}
                                    resetPermissions={resetPermissions}
                                    canResetPassword={canResetPassword}
                                />}
                    </FitHeight>}
                </FitHeight>
            </F4FormDiv>

        </F4FormStep>
    );
}

export default RolesPermissions;
