import {ButtonColorPicker, Color} from '@aktek/f4kit';

const ColorCellEdit = ({value, onChange, isSquare}) => {
    return <div className="flex items-center gap-1">
        <ButtonColorPicker
            size="sm"
            value={value}
            onChange={onChange}
        />

    </div>;
};

export default ColorCellEdit;
