import {SpreadsheetService} from '@aktek/morph-frontend-sdk';

// import ParallelServiceRequestsManager from '@@Logical/TagSelector/ParallelServiceRequestsManager';

export default async ({value, _id, field, readMode = false}) => {
    if (Number.isInteger(value?._id)) {
        return {isValid: true, finalValue: value};
    }

    if (typeof value != 'string' && !readMode) {
        return {isValid: false};
    }

    const request = {sboId: field.sboId, fieldId: field.fieldId, values: {}, canCreate: field.canCreateNew};
    request.values[0] = value;
    const key = field.sboId + '_' + field.fieldId + '-' + field.canCreateNew + '-' + value + ' - ' + readMode;

    // const res = await ParallelServiceRequestsManager.dedup(SpreadsheetService.transformRef, request, key);
    const res = await SpreadsheetService.transformRef(request);

    if (!res.isSuccessful()) return {isValid: false};

    const data = res.getData();

    const _value = data[0];

    if (_value) {
        return {isValid: true, finalValue: _value};
    }

    return {isValid: true, finalValue: null};
};
