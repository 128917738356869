import {F4Form, useF4FormRef} from '@aktek/f4form';
import {Button, Drawer, FitHeight, toast, useTab} from '@aktek/f4kit';
import {SBOService} from '@aktek/morph-frontend-sdk';
import {faDatabase} from '@fortawesome/pro-regular-svg-icons';
import {useEffect, useState} from 'react';

import AskForCloseWindowModal from '@/components/Modals/AskForCloseWindowModal';
import AskForFolder from '@/components/Modals/AskForFolder';
import {strings} from '@/localization/i18n';
import {openCloseDrawer} from '@/utils/openCloseDrawer';
import ls from '@/utils/storage/ls';

import {addRecentlyUsedDS, getRecentlyUsedDS, STORAGE_KEY} from '../helpers/RecentlyUsedDataStructure';
import DataStructureEditorMainInfo from './DataStructureEditorMainInfo';
import DataStructureEditorSidebar from './DataStructureEditorSidebar';
import DataStructureSectionsFields from './DataStructureSectionsFields';

type TDataStructureEditorProps ={
    sboId: string;
    onSetValue?: (key:string, value:unknown) => void
}

function DataStructureEditor(props: TDataStructureEditorProps) {
    const DSFormRef = useF4FormRef();
    const tab = useTab();

    // TODO: here I have some hard coded data cz I can't save without the, when sections and fields are ready we remove them
    const [editorState, setEditorState] = useState({
        currentSBO: {folder: null, hasRecordTags: true, name: '', _id: null,
            sections: ['7nWza'],
            tableFields: [],
            sectionsMap: {
                'Nh3cb': {
                    'id': 'Nh3cb',
                    'fields': [
                        'AsWcr',
                    ],
                    'justCreated': false,
                    'name': {
                        'def': '',
                        'en': '',
                    },
                    'underConstruction': false,
                },
            },
            fieldsMap: {
                'AsWcr': {
                    'name': {
                        'def': 'sdf',
                        'en': 'sdf',
                    },
                    'parentSection': 'Nh3cb',
                    'type': 'string',
                    'id': 'AsWcr',
                    'underConstruction': false,
                    'justCreated': false,
                },
            }},
        isEdited: false,
        recentDS: getRecentlyUsedDS(),
    });

    const updateState = (key, value) => setEditorState((prev) => ({...prev, [key]: value}));

    const [isLoading, setIsLoading] = useState(false);

    const _OnSetValue = (key: string, value: unknown) => {
        if (!editorState.isEdited) updateState('isEdited', true);

        props.onSetValue?.(key, value);
    };

    const fetchSboById = async ()=> {
        setIsLoading(true);
        const response = await SBOService.getCompleteSBOById(props.sboId);
        if (response.isSuccessful()) updateState('currentSBO', response.getData());
        setIsLoading(false);
    };

    const handleSave = async ()=>{
        let validRequest = true;
        const validation = DSFormRef.current.validate();

        if (!DSFormRef.current.getData().name || DSFormRef.current.getData().name.length === 0) {
            validRequest = false;
            toast.error(strings('data_structure_name_is_required'));
        }

        if (validRequest === true && validation.isFormValid) {
            // creating a new data structure
            let folderResults;

            if (!props.sboId) {
                folderResults = await AskForFolder();

                setEditorState((prev) => ({...prev, currentSBO: {...prev.currentSBO, folder: folderResults?.parentId}}));
            }

            setIsLoading(true);

            const finalResults = {
                ...DSFormRef.current.getData() as object,
                folder: folderResults?.parentId || DSFormRef.current.getData()?.folder || null,
            };

            const createSBO = await SBOService.storeSBO(finalResults);

            if (createSBO.isSuccessful()) {
                editorState.recentDS.map((item, index)=>{
                    if ( item.key === props.sboId) {
                        const tab = {
                            key: createSBO.getData()._id,
                            label: createSBO.getData().name.def,
                            icon: faDatabase,
                        };

                        return editorState.recentDS[index] = tab;
                    }
                });
                localStorage.setItem(STORAGE_KEY, JSON.stringify(editorState.recentDS));
                tab.focusOrOpenNewTab('dataStructureEditor', createSBO.getData().name.def, {sboId: createSBO.getData()._id, refreshTable: ()=> props?.refreshTable()});
                toast.success(strings('data_structure_is_saved_successfully'));
                const myTab = {
                    key: createSBO.getData()._id,
                    label: createSBO.getData().name.def,
                    icon: faDatabase,
                };
                addRecentlyUsedDS(myTab);
                setIsLoading(false);
                updateState('isEdited', false);
                props?.refreshTable();
            }
        }

        setIsLoading(false);
    };

    const handleCancel = ()=>{
        if (editorState.isEdited) {
            AskForCloseWindowModal(tab.close);
        } else tab.close();
    };

    useEffect(()=>{
        props?.sboId && fetchSboById();
    }, [props?.sboId]);

    return (
        <div className="flex h-full">
            <Drawer
                sideWidth={96}
                tooltipDelay={1000}
                defaultOpen={ls.drawer?.dataStructure ?? true}
                onOpenChange={(e) => openCloseDrawer('dataStructure', !!e)}
                buttonSize="sm"
                className="sm:!w-2/4 md:!w-1/4 !w-11/12 min-w-64"
            >

                <DataStructureEditorSidebar
                    refreshTable={()=> props?.refreshTable()}
                    selectedSboId={props.sboId}
                    recentDS={editorState.recentDS}
                    isEdited={editorState.isEdited}
                    updateState={updateState}/>

                <FitHeight className="h-full w-full overflow-x-auto bg-neutral-50 p-4 border-l border-neutral-200">
                    <F4Form
                        isLoading={isLoading}
                        formKey="dataStructure"
                        ref={DSFormRef}
                        printData="console"
                        initialValue={editorState.currentSBO || {}}
                        className="h-full px-20 py-2"
                        onSetValue={_OnSetValue}
                    >
                        <DataStructureEditorMainInfo DSFormRef={DSFormRef} sboId={props?.sboId}/>
                        <DataStructureSectionsFields />
                    </F4Form>
                    <div className="absolute bottom-4 right-6 flex gap-2">
                        <Button isGhost label={strings('cancel')} textColor="neutral-600" onClick={handleCancel}/>
                        <Button label={strings('save')} onClick={handleSave}/>
                    </div>
                </FitHeight>
            </Drawer>
        </div>
    );
}

export default DataStructureEditor;

