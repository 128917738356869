import {Tag} from '@aktek/f4kit';
import {faPen, faTrash} from '@fortawesome/pro-regular-svg-icons';
import _compact from 'lodash/compact';

import AskForCreationModal from '@/components/Modals/AskForCreationModal';
import {TColumn, TGetRightClickRowActions} from '@/components/Table/Types/Table.t';
import {useAdminPermissions} from '@/context/UserContext/Hooks/useAdminPermissions';
import {strings} from '@/localization/i18n';

import CreateTagModal from '../components/CreateTagModal';
import {GetTagTextColor} from '../helpers/TagTextColors.fn';

export const TAG_COLUMNS = ():Array<TColumn> => {
    const {tags} = useAdminPermissions();

    return [
        {
            key: 'Tag',
            type: 'tags',
            renderCell({row}) {
                const color = row?.original?.Color[0].color;
                const label = row?.original?.Tag[0].label;

                const backgroundColor = color.length > 0 ? color : '#9BD8B8';

                return (
                    <Tag
                        backgroundColor={backgroundColor}
                        textColor={GetTagTextColor(color)}
                        className={` !rounded-3xl px-2 py-1 `}
                        label={label}
                    />
                );
            },
        },
        {
            key: 'Color',
            type: 'color',
        },
        {
            key: 'actions',
            type: 'actions',
            header: '',
            actions: {

                edit: {
                    tooltip: strings('edit'),
                    isVisible: tags?.can_edit,
                },

                delete: {
                    tooltip: strings('delete'),
                    isVisible: tags?.can_delete,
                },
            },
        },
    ];
};

export const GetRightClickRowTagsActions: TGetRightClickRowActions = (permission) => (refreshTable, deleteModalRef) => (row) => {
    return _compact([

        permission?.can_edit && {
            label: strings('edit'),
            icon: faPen,
            onClick: () => AskForCreationModal(CreateTagModal, {state: {mode: 'edit', _id: row.original?.Tag[0].label}, refreshTable}),
        },

        permission?.can_delete && {
            label: strings('delete'),
            icon: faTrash,
            iconProps: {color: 'error-600'},
            onClick: () => deleteModalRef.current.open({id: row.original.Tag[0].label, name: row.original.Tag[0].label, message: 'delete_tag'}),
            labelProps: {color: 'error-600'},
        },
    ]);
};

