
export default (editableFieldsIDs, lockedSectionsAndFields) => {
    const editableFieldsIDsSet = new Set(editableFieldsIDs);
    const lockedFieldsSet = new Set();
    const lockedSectionsSet = new Set();

    lockedSectionsAndFields?.fields?.map?.((key) => {
        editableFieldsIDsSet.delete(key);
        lockedFieldsSet.add(key);
    });

    lockedSectionsAndFields?.sections?.map?.((key) => {
        lockedSectionsSet.add(key);
    });

    return {lockedSectionsSet, lockedFieldsSet, editableFieldsIDsSet};
};

