import {MorphService} from '@aktek/morph-frontend-sdk';

const bulkDeleteMorph = async (sboId, morphIds: string[]) => {
    const res = await MorphService.bulkDeleteMorph(morphIds, sboId);

    if (res.isSuccessful()) {
        return true
    }
    return false
};

export default async (sboId, morphIds: string[], onDelete?) => {
    if (!morphIds.length) return true;

    const isSuccess = await bulkDeleteMorph(sboId, morphIds);
    onDelete?.(isSuccess);

    return isSuccess;
};
